import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { permisosInterface } from '../../Model/Seguridad/permisos.Interface';
import { AuthService } from '../auth.service';
@Injectable({
  providedIn: 'root'
})
export class PermisosValidarService {


  constructor(private router:Router, private auth: AuthService) { }

  validate(nombre:string) {

    let permisos!: permisosInterface ;
    let menus:any = JSON.parse(localStorage.getItem('menus')!);

    for (const i in menus) {
        for (const j in menus[i].hijos) {
          if (menus[i].hijos[j].texto == nombre) {
            permisos = menus[i].hijos[j];
          }
      }
    }
    // if(permisos == undefined){
    //   this.auth.CerrarSesion();
    //   this.router.navigateByUrl('/login');
    // }
    return permisos;
  }

  validateGuard(urlTemplate: string) {
    urlTemplate = urlTemplate.substring(1);
    let permisos!: permisosInterface;
    let menus = JSON.parse(localStorage.getItem('menus')!);

    for (const i in menus) {
      for (const j in menus[i].hijos) {
        if (menus[i].hijos[j].urlTemplate == urlTemplate) {
          permisos = menus[i].hijos[j];
        }
      }

    }
    return permisos;
  }
}
