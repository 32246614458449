import { HttpErrorResponse } from '@angular/common/http';
import * as sha1 from 'sha1';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataApi } from '../Model/dataApi';
import { MensajesHttpService } from '../utils/mensajesHttp/mensajes-http.service';
import { SeguridadService } from './seguridad/seguridad.service';
import { ToastrServiceLocal } from './toast/toastr.service';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  constructor(public router: Router, public seguridadService: SeguridadService, public _toast: ToastrServiceLocal, 
    public _mensajesHttp: MensajesHttpService) { }

  CerrarSesion(){
    localStorage.clear();
    this.router.navigateByUrl('inicio');
  }

  Login(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', '');

    const request$ = this.seguridadService.PostDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/auth/login/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        localStorage.setItem("token_go504", result.token);
        localStorage.setItem("data_go504", JSON.stringify(result.data));
        localStorage.setItem("menusTotales", JSON.stringify(result?.menus));
        
        
        if (result.data.cambioContrasena === true) {
          this.router.navigateByUrl('/cambioPassword/1');
        } else {
          if (result.data.idTipo==2) {
            localStorage.setItem("dasboard",'9');
            localStorage.setItem("idModulo",'9');
            localStorage.setItem("typeTwo",'72DE444A-06D6-4749-BE93-39A5BFC54637');
            this.router.navigateByUrl(`/notificaciones/clienteDetalle/${result.data.idCliente}`);
            var encryto =CryptoJS.AES.encrypt(result.data.idCliente.toString(),'c3o3YrDuPO').toString()
             localStorage.setItem("idUnico", encryto);
            
          } else {
            localStorage.setItem("typeTwo",'F676E28C-067F-4085-8204-9463062D8581');
            this.router.navigateByUrl('/mosaicMenu');
          }
        }
        // this._toast.mensajeSuccess('Bienvenido', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al entrar al sistema', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  

}
