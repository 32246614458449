<app-fixed-filtro *ngIf="(filtroFacade.responseReporteFinal$ | async)"></app-fixed-filtro>

<div *ngIf="!(filtroFacade.responseReporteFinal$ | async)">
    <!-- <mat-card class="filter-card matCardPersonalizada"> 
        <mat-card-header>
         
            <mat-card-title>Generar Reporte</mat-card-title>
            <mat-card-subtitle>Filtro para la generación de reportes</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content> -->

    <div>

        <div class="text-left" style="margin-top: 3rem !important;">
            <div *ngIf="(filtroFacade.responseCargando$ | async)">
                <app-loading [data]="4"></app-loading>
            </div>
            <div style="letter-spacing: -0.025em !important; line-height: 2rem !important; font-size: 2.5rem !important; font-weight: 600 !important;" *ngIf="verReportes">
                Reportes
            </div>

            <div style="letter-spacing: -0.025em !important; line-height: 2rem !important; font-size: 2.5rem !important; font-weight: 600 !important;" *ngIf="!verReportes">
                {{reporte}}
            </div>
        </div>

        <!-- <div class="section over-hide"> -->




        <div class="row mt-4" *ngIf="!(filtroFacade.responseCargando$ | async) && verReportes">

            <div *ngFor="let item of (filtroFacade.responseReportesModulo$ | async)" class="col-md-4">
                <div class="card" style="width: 18rem;">
                    <img src="https://cdn.estegrafico.com/images/check.gif" class="card-img-top"
                        alt="Chicago Skyscrapers" />
                    <div class="card-body">
                        <h5 class="card-title"> {{item.descripcion}}</h5>
                        <p class="card-text"></p>
                    </div>

                    <div class="card-body">
                        <div class="text-center">
                            <button mat-raised-button (click)="seleccionar(item)" class="buttonPrincipal">Ver Reporte</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <button *ngIf="!verReportes" mat-mini-fab (click)="verReportes = true" class="buttonPrincipal mt-4">
             <mat-icon>arrow_back</mat-icon>
        </button>


        <mat-card class="matCardHeader mt-4" *ngIf="elementoForm.controls.length > 0 && !verReportes">
            <mat-card-header>
                <mat-card-title>
                    {{reporte}} filtrar Por
                </mat-card-title>
            </mat-card-header>

            <mat-card-content>
                
                <div class="row">
                    <div *ngFor="let item of elementoForm.controls; let i=index;"
                        class="{{item.value.grid || 'col-md-6'}}">

                        <ng-container [formGroup]="item">

                            <mat-form-field appearance="legacy" *ngIf="item.value.tipoElemento === 'select'">
                                <mat-label>{{item.value.titulo}}</mat-label>
                                <mat-select formControlName="value">
                                    <mat-option *ngFor="let elemento of item.value.data" [value]="elemento.id">
                                        {{elemento.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="legacy" *ngIf="item.value.tipoElemento === 'date'">
                                <mat-label><b>{{item.value.titulo}}</b> </mat-label>
                                <input matInput type="date" formControlName="value">
                            </mat-form-field>
                           
                            <mat-form-field appearance="legacy" *ngIf="item.value.tipoElemento === 'multiselect'">
                                <mat-label>{{item.value.titulo}}</mat-label>
                                <mat-select formControlName="value" multiple (ngModelChange)="onChange(i, $event)">
                                    <mat-select-trigger>
                                        {{item.value.multiSelectValue ? item.value.multiSelectValue[0] : ''}}
                                        <span *ngIf="item.value.multiSelectValue?.length > 1"
                                            class="example-additional-selection">
                                            (+{{item.value.multiSelectValue.length - 1}} )
                                        </span>
                                    </mat-select-trigger>

                                    <mat-option *ngFor="let elemento of item.value.data" [value]="elemento.id">
                                        {{elemento.descripcion}}</mat-option>

                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="legacy" *ngIf="item.value.tipoElemento === 'text'">
                                <mat-label><b>{{item.value.titulo}}</b> </mat-label>
                                <input matInput formControlName="value" autocomplete="off">
                            </mat-form-field>

                        </ng-container>

                    </div>
                </div>

                <div class="text-right" style="margin-bottom: 10px; margin-right: 10px;">
                    <button mat-raised-button class="buttonPrincipal" (click)="generarReporte()">Generar Reporte</button>
                </div>

            </mat-card-content>

        </mat-card>
<!-- 

        <div class="row" *ngIf="!(filtroFacade.responseCargando$ | async) && elementoForm.controls.length > 0">
            <div
                style="letter-spacing: -0.025em !important; font-weight: 500 !important; color: rgba(100, 116, 139, 1); font-size: 1.5rem !important;">
                <b>:</b>
            </div> -->
            <!-- <mat-form-field appearance="legacy" class="col-md-12">
                <mat-label>Reporte</mat-label>
                <mat-select [formControl]="idReporte">
                    <mat-option *ngFor="let item of (filtroFacade.responseReportesModulo$ | async)" [value]="item.id">
                        {{item.descripcion}}</mat-option>
                </mat-select>
            </mat-form-field> -->

            <!-- <div class="title col-md-12">
                <b>Filtrar Por:</b>
            </div> -->


<!-- 
        </div> -->
    </div>

    <!-- </mat-card-content>
        <mat-card-actions>
            <div class="text-right" style="margin-bottom: 10px; margin-right: 10px;">
                <button mat-raised-button class="buttonPrincipal" (click)="generarReporte()">Generar Reporte</button>
            </div>
        </mat-card-actions>
    </mat-card> -->
</div>

<div *ngIf="(filtroFacade.responseReporteFinal$ | async)">
    <app-reporte-final></app-reporte-final>
</div>