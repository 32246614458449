import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { menuInterface } from '../../../Model/Seguridad/menu.Interface';
import { DataApi } from '../../../Model/dataApi';
import { DataApiService } from '../../../services/data-api.service';
import { ToastrServiceLocal } from '../../../services/toast/toastr.service';
import { MensajesHttpService } from '../../../utils/mensajesHttp/mensajes-http.service';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SeguridadService } from 'src/app/services/seguridad/seguridad.service';

@Injectable({
  providedIn: 'root'
})
export class AsignarMenusFacadeService implements OnDestroy {

  public suscription: Subscription = new Subscription();


  constructor( private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal,  private _dataApi: SeguridadService) { }

  ngOnDestroy(): void {
    this.suscription.unsubscribe();
  }

  private MenusAll$ = new BehaviorSubject<menuInterface[]>([]);
  public responseMenusAll$: Observable<menuInterface[]> = this.MenusAll$.asObservable();

  private Action$ = new BehaviorSubject<any>({});
  public responseAction$: Observable<any> = this.Action$.asObservable();

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private Error$ = new BehaviorSubject<any>({ hasError: true, recurso: '', messageError: '', errors: [] });
  public responseError$: Observable<any> = this.Error$.asObservable();

  public menusHijos: boolean = false;
  public idMenuPadre: any;


  MostrarMenu(params: any) {
    this.menusHijos = false;
    this.Cargando$.next(true);
    this.idMenuPadre = 0;
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/`, params).pipe(
      tap((result: DataApi | any) => {
        this.MenusAll$.next(result);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los menus', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }
  subMenuConstruir(idMenuPadre:any) {
    this.Cargando$.next(true);
    this.idMenuPadre = idMenuPadre;
    let arrayMenus: menuInterface[] = [];
    this.suscription.add(this.MenusAll$.subscribe((result) => {
      for (let i = 0; i < result.length; i++) {
        if (result[i].idMenu === idMenuPadre) {
          arrayMenus = result[i].hijos;
        }
      }
    }));
    this.Cargando$.next(false);
    this.MenusAll$.next(arrayMenus);
    this.menusHijos = true;
  }

  asignarMenusPerfil(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PutDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/profile/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        this.Action$.next(result);
        this.Cargando$.next(false);
        this._toast.mensajeSuccess('Se asigno con exito el menu', '');

      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al asignar el menu', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  asignarMenusUsuario(params:any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/user/`, params).pipe(
      tap((result: DataApi | any) => {
        console.log(result);
        this._toast.clearToasts();
        this._toast.mensajeSuccess('Se asigno con exito el menu', '');
        this.Action$.next(result);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al asignar el menu', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }
}
