<!-- <div style="display: flex; justify-content: center;">
    <mat-card class="matCardPersonalizada mt-2" style="width: 70% !important;">

        <mat-card-header>
            <mat-card-title>
                <span *ngFor="let tables of (filtroFacade.responseReporte$ | async).tables">
                    {{(filtroFacade.responseReporte$ | async).tables[0].tableTitle}}
                </span>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>

            <div class="row">
                <div class="col-md-4 text-center">
                    <button mat-raised-button class="btnExportar"(click)="imprimirContrato('#ReporteFinal')">
                        <mat-icon>print</mat-icon>
                        Imprimir
                    </button>
                </div>

                <div class="col-md-4 text-center">
                    <button mat-raised-button (click)="cambiarOrientacion()" class="buttonSecundary">
                        <mat-icon>restore_page</mat-icon>
                        Orientación
                    </button>
                </div>

                <div class="col-md-4 text-center">
                    <button mat-raised-button (click)="downloadPDF()" class="buttonPrincipal">
                        <mat-icon>download</mat-icon> 
                        Descargar
                    </button>
                </div>

            </div>

        </mat-card-content>


    </mat-card>
</div> -->

<!-- <div class="text-left" *ngIf="(filtroFacade.responseReporteFinal$ | async)">
    <button mat-mini-fab (click)="filtroFacade.resetearReporteFinal()">
        <mat-icon>arrow_back</mat-icon>
    </button>
</div> -->

<div id="ReporteFinalVertical" class="page mt-2" size="letter" *ngIf="filtroFacade.orientacionPagina === 1">
    <table>
        <thead>
            <tr>
                <td>
                    <!-- <div class=" row">
                        <div class="col-md-3 text-center">
                            <img [src]="logoEmpresa" class="img-fluid">
                        </div>
                        <div class="col-md-6 text-center">
                            <p id="title">
                                {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async ).nombreEmpresa}}
                                <br>
                                {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async ).ciudad}}
                                {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async ).direccion}}
                                <br>
                                Telefono: {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async ).telefono}}
                                Correo Electronico: {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async
                                ).email}}
                            </p>


                        </div>
                        <div class="col-md-3 text-center">

                            <span style="font-size: 13px;">Fecha Impresion: {{fecha | date: 'dd/MM/yyyy'}}</span>
                            <br>
                            <span style="font-size: 13px;">Usuario: {{dataUsuario.Usuario}}</span>

                        </div>

                    </div> -->
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <!-- <div *ngFor="let item of (filtroFacade.responseReporte$ | async).tables">

                        <table mat-table [dataSource]="item?.data" class="mat-elevation-z8">

                            <ng-container [matColumnDef]="column" *ngFor="let column of item?.th">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> {{column}} </th>
                                <td mat-cell *matCellDef="let element" class="text-center"> {{element[column]}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columsDisplay(item?.th)"></tr>
                            <tr mat-row *matRowDef="let row; columns: columsDisplay(item?.th);"></tr>
                        </table>
                    </div> -->


                    <div class="table-responsive mt-2 col-md-12"
                        *ngFor="let tables of (filtroFacade.responseReporte$ | async).tables">
                        <table class="table  bordeTabla tablep" role="table" id="table"
                            [ngStyle]="{'font-family': filtroFacade.estiloContenido.fontFamily, 'font-size':  filtroFacade.estiloContenido.fontSize} ">
                            <thead class="theadp">

                                <tr class="trp bg-success text-center ">
                                    <th class="thp text-left" scope="col " role="columnheader"
                                        *ngFor="let th of tables?.th">{{th}}</th>
                                </tr>
                            </thead>


                            <tbody role="rowgroup " class="tbodyp" id="tableBodyActivos">

                                <tr class="trp" role="row" *ngFor="let data of tables?.data">

                                    <td role="cell " class="tdp {{filtroFacade.estiloContenido.align}}"
                                        *ngFor="let columns of columsDisplay(tables?.th);">{{data[columns]}}</td>

                                </tr>




                            </tbody>

                        </table>
                    </div>

                </td>
            </tr>
        </tbody>
        <tfoot>

        </tfoot>
    </table>




</div>

<div id="ReporteFinalHorizontal" class="page mt-2" size="landscape" *ngIf="filtroFacade.orientacionPagina === 2">
    <table>
        <thead>
            <tr>
                <td>
                <!--     <div class=" encabezado" class="tableRow">
                        <div class="col-md-3 text-center Column">
                            <img [src]="logoEmpresa" class="img-fluid">
                        </div>
                        <div class="col-md-6 text-center Column">
                            <p id="title">
                                {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async ).nombreEmpresa}}
                                <br>
                                {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async ).ciudad}}
                                {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async ).direccion}}
                                <br>
                                Telefono: {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async ).telefono}}
                                Correo Electronico: {{(datosGeneralesFacade.responseDatosGeneralesEmpresa$| async
                                ).email}}
                            </p>


                        </div>
                        <div class="col-md-3 text-center Column">

                            <span style="font-size: 13px;">Fecha Impresion: {{fecha | date: 'dd/MM/yyyy'}}</span>
                            <br>
                            <span style="font-size: 13px;">Usuario: {{dataUsuario.Usuario}}</span>

                        </div>

                    </div> -->
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <!-- <div *ngFor="let item of (filtroFacade.responseReporte$ | async).tables">

                        <table mat-table [dataSource]="item?.data" class="mat-elevation-z8">

                            <ng-container [matColumnDef]="column" *ngFor="let column of item?.th">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> {{column}} </th>
                                <td mat-cell *matCellDef="let element" class="text-center"> {{element[column]}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columsDisplay(item?.th)"></tr>
                            <tr mat-row *matRowDef="let row; columns: columsDisplay(item?.th);"></tr>
                        </table>
                    </div> -->

                    <div class="text-right">
                        <span style="font-size: 13px;">Fecha Impresion: {{fecha | date: 'dd/MM/yyyy'}}</span>
                        <br>
                        <span style="font-size: 13px;">Usuario: {{dataUsuario.Usuario}}</span>
                    </div>

                    <div class="table-responsive mt-2 col-md-12"
                        *ngFor="let tables of (filtroFacade.responseReporte$ | async).tables">
                        <table class="table  bordeTabla tablep" role="table" id="table"
                            [ngStyle]="{'font-family': filtroFacade.estiloContenido.fontFamily, 'font-size':  filtroFacade.estiloContenido.fontSize} ">
                            <thead class="theadp">

                                <tr class="trp bg-success text-center ">
                                    <th class="thp text-left" scope="col " role="columnheader"
                                        *ngFor="let th of tables?.th">{{th}}</th>
                                </tr>
                            </thead>


                            <tbody role="rowgroup " class="tbodyp" id="tableBodyActivos">

                                <tr class="trp" role="row" *ngFor="let data of tables?.data">

                                    <td role="cell " class="tdp text-left"
                                        *ngFor="let columns of columsDisplay(tables?.th);">{{data[columns]}}</td>

                                </tr>




                            </tbody>

                        </table>
                    </div>

                </td>
            </tr>
        </tbody>
        <tfoot>

        </tfoot>
    </table>




</div>