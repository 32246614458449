import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SidemeduFacadeService } from './sidemedu-facade.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideMenuComponent implements OnInit, OnDestroy {

  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;


  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public sideMenuServices: SidemeduFacadeService,
    public media: MediaMatcher,
    private _route: Router,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.sideMenuServices.consumirMenus();
  }

  ngOnInit(): void{
    
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  salir() {
    this._route.navigateByUrl('/login');
  }

 
}
