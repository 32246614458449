
<nav aria-label="breadcrumb" *ngIf="menusFacade.menusHijos == false">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/adminMenu']">Inicio</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/menus']">menus</a></li>
        <li class="breadcrumb-item active" aria-current="page">Menus</li>
    </ol>
</nav>
<nav aria-label="breadcrumb" *ngIf="menusFacade.menusHijos == true">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/adminMenu']">Inicio</a></li>
        <li class="breadcrumb-item" aria-current="page"><a (click)="consumirMenus()" class="cursorActivo">Menu</a></li>
        <li class="breadcrumb-item" aria-current="page">{{menusFacade.nombreMenu}} Sub Menus</li>
    </ol>
</nav>
<div>
    <mat-card class="example-card">
        <mat-card-content>

            <section>
                <!-- <div class="text-right" *ngIf="(menusFacade.responseCargando$ | async) === false && permisos?.ingresar"></div> -->
                <div class="text-right">
                    <button mat-raised-button class="buttonPrincipal" [routerLink]="['/nuevoMenu', 0, menusFacade.idMenuPadre]">Nuevo Menu</button>
                </div>

                <div>
                    <app-table-menus [dataProfile]="3"></app-table-menus>
                </div>


            </section>
        </mat-card-content>

    </mat-card>
</div>

