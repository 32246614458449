import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable ,EMPTY} from 'rxjs';
import { NotificacionesService } from '../../services/notificaciones.service';
import { DataApi } from '../../Model/dataApi';
import { tap, catchError } from 'rxjs/operators';
import { MensajesHttpService } from '../../utils/mensajesHttp/mensajes-http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EmpresaServicesService {

  public Cargando = true;
  public CargandoD=false;
  public CargandoP=false;
  // public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private listEmpresas$ = new BehaviorSubject<any>([]);
  public repoEmpresa$: Observable<any> = this.listEmpresas$.asObservable();
  constructor(private _dataApi: NotificacionesService,
    private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal) { }

  mostrarEmpresas() {
    this.Cargando=true;
    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/empresa/`).pipe(
      tap((result: any) => {
        this.listEmpresas$.next(result?.data?.table0);
        this.Cargando=false;
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los email', '');
        this.Cargando=false;
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  getEmpresas(params: any, callback) {
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/empresa/`;
    const request$ = this._dataApi.GetDataApi(url).pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los datos generales', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  InsertUpateEmpresa(params: any, callback) {
    console.log(params,'parametro')
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/empresa`, params).pipe(
      tap((result: DataApi| any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }



  
  getClientes(params: any, callback) {
    this.Cargando=true;
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/cliente`;
    const request$ = this._dataApi.GetDataApi(url).pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          this.Cargando=false;


          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
               this.Cargando=false

          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los clientes', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  getClienteDetalles(params: any, callback) {
    this.Cargando=true;
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/cliente/${params}`;
    const request$ = this._dataApi.GetDataApi(url).pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          this.Cargando=false;


          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
               this.Cargando=false

          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar el cliente', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  UpateClientes(params: any, callback) {
    console.log(params,'parametro')
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PostDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/cliente`, params).pipe(
      tap((result: DataApi| any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  InsertClientes(params: any, callback) {
    console.log(params,'parametro')
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/cliente`, params).pipe(
      tap((result: DataApi| any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  getDocumentos(params: any, callback) {
    this.CargandoD=true;
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/correspondencia/cliente/${params}`;
    const request$ = this._dataApi.GetDataApi(url).pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          this.CargandoD=false;


          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
               this.CargandoD=false

          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los documentos', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  getDocumentosFecha(params: any, callback) {
    this.CargandoD=true;
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/correspondencia/cliente/${params.id}/${params.fechaInicial}/${params.fechaFinal}`;
    const request$ = this._dataApi.GetDataApi(url).pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          this.CargandoD=false;


          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
               this.CargandoD=false

          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los documentos', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  InsertUpateDocumento(params: any, callback) {

    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/correspondencia`, params).pipe(
      tap((result: DataApi| any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  InsertDocumentoDetalles(params: any, callback) {

    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/CorrespondenciaDetalle`, params).pipe(
      tap((result: DataApi| any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  getPlantillaCliente(params: any, callback) {
    this.CargandoP=true;
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/PlantillaCorrespondencia/cliente/${params}`;
    const request$ = this._dataApi.GetDataApi(url).pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          this.CargandoP=false;

          console.log(result);
          callback(result);
        
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
               this.CargandoP=false

          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los clientes', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  getCiudad(params: any, callback) {
    this.CargandoP=true;
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/ciudad/`;
    const request$ = this._dataApi.GetDataApi(url).pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          this.CargandoP=false;


          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
               this.CargandoP=false

          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los clientes', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  InsertPlantillaCliente(params: any, callback) {
    console.log(params,'parametro')
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/PlantillaCorrespondencia`, params).pipe(
      tap((result: DataApi| any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  UpdatePlantillaCliente(params: any, callback) {
    console.log(params,'parametro')
   
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PostDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/PlantillaCorrespondencia`, params).pipe(
      tap((result: DataApi| any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  htmlToPdf(params: any, callback) {
    console.log(params,'parametro')
   
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PostDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/genera/pdf`, params).pipe(
      tap((result: DataApi| any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  getDocumento(params: any, callback) {
    this.Cargando=true;
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/CorrespondenciaDetalle/id/${params}`;
    const request$ = this._dataApi.GetDataApi(url).pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          this.Cargando=false;
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
               this.Cargando=false
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los clientes', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  updateDocumento(params: any, callback) {
    console.log(params,'parametro')
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PostDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/correspondencia`, params).pipe(
      tap((result: DataApi| any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  deleteDocumento(id: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/correspondencia/${id}`;
    const request$ = this._dataApi.DeleteDataApi(url,"").pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  deleteDocumentoDetalles(id: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/CorrespondenciaDetalle/${id}`;
    const request$ = this._dataApi.DeleteDataApi(url,"").pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  getDocumentoDetalle(params: any, callback) {
    this.Cargando=true;
    let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/CorrespondenciaDetalle/${params}`;
    const request$ = this._dataApi.GetDataApi(url).pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          this.Cargando=false;


          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
               this.Cargando=false

          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

   //INSERTAR VOUCHER DE PAGO
   saveLogo(params: any, callback) {
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApiFile(`${environment.site}/api/utils/${environment.processEnv}/v1/repo/`, params).pipe(
          tap((result: DataApi | any) => {
            if (!result.hasError) {
                callback(result);
            } else {
                result.errors.forEach(element => {
                    this._toast.mensajeInfo(element.descripcion, '');
                });
                callback();
            }
        }),
        catchError((error: HttpErrorResponse) => {
            this._toast.clearToasts();
            this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al subir la imagen adjunta', '');
            return EMPTY;
        })
    );
    return request$.subscribe();
}

getLogo(params: any, callback) {
  let url = `${environment.site}/api/utils/${environment.processEnv}/v1/repo/${params.bucket}/${params.key}/60`;
  const request$ = this._dataApi.GetDataApiFile(url).pipe(
      tap((result: DataApi | any) => {
          if (!result.hasError) {
              callback(result);
          } else {
              result.errors.forEach(element => {
                  this._toast.mensajeInfo(element.descripcion, '');
              });
              callback();
          }
      }),
      catchError((error: HttpErrorResponse) => {
          this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar las clinicas', '');
          return EMPTY;
      })
  );
  return request$.subscribe();
}

deletePlantilla(id: any, callback) {
  this._toast.mensajeLoading("Cargando", "Procesando la información");
  let url = `${environment.site}/api/notification/${environment.processEnv}/v1/admin/PlantillaCorrespondencia/${id}`;
  const request$ = this._dataApi.DeleteDataApi(url,"").pipe(
    tap((result: DataApi | any) => {
      this._toast.clearToasts();
      if (!result.hasError) {
        callback(result);
      } else {
        result.errors.forEach((element) => {
          this._toast.mensajeInfo(element.descripcion, "");
        });
        callback();
      }
    }),
    catchError((error: HttpErrorResponse) => {
      this._toast.clearToasts();
      this._mensajesHttp.mostrarErrorHttp(
        error,
        "Ocurrio un error",
        ""
      );
      return EMPTY;
    })
  );
  return request$.subscribe();
}

}


