<nav aria-label="breadcrumb">

    <ol *ngIf="tipoDos" class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Inicio</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/notificaciones/clientes']">Clientes</a></li>
        <li class="breadcrumb-item active" aria-current="page">Cliente detalles</li>
    </ol>

    <ol *ngIf="!tipoDos" class="breadcrumb">
        <!-- <li class="breadcrumb-item"><a [routerLink]="['/']">Inicio</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/notificaciones/clientes']">Clientes</a></li> -->
        <li class="breadcrumb-item active" aria-current="page">Cliente detalles</li>
    </ol>


</nav>
<mat-card>
    <mat-card-content>
        <div class="content" #goUp>
            <div class=" titleNav">
                <form [formGroup]="clienteForm">
                    <div class="imgUser" (click)="selectImage.click()">
                        <ng-container *ngIf="isImageSaved; else elseTemplate">
                            <img [src]="cardImageBase64" class="img-responsive" class="imgPersonalizable img-fluid" />
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div>
                                <img [src]="(image === '') ? 'assets/images/logoCompany.jpg' : image"
                                    onerror="this.src='assets/images/logoCompany.jpg'" class="img-responsive"
                                    class="imgPersonalizable img-fluid" />
                            </div>
                        </ng-template>
                    </div>

                    <!--  <div class="imgUser" (click)="selectImage.click()">
                <ng-container *ngIf="isImageSaved; else elseTemplate">
                    <img [src]="cardImageBase64" class="img-responsive" class="imgPersonalizable img-fluid" />
                </ng-container>
                <ng-template #elseTemplate>
                    <div>
                        <img  [src]="(image === '') ? 'assets/images/notimage.jpg' : image" onerror="this.src='assets/images/notimage.jpg'"  class="img-responsive" class="imgPersonalizable img-fluid" />
                    </div>
                </ng-template>
                <span class="tooltipNew">
                    <b>Requisitos Fotografia valida:</b>
                    <p>1- Haber sido tomada dentro de los últimos 6 meses para reflejar su apariencia actual.</p>
                    <p>2- Tener un fondo blanco.</p>
                    <p>3- Debe abarcar la cara completa, la cual debe estar mirando directamente hacia la cámara.
                    </p>
                    <p>4- La expresión facial debe ser neutral (de preferencia), o tener una sonrisa natural, con
                        ambos ojos abiertos.</p>
                    <!-- <img [src]="getfoto" class="img-responsive" class="rounded-circle imgPersonalizable img-fluid" /> -->

                    <!--  </span>
            </div> -->


                    <input type="file" (change)="onFileSelect($event)" style="display: none" #selectImage
                        accept="image/*" />

                </form>

            </div>
            <div class="text-left action">
                <div class="nombre">
                    <h2
                        style="font-size: 2rem; font-weight: 800; letter-spacing: -.025em!important; line-height: 2.5rem!important;text-overflow: ellipsis!important; margin-bottom: 5px !important;">
                        Nombre Cliente: {{nombre.value}}
                    </h2>
                </div>
                <div class="expediente"
                    style="font-size: 2rem; font-weight: 800; letter-spacing: -.025em!important; line-height: 2.5rem!important;text-overflow: ellipsis!important; margin-bottom: 5px !important;">
                    RTN: {{rtn.value}}
                </div>


            </div>

        </div>
    </mat-card-content>
</mat-card>

<mat-card>






    <mat-card-content>
        <mat-tab-group mat-align-tabs="left">
            <mat-tab label="Información General" animationDuration="1200ms">
                <form [formGroup]="clienteForm" (ngSubmit)="saveClient()" autocomplete="off">
                    <br>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <mat-list-item>
                                <mat-form-field appearance="standard">
                                    <mat-label>RTN</mat-label>
                                    <input [readonly]="!tipoDos" formControlName="rtn" #rtn mask="0000-0000-000000"
                                        placeholder="0000-0000-000000" matInput>
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </mat-list-item>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <mat-form-field appearance="standard">
                                <mat-label>Nombre Cliente</mat-label>
                                <input autocomplete="off" #nombre formControlName="nombre" matInput>
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <mat-list-item>
                                <mat-form-field appearance="standard">
                                    <mat-label>Telefono</mat-label>
                                    <input mask="0000-0000" (keydown)="validarNumeros($event)"
                                        formControlName="telefono" placeholder="0000-0000" matInput>
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </mat-list-item>
                        </div>
                    </div>
                    <div class="row">


                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <mat-list-item>
                                <mat-form-field appearance="standard">
                                    <mat-label>Correo</mat-label>
                                    <input formControlName="correo" placeholder="correo" matInput>
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </mat-list-item>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <mat-list-item>
                                <mat-form-field appearance="standard">
                                    <mat-label>Descripción</mat-label>
                                    <input formControlName="descripcion" placeholder="Descripción" matInput>
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </mat-list-item>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <mat-list-item>
                                <mat-form-field appearance="standard">
                                    <mat-label>Comentario</mat-label>
                                    <input formControlName="comentario" placeholder="comentario" matInput>
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </mat-list-item>
                        </div>
                    </div>
                    <hr>
                    Contacto
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <mat-list-item>
                                <mat-form-field appearance="standard">
                                    <mat-label>Nombre</mat-label>
                                    <input formControlName="nombreContacto" placeholder="Nombre contacto" matInput>
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </mat-list-item>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <mat-list-item>
                                <mat-form-field appearance="standard">
                                    <mat-label>Teléfono</mat-label>
                                    <input mask="0000-0000" (keydown)="validarNumeros($event)"
                                        formControlName="telefonoContacto" placeholder="telefono contacto" matInput>
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </mat-list-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <mat-list-item>
                                <mat-form-field appearance="standard">
                                    <mat-label>Celular</mat-label>
                                    <input mask="0000-0000" (keydown)="validarNumeros($event)"
                                        formControlName="celularContacto" placeholder="Celular contacto" matInput>
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </mat-list-item>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <mat-list-item>
                                <mat-form-field appearance="standard">
                                    <mat-label>Correo</mat-label>
                                    <input formControlName="correoContacto" placeholder="Correo contacto" matInput>
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </mat-list-item>
                        </div>
                    </div>

                    <div *ngIf="tipoDos" class="text-right">

                        <button mat-raised-button type="submit" class="buttonPrincipal">
                            {{flagAccion==1&&'Guardar'||'Actualizar'}}
                        </button>
                    </div>

                </form>
            </mat-tab>
            <mat-tab *ngIf="flagAccion==2 && tipoDos " label="Plantillas de correos" animationDuration="1200ms">
                <app-plantillas></app-plantillas>
            </mat-tab>
            <mat-tab *ngIf="flagAccion==2" label="Graficos" animationDuration="1200ms">
                <br>
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6 animate__animated animate__fadeInLeft " *ngFor="let item of (dashboardService.responseDashboard$ | async).card" >
                        <div class="card card-stats ">
                            <div class="card-header  card-header-icon" [class]="item.class">
                                <div class="card-icon">
                                    <i [class]="item.icon"></i>
                                </div>
                                <p class="card-category">{{item.category}}</p>
                                <h1 class="card-title "> {{item.title}}</h1>
                            </div>
                            <div class="card-footer">
                                <div class="stats">
                                    <i [class]="item.footerIcon"></i> {{item.titleFooter}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngFor="let item of (dashboardService.responseDashboard$ | async).chart"
                        [ngClass]="[item?.class || 'col-md-6 col-sm-12', animacionChart]">
                        <mat-card class="card-chart">
                            <mat-card-header>
                                <div mat-card-avatar>
                                    <mat-icon style="font-size: 34px;">trending_up</mat-icon>
                                </div>
                                <mat-card-title>{{item.title}}</mat-card-title>
                                <mat-card-subtitle>{{item.subtitle}}</mat-card-subtitle>
                            </mat-card-header>
                            <div class="card-header chartsuccess">
                                <div class="chart-wrapper">
                                    <canvas baseChart [datasets]="item.data" [labels]="item.chartLabels"
                                        [colors]="item.chartStyleColor" [options]="barChartOptions"
                                        [legend]="item.chartLegend" [chartType]="item.chartType || 'bar'"
                                        [plugins]="barChartPlugins">
                                    </canvas>
                                </div>
                            </div>
                            <mat-card-content>
                                <!-- <h4 class="card-title">{{item.description}}</h4> -->
                                <p class="card-category">
                                    <span class="text-success">{{item.description}} </span>
                                </p>
                            </mat-card-content>

                            <mat-card-actions class="mat-card-footer">
                                <div class="stats">
                                    {{item.footer}}
                                </div>
                            </mat-card-actions>
                        </mat-card>


                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12 animate__animated animate__zoomIn" style="margin-bottom: 20px !important;">
                        <mat-card>
                            <mat-tab-group mat-align-tabs="start">
                                <mat-tab  *ngFor="let item of (dashboardService.responseDashboard$ | async).tables" [label]="item?.tab">
                                    <div class="title" style="padding-left: 1%;">
                                        <h4 style="font-weight: bold;">{{item?.tableTitle}}</h4>
                                    </div>
                                    <table mat-table [dataSource]="item?.data" class="mat-elevation-z8">
                                        
                                        <ng-container [matColumnDef]="column" *ngFor="let column of item?.th">
                                          <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                                          <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                        </ng-container>
                                      
                                        <tr mat-header-row *matHeaderRowDef="columsDisplay(item?.th)"></tr>
                                        <tr mat-row *matRowDef="let row; columns: columsDisplay(item?.th);"></tr>
                                    </table>
                                </mat-tab>
                              
                            </mat-tab-group>
                        </mat-card>
                        
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="flagAccion==2 && tipoDos" label="Usuarios" animationDuration="1200ms">
                <br>


                <mat-card>
                    <mat-card-content>

                        <div class="text-right">
                            <button mat-raised-button class="buttonPrincipal" (click)="openDialog({})">
                                <mat-icon>
                                    account_circle
                                </mat-icon>
                                Agregar Usuario
                            </button>
                        </div>

                        <div *ngIf="(usuariosFacade.responseUsuarios$| async ).length == 0" class="mt-4">
                            <div class="alert alert-warning text-center" role="alert">
                                No existen usuarios para mostrar
                            </div>
                        </div>
                        <div *ngIf="(usuariosFacade.responseUsuarios$| async ).length != 0" class="mt-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <form [formGroup]="filtro">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Busqueda</mat-label>
                                            <input matInput placeholder="Buscar" formControlName="filtrar">
                                            <mat-icon matSuffix>search</mat-icon>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>

                            <div class="table-responsive"
                                *ngIf="(usuariosFacade.responseUsuarios$| async ).length != 0">

                                <table class="table  bordeTabla tablep" role="table">
                                    <thead class="theadp">

                                        <tr class="trp bg-success text-center">

                                            <th class="thp" scope="col" role="columnheader">Nombre</th>
                                            <th class="thp" scope="col" role="columnheader">Usuario</th>
                                            <th class="thp" scope="col" role="columnheader">Observaciónes</th>
                                            <th class="thp" scope="col" role="columnheader">Activo</th>
                                            <th class="thp" scope="col" role="columnheader">Opciones</th>
                                        </tr>
                                    </thead>


                                    <tbody role="rowgroup" class="tbodyp">

                                        <tr class="text-center trp" role="row"
                                            *ngFor="let item of (usuariosFacade.responseUsuarios$ | async) | search: this.filtro.value.filtrar: 'Nombre' | slice: desde :hasta">
                                            <td role="cell" data-title="Nombre" class="tdp"> {{item.Nombre}}</td>
                                            <td role="cell" data-title="Usuario" class="tdp"> {{item.Usuario}}</td>
                                            <td role="cell" data-title="Observaciónes" class="tdp">
                                                {{item.Observaciones}}</td>
                                            <td role="cell" data-title="Activo" class="tdp">
                                                <span *ngIf="item.Activo === true">
                                                    <mat-icon class="success">check_box</mat-icon>
                                                </span>
                                                <span *ngIf="item.Activo === false">
                                                    <mat-icon class="error">indeterminate_check_box</mat-icon>
                                                </span>
                                            </td>

                                            <td role="cell" data-title="Opciones" class="tdp">
                                                <div class="text-center">

                                                    <button mat-mini-fab class="btnDelete" #tooltip="matTooltip"
                                                        matTooltip="Eliminar Usuario" (click)="eliminarUsuario(item)"
                                                        matTooltipPosition="above">
                                                        <mat-icon>delete_forever</mat-icon>
                                                    </button>

                                                    <button mat-mini-fab class="buttonPrincipal" #tooltip="matTooltip"
                                                        matTooltip="Editar Usuario" (click)="openDialog(item)"
                                                        matTooltipPosition="above">
                                                        <mat-icon>create</mat-icon>
                                                    </button>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <mat-paginator [length]="(usuariosFacade.responseUsuarios$ | async).length "
                                    [pageSize]="pageSize " (page)="next($event) ">
                                </mat-paginator>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-tab>

            <mat-tab label="Reportes" animationDuration="1200ms">
                <mat-card>
                    <mat-card-content>
                        <app-filtro [idCliente]="idCliente"></app-filtro>
                    </mat-card-content>
                </mat-card>
            </mat-tab>



        </mat-tab-group>
    </mat-card-content>
</mat-card>