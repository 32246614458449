import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() { }
  imprimirElemento(elemento: any, hojasEstilo?: any) {

    //var ventana = window.open('','PRINT','height=600,width=800');
    var ventana = window.open(null, 'PRINT', 'fullscreen=1,location=0,titlebar=0,menubar=0,toolbar=0,status=0');
    // ventana.document.write('<html><head><title>' + document.title + '</title>');
    ventana.document.write('<html><head><title></title>');
    ventana.document.write('<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css">');


    if (hojasEstilo.length > 0) {
      for (let estilo of hojasEstilo) {
        ventana.document.write(`<style type="text/css">
              ${estilo.estilo}
              </style>`);
      }
    }
    console.log(elemento);
    ventana.document.write('</head><body >');
    ventana.document.write(elemento.innerHTML);
    ventana.document.write('</body></html>');
    ventana.document.close();
    ventana.focus();
    ventana.onload = function () {
      ventana.print();
      ventana.close();
    };
    return true;


  }
}
