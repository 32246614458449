<div class="matCardHeader">
    Nuevo Perfil
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="perfil" class="mt-2">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field hintLabel="Max 100 caracteres" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput #nombre maxlength="100" placeholder="Nombre" formControlName="nombre">
                    <mat-hint align="end">{{nombre.value?.length || 0}}/100</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field hintLabel="Max 1000 caracteres" appearance="outline">
                    <mat-label>Observaciónes</mat-label>
                    <input matInput #observaciones maxlength="1000" placeholder="Observaciónes" formControlName="observaciones">
                    <mat-hint align="end">{{observaciones.value?.length || 0}}/1000</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <section class="example-section">
                    <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                </section>
            </div>
        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button type="submit" class="buttonPrincipal" (click)="actionPerfil()" [disabled]="perfil.invalid || (perfilFacade.responseCargando$ | async)">Guardar</button>
</mat-dialog-actions>