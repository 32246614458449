import { Component, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { EmpresaServicesService } from 'src/app/services/empresas/empresa-services.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})
export class EmpresasComponent implements OnInit {
listEmpresas:any=[]
flagAccion:number
  constructor(public srvEmpresa:EmpresaServicesService,private modal:MatDialog,private _toast: ToastrServiceLocal ) {
  }
  filtro:FormGroup
  empresaFrom:FormGroup
  ngOnInit(): void {
    this.filtro= new FormGroup({
      filterEmpresa: new FormControl('')
    })
    this.getEmpresas();
    this.empresaFrom= new  FormGroup({
      idEmpresa : new FormControl(''),
      rtn : new FormControl('',Validators.required),
      nombreEmpresa : new FormControl('',Validators.required), 
      telefono : new FormControl('',Validators.required), 
      celular : new FormControl(''), 
      emailContacto : new FormControl('',Validators.email),
      direccion : new FormControl('',Validators.required), 
      nombre : new FormControl(''),
      apellido : new FormControl(''),
      email : new FormControl('',Validators.email),
      alias : new FormControl(''),
      precioMsj : new FormControl('',Validators.required),
      precioEmail : new FormControl('',Validators.required),
    })
  }

  openAgregarEmpresa(tmp){
    this.flagAccion=1;
    this.empresaFrom.reset();
    const dialogRef = this.modal.open(tmp, {
      width: '900px',
  });
  }
  getEmpresas(){
this.srvEmpresa.getEmpresas('',(data)=> {

  this.listEmpresas=data.data.table0;
})
  }
  guardar(){

    var params={
      evento:{
  
        idEmpresa : this.empresaFrom.value.idEmpresa, 
        rtn : this.empresaFrom.value.rtn,
        nombreEmpresa : this.empresaFrom.value.nombreEmpresa,
        telefono: this.empresaFrom.value.telefono,
        celular :  this.empresaFrom.value.celular, 
        emailContacto : this.empresaFrom.value.emailContacto,
        direccion : this.empresaFrom.value.direccion,
        nombre : this.empresaFrom.value.nombre,
        apellido :this.empresaFrom.value.apellido,
        email :this.empresaFrom.value.email,
        alias : this.empresaFrom.value.alias,
        precioMsj : this.empresaFrom.value.precioMsj,
        precioEmail : this.empresaFrom.value.precioEmail
    }  
    
  }
  if (this.empresaFrom.valid) {
    this.srvEmpresa.InsertUpateEmpresa(params,(data)=>{
      this.modal.closeAll();
      this._toast.mensajeSuccess(data.data.table1[0].descripcion,'')
      this.getEmpresas();
    })
    
  } else {
    this._toast.mensajeWarning('Llene los campos obligatorios','');
  }
  }
  openEditar(tmp,item){
    this.flagAccion=2;
    this.empresaFrom.patchValue({
      idEmpresa : item.idEmpresa,
      rtn : item.rtn,
      nombreEmpresa : item.nombreEmpresa, 
      telefono : item.telefono, 
      celular : item.celular, 
      emailContacto : item.correoElectronico,
      direccion : item.direccion, 
      nombre : item.nombre,
      apellido : item.apellido,
      email : item.email,
      alias : item.alias,
      precioMsj : item.precioxMsj,
      precioEmail : item.precioxEmail,

    })
    const dialogRef = this.modal.open(tmp, {
      width: '900px',
  });
  }

  //Paginacion
public pageSize = 10;
public page = 0;
public pageEvent!: PageEvent;
public pageIndex: number = 0;
public desde = 0;
public hasta = 10;
//Paginación de la tabla
next(event: PageEvent) {

if (event.pageIndex === this.pageIndex + 1) {
  this.desde = this.desde + this.pageSize;
  this.hasta = this.hasta + this.pageSize;
}
else if (event.pageIndex === this.pageIndex - 1) {
  this.desde = this.desde - this.pageSize;
  this.hasta = this.hasta - this.pageSize;
}
this.pageIndex = event.pageIndex;
}

validarNumeros(event) {
  if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
      return false;
  } else {
      return true;
  }
}

}
