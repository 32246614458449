import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DatosGeneralesFacadeService } from 'src/app/services/utils/datos-generales-facade.service';
import { PrintService } from 'src/app/services/utils/print.service';
import { environment } from 'src/environments/environment';
import { FiltroFacadeService } from '../filtro/filtro-facade.service';

@Component({
  selector: 'app-reporte-final',
  templateUrl: './reporte-final.component.html',
  styleUrls: ['./reporte-final.component.css']
})
export class ReporteFinalComponent implements OnInit {

  public fecha = new Date();
  public dataUsuario: any;
  public cargando:boolean = false;
  public suscripciones:Subscription = new Subscription();
  public logoEmpresa: any = "";
  public isImageSaved: boolean;
  public encabezadoHTML: HTMLElement;

  constructor(public filtroFacade:FiltroFacadeService, public datosGeneralesFacade: DatosGeneralesFacadeService) { 
     this.dataUsuario = JSON.parse(localStorage.getItem("data_go504"));
     console.log(this.dataUsuario)
   // this.dataUsuario = {};
    
    // this.datosGeneralesFacade.mostrarDatosGeneralesEmpresa("");

    this.suscripciones.add(
      this.datosGeneralesFacade.responseDatosGeneralesEmpresa$.subscribe(
          (result: any) => {
              if (result?.keyImg) {
                  this.filtroFacade.mostrasVoucher(result?.keyImg, (data) => {
                      this.logoEmpresa = data.data[0].table0[0].url;
                      this.isImageSaved = false;
                  });
              }

          }
      )
  );

  }

  ngOnInit(): void {
  }

  columsDisplay(item:[]){
  
    return item.slice();
  }

  

}
