<!-- <h2 mat-dialog-title>Usuario</h2> -->
<div style="float: right; z-index: 2;">
    <button mat-mini-fab (click)="dialog.closeAll()" class="buttonView">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="matCardHeader">
    Usuarios
</div>
<mat-dialog-content class="mat-typography">
    <mat-horizontal-stepper [linear]="isLinear">
        <mat-step label="General" state="general" [stepControl]="generalForm">
            <form [formGroup]="generalForm">
                <div class="row">
                    <div class="mt-2 col-md-12">
                        <mat-form-field hintLabel="Max 200 caracteres" appearance="legacy">
                            <mat-label>Nombre</mat-label>
                            <input matInput #nombre maxlength="200" placeholder="Nombre" formControlName="nombre"
                                autocomplete="off" cdkFocusInitial>
                            <mat-hint align="end">{{nombre.value?.length || 0}}/200</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="mt-2 col-md-12">
                        <mat-form-field hintLabel="Max 200 caracteres" appearance="legacy">
                            <mat-label>Correo</mat-label>
                            <input matInput type="email" maxlength="200" placeholder="Correo" formControlName="correo"
                                autocomplete="off">
                        </mat-form-field>
                    </div>

                </div>
            </form>
            <div class="text-right">
                <button mat-button matStepperNext class="buttonPrincipal">Siguiente</button>
            </div>
        </mat-step>
        <mat-step label="Cuenta" state="account" [stepControl]="formUser">
            <form [formGroup]="formUser">
                <div class="row">
                    <div class="mt-2 col-md-4">
                        <mat-form-field hintLabel="Max 100 caracteres" appearance="legacy">
                            <mat-label>Usuario</mat-label>
                            <input matInput #user maxlength="100" placeholder="Usuario" formControlName="user"
                                autocomplete="off">
                            <mat-hint align="end">{{user.value?.length || 0}}/100</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="mt-2 col-md-4">
                        <mat-form-field hintLabel="Max 20 caracteres" appearance="legacy">
                            <mat-label>Codigo Empleado</mat-label>
                            <input matInput #codEmpleado maxlength="20" placeholder="Codigo Empleado"
                                formControlName="codEmpleado" autocomplete="off">
                            <mat-hint align="end">{{codEmpleado.value?.length || 0}}/20</mat-hint>
                        </mat-form-field>
                    </div>
                    <mat-form-field class="mt-2 col-md-4" appearance="legacy">
                        <mat-label>Empresa</mat-label>
                        <mat-select [disabled]="block" formControlName="idEmpresa" name="empresa">
                          <mat-option *ngFor="let item of listEmpresas" [value]="item.idEmpresa">
                            {{item.nombreEmpresa}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                    <mat-form-field appearance="legacy" class="mt-2 col-md-6" *ngIf="!data.IdUsuario">
                        <mat-label>Contraseña</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña"
                            formControlName="clave" required autocomplete="off">
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>

                    <div class="mt-2 col-md-1 text-left" *ngIf="!data.IdUsuario">
                        <button type="button" mat-mini-fab (click)="generarPassRandom()">
                            <mat-icon>
                                autorenew
                            </mat-icon>
                        </button>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field hintLabel="Max 1000 caracteres" appearance="legacy">
                            <mat-label>Observaciones</mat-label>
                            <textarea matInput #observaciones maxlength="1000" placeholder="Observaciones"
                                formControlName="observaciones" cdkTextareaAutosize cdkAutosizeMinRows="4"
                                autocomplete="off"></textarea>
                            <mat-hint align="end">{{observaciones.value?.length || 0}}/1000</mat-hint>
                        </mat-form-field>
                    </div>


                </div>
                <div class="text-right">
                    <button mat-button matStepperPrevious>Volver</button>
                    <button mat-button matStepperNext class="buttonPrincipal">Siguiente</button>
                </div>
            </form>
        </mat-step>
        <mat-step label="Opciones" state="option" [stepControl]="optionForm">
            <form [formGroup]="optionForm">

                <div class="row" style="background-color: whitesmoke !important;">
                    <div class="mt-2 col-md-6" style="display: flex; ">
                        <div style="justify-content: flex-start; width: 20%; display: flex; align-items: center;">
                            <mat-icon>no_accounts</mat-icon>
                        </div>
                        <div style="justify-content: center; width: 50%; display: flex; align-items: center;">
                            <b>Activo</b>
                        </div>

                        <div style="justify-content: flex-end; width: 30%; display: flex; align-items: center;"
                            class="buttonPanel">
                            <section class="example-section mt-2">
                                <mat-checkbox formControlName="activo"></mat-checkbox>
                            </section>

                        </div>
                    </div>
                    <!-- <div class="mt-2 col-md-6" style="display: flex;">
                        <div style="justify-content: flex-start ; width: 20%; display: flex; align-items: center;">
                            <mat-icon>email</mat-icon>
                        </div>
                        <div style="justify-content: center; width: 50%; display: flex; align-items: center;">
                            <b>Enviar Contraseña por correo</b>
                        </div>

                        <div style="justify-content: flex-end; width: 30%; display: flex; align-items: center;"
                            class="buttonPanel">
                            <section class="example-section mt-2">
                                <mat-checkbox formControlName="envioCorreoEmail"></mat-checkbox>
                            </section>
                        </div>
                    </div> -->

                </div>

                <div class="text-right">
                    <button mat-button matStepperPrevious>Volver</button>
                    <!-- <button mat-button matStepperNext class="buttonPrincipal">Siguiente</button> -->
                    <button mat-raised-button class="buttonPrincipal" type="submit" (click)="guardarUsuario()">Guardar</button>

                </div>
            </form>
        </mat-step>
        <!-- <mat-step label="Modulos" state="modules" [stepControl]="modulosArray">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="legacy">
                        <mat-label>Búsqueda</mat-label>
                        <input matInput placeholder="Buscar " [formControl]="filter" autocomplete="off">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="title">
                <b>Modulos</b>
            </div>
            <div class="row">
                <div class="col-md-6"
                    *ngFor="let item of modulosArray.controls| searchFormReactive: this.filter?.value: ['descripcion']">
                    <ng-container [formGroup]="item">
                        <mat-list role="list">
                            <div mat-subheader class="contentP">
                                <div><p style="margin-bottom: 0px !important;">{{item.value.descripcion}}</p> </div>
                                <div class="posicionFinal">
                                    <mat-slide-toggle formControlName="asignacion"></mat-slide-toggle>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list>
                    </ng-container>

                </div>
            </div>
           
            <div class="text-right">
                <button mat-button matStepperPrevious>Volver</button>
                <button mat-button matStepperNext class="buttonPrincipal">Siguiente</button>

            </div>
        </mat-step> -->

        <!-- <mat-step label="Perfiles" state="profiles" [stepControl]="modulosArray">
           
     
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="legacy">
                        <mat-label>Búsqueda</mat-label>
                        <input matInput placeholder="Buscar " [formControl]="filterProfile" autocomplete="off">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="title">
                <b>Perfiles</b>
            </div>
            <div class="row">
                <div class="col-md-6"
                    *ngFor="let item of profilesArray.controls| searchFormReactive: this.filterProfile?.value: ['descripcion']">
                    <ng-container [formGroup]="item">
                        <mat-list role="list">
                            <div mat-subheader class="contentP">
                                <div><p style="margin-bottom: 0px !important;">{{item.value.descripcion}}</p> </div>
                                <div class="posicionFinal">
                                    <mat-slide-toggle formControlName="asignacion"></mat-slide-toggle>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list>
                    </ng-container>

                </div>
            </div>
           
            <div class="text-right">
                <button mat-button matStepperPrevious>Volver</button>
                <button mat-raised-button class="buttonPrincipal" type="submit" (click)="guardarUsuario()">Guardar</button>
            </div>
        </mat-step> -->

        <ng-template matStepperIcon="general">
            <mat-icon>account_circle</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="account">
            <mat-icon>manage_accounts</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="option">
            <mat-icon>tune</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="modules">
            <mat-icon>dashboard_customize</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="profiles">
            <mat-icon>admin_panel_settings</mat-icon>
        </ng-template>

    </mat-horizontal-stepper>

</mat-dialog-content>