import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { DataApiService } from '../../../services/data-api.service';
import { MensajesHttpService } from '../../../utils/mensajesHttp/mensajes-http.service';
import { ToastrServiceLocal } from '../../../services/toast/toastr.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from '../../../Model/dataApi';
import { usuarioInterface } from '../../../Model/Seguridad/usuarios.Interface';
import { SeguridadService } from 'src/app/services/seguridad/seguridad.service';
import { environment } from 'src/environments/environment';
import * as sha1 from 'sha1';

@Injectable({
  providedIn: 'root'
})
export class UsuariosFacadeService {

  constructor(private _dataApi: SeguridadService, private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal) { }

  //Respuesta Acción
  private Action$ = new Subject<any>();
  public responseAction$: Observable<any> = this.Action$.asObservable();

  private Usuarios$ = new BehaviorSubject<usuarioInterface[]>([]);
  public responseUsuarios$: Observable<usuarioInterface[]> = this.Usuarios$.asObservable();

  private DataUsuario: any;


  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responnotificationargando$: Observable<boolean> = this.Cargando$.asObservable();

  private Error$ = new BehaviorSubject<any>({ hasError: true, recurso: '', messageError: '', errors: [] });
  public responseError$: Observable<any> = this.Error$.asObservable();


  MostrarUsuarios(params: any) {
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/user`, params).pipe(
      tap((result: DataApi | any) => {
        // console.log(result);
        this.Usuarios$.next(result?.data?.table0);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los usuarios', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  MostrarUsuariosByCliente(params: any) {
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/user/cliente/`, params).pipe(
      tap((result: DataApi | any) => {
        this.Usuarios$.next(result?.data?.table0);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los usuarios', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  NuevoUsuario(params: any) {
    params.clave = sha1(params.clave);
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/user`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        console.log(result);
        
        this.Action$.next(result);
        this._toast.mensajeSuccess('Se inserto con exito el usuario', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al insertar el usuario', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  ActualizarUsuario(params: any) {

    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PostDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/user`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();

        this.Action$.next(result);
        this._toast.mensajeSuccess('Se actualizo con exito el usuario', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al actualizar el usuario', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  //Pendiente
  UsuarioMenuActualizar(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PostDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/user/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();

        this.Action$.next(result);
        this.Cargando$.next(false);
        this._toast.mensajeSuccess('Se actualizaorn con exito los permisos del menu', '');

      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al actualizar los permisos del menu', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  UsuarioEliminar(params: any) {
    console.log(params,'aja')
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.DeleteDataApiBody(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/user/`, params).pipe(
      tap((result: DataApi | any) => {
        console.log(result);
        this._toast.clearToasts();

        this.Action$.next(result);
        this._toast.mensajeSuccess('Se elimino con exito el usuario', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al eliminar el usuario', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }
  
  //Pendiente
  UsuarioPerfilIngresar(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/admin/user/profile`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();

        this.Action$.next(result);
        this._toast.mensajeSuccess('Se Inserto con exito el perfil al usuario', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al insertar el perfil', '');
        this.Cargando$.next(false);
        
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  SetearUsuario(item:any) {
    this.DataUsuario = item;
  }

  consumirUsuario() {
    return this.DataUsuario;
  }
}
