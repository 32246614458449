import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from 'src/app/Model/dataApi';
import { mosaicInterface } from 'src/app/Model/Seguridad/mosaic.interface';
import { SeguridadService } from 'src/app/services/seguridad/seguridad.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MosaicMenuFacadeService {

  constructor(private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal, private _dataApi: SeguridadService) { }


  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private Modules$ = new BehaviorSubject<mosaicInterface[]>([]);
  public responseModules$: Observable<mosaicInterface[]> = this.Modules$.asObservable();
  
  //Mostrar todos las requisiciones 
  mostrarModulo(params?: string) {
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/module/`, params).pipe(
      tap((result: DataApi | any) => {
        if (result.hasError === false) {
          this.Modules$.next(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar las modulos', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  getPerfil(params: any, callback) {
    let url = `/${environment.site}/api/notification/${environment.processEnv}/v1/admin/userperfil/${params}/activo/true`;
    const request$ = this._dataApi.GetDataApi(url,'').pipe(
      tap((result: DataApi| any) => {
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los perfiles', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
}
