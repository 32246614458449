import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { DataApi } from '../Model/dataApi';
import { param } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class DataApiService {

  constructor(private http: HttpClient) { }

  GetDataApi(url: string, params: any) {
   /*  const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    }; */
    //    return this.http.get<DataApi>(environment.apiUrl + url, options);
    return this.http.get<DataApi>(environment.apiUrl + url+  params);
  };
  GetDataApiAsync(url: string, params: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    //    return this.http.get<DataApi>(environment.apiUrl + url, options);
    return this.http.get<DataApi>(environment.apiUrl + url + params).toPromise();
  };
  /*   return this.http.get<DataApi>(environment.apiUrl + url + params);
  } */

  PostDataApi(url: string, params: any) {
    return this.http.post<DataApi>(environment.apiUrl + url, params);
  };
  
  DeleteDataApi(url: string, params: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    return this.http.delete<DataApi>(environment.apiUrl + url, options);
  }

  DeleteDataApiUrl(url: string, params: any) {
    
    return this.http.delete<DataApi>(environment.apiUrl + url + params);
  }

  PutDataApi(url: string, params: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    return this.http.put<DataApi>(environment.apiUrl + url, params);
  }

  GetDataApiUtils(url: string, params: any) {
    return this.http.get<DataApi>(environment.apiUrlUtils + url + params);
  };

  PostDataApiUtils(url: string, params: any) {
    return this.http.post<DataApi>(environment.apiUrlUtils + url, params);
  };
}
