<mat-toolbar>
    <span class="titleMosaic">Sistema Notificaciones</span>
    <span class="example-spacer"></span>
    <span fxFlex></span>

    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon style="color: blue;">menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="PerfilAdministrador" mat-menu-item (click)='goAdmin()'>
          <mat-icon>supervised_user_circle</mat-icon>
          <span>Administrador</span>
        </button>
        <button mat-menu-item (click)="salir()">
            <mat-icon>exit_to_app</mat-icon>
          <span>Cerrar sesión</span>

        </button>
      </mat-menu>
      
</mat-toolbar>

    <!-- <div class="pageMosaic" style="margin: 10px;">
        <mat-grid-list #grid [cols]="cols | async" class="myGridList" rowHeight="fit" style="height: 85vh !important" [gutterSize]="'15px'">
            <mat-grid-tile  *ngFor="let tile of titulos"  [rowspan]="1"
                [ngStyle]="{'background-image': 'url(' + tile.image + ')', 'color': 'white', 'font-weight': 'bold'}" class="configImage"  (click)="navegar(tile)">
                <div class="textGrid">
                    <span >{{tile.title}}</span>
                </div>
                <div class="overlay">
                    <span>
                        {{tile.description}}
                    </span>
                </div>

            </mat-grid-tile>

        </mat-grid-list>
    </div> -->

<div class="pageMosaic" style="margin: 10px;">
    <mat-grid-list #grid [cols]="cols | async" class="myGridList" rowHeight="fit" style="height: 85vh !important" [gutterSize]="'15px'">
        <mat-grid-tile  *ngFor="let tile of (mosaicMenu.responseModules$ | async)"  [rowspan]="1"
             [ngStyle]="{'background-image': 'url(' + tile.image + ')',  'color': 'white', 'font-weight': 'bold'}"  class="configImage"  (click)="navegar(tile)">
            <div   class="textGrid">
                <span >{{tile.title}}</span>
            </div>
            <div *ngIf="tile.idModule!=8" class="overlay">
                <span>
                    {{tile.description}}
                </span>
            </div>

        </mat-grid-tile>

    </mat-grid-list>
</div> 
