import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PerfilesFacadeService } from '../perfiles-facade.service';
import { Subscription } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-nuevo-perfil',
  templateUrl: './modal-nuevo-perfil.component.html',
  styleUrls: ['./modal-nuevo-perfil.component.css']
})
export class ModalNuevoPerfilComponent implements OnInit, OnDestroy {
  public suscripciones: Subscription = new Subscription();
  public perfil: FormGroup;
  constructor(public perfilFacade: PerfilesFacadeService, public dialog: MatDialog, 
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.perfil = new FormGroup({
      idPerfil: new FormControl(this.valueForm(this.data?.IdPerfil)),
      nombre: new FormControl(this.valueForm(this.data?.Perfil), [Validators.required]),
      observaciones: new FormControl(this.valueForm(this.data?.Observaciones)),
      activo: new FormControl(this.valueForm(this.data?.Activo))

    });
  }

  /*Inicio Creacion Formularios */
  valueForm(valor:any) {
    return (this.data?.IdPerfil) ? { value: valor, disabled: false }
      : { value: '', disabled: false }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void{
    this.suscripciones.unsubscribe();
  }

  actionPerfil() {
    if (!this.data.IdPerfil){
      this.perfilFacade.NuevoPerfil(this.perfil.value);
    }else{
      this.perfilFacade.EditarPerfil(this.perfil.value);
    }
    this.suscripciones.add(this.perfilFacade.responseAction$.subscribe((result) => {
      if (result.hasError == false) {
        this.perfilFacade.MostrarPerfiles('');
        this.dialog.closeAll();
      }
    }));
  }

}
