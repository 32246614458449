import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MenusFacadeService } from '../../menus/menus-facade.service';
import { PerfilesFacadeService } from '../perfiles-facade.service';
import { SweetAlertService } from '../../../../utils/sweetAlert/sweet-alert.service';
import { ModalNuevoPerfilComponent } from '../modal-nuevo-perfil/modal-nuevo-perfil.component';
import { Subscription } from 'rxjs';
import { permisosInterface } from '../../../../Model/Seguridad/permisos.Interface';
import { UsuariosFacadeService } from '../../usuarios/usuarios-facade.service';
import { MatDialog } from '@angular/material/dialog';
import { PermisosValidarService } from 'src/app/services/seguridad/permisos-validar.service';

@Component({
  selector: 'app-table-perfiles',
  templateUrl: './table-perfiles.component.html',
  styleUrls: ['./table-perfiles.component.css']
})
export class TablePerfilesComponent implements OnInit, OnDestroy {
  @Input() dataFilter: string = "";

  public permisos: permisosInterface;

  private subscripciones: Subscription = new Subscription();
  public data: any;
  public verMenus: boolean = false;

  constructor(public router: Router, public menusFacade: MenusFacadeService,
    public dialog: MatDialog,
    private validarService: PermisosValidarService,
    public usuarioFacade: UsuariosFacadeService,
    public perfilFacade: PerfilesFacadeService,public sweetAlert: SweetAlertService) {
    this.permisos = this.validarService.validate('Perfiles');
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscripciones.unsubscribe();
    // this.menusFacade.itemMenus = '';
  }

  menusPerfil(item:any) {
    item.filtro = 2;
    item.tituloModal = 'Perfil';
    this.perfilFacade.perfilSeleccionado();
    this.menusFacade.menuItem(item);
  }

  eliminarPerfil(item:any) {
    this.sweetAlert.mensajeConConfirmacion('Mensaje Confirmación', `¿Esta seguro que desea eliminar el perfil ${item.Nombre}?`, 'warning')
      .then((res: boolean) => {
        if (res) {
          this.perfilFacade.EliminarPerfil(`${item.IdPerfil}`);
        }
      });
  }

  quitarPerfil(item:any) {
    // console.log(this.usuarioFacade.consumirUsuario());
    let usuarioData = this.usuarioFacade.consumirUsuario();
    this.sweetAlert.mensajeConConfirmacion('Mensaje Confirmación', `¿Esta seguro cambiar el estado al perfil ${item.Perfil}?`, 'warning')
      .then((res: boolean) => {
        if (res) {
          this.perfilFacade.EliminarPerfilUsuario({ idPerfil: item.IdPerfil, idUsuario: usuarioData.IdUsuario });

          this.subscripciones.add(this.perfilFacade.responseAction$.subscribe((result)=>{
              if(result.hasError == false){
                this.perfilFacade.UsuariosPerfilesMostrar(`${item.IdUsuario}/activo/true`);
              }
          }));
        }
      });
  }
  openDialog(data: any) {
    console.log(data);
    const dialogRef = this.dialog.open(ModalNuevoPerfilComponent, {
      width: '60vw',
      maxWidth: '80vw',
      data: data
    });
  }

}
