
<div style="float: right; z-index: 2;">
  <button mat-mini-fab mat-dialog-close class="buttonView">
      <mat-icon>close</mat-icon>
  </button>
</div>

<div class="matCardHeader">
  Asignar Menus
</div>
<div class="mat-typography dialogP">
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step label="Menu" [stepControl]="selectMenu" >
      <div *ngIf="(asignarMenusFacade.responseCargando$ | async)">
        <app-loading [data]="4"></app-loading>
      </div>
      <div *ngIf="!(asignarMenusFacade.responseCargando$ | async) && (asignarMenusFacade.responseMenusAll$| async ).length == 0"
        class="mt-4">
        <div class="alert alert-warning text-center" role="alert">
          No existen menus para mostrar
        </div>
      </div>
      <section *ngIf="(asignarMenusFacade.responseMenusAll$ | async).length != 0 && !(asignarMenusFacade.responseCargando$ | async) ">
        <div class="row">
          <div class="col-md-6">
            <form [formGroup]="filtro">
              <mat-form-field appearance="fill">
                <mat-label>Busqueda</mat-label>
                <input matInput placeholder="Buscar" formControlName="filter" cdkFocusInitial>
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </form>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" role="columnheader" class="text-center">Acciones</th>
                <th scope="col" role="columnheader" class="text-center">Nombre</th>
                <th scope="col" role="columnheader" class="text-center">Descripción</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr
                *ngFor="let item of (asignarMenusFacade.responseMenusAll$ | async) | search: this.filtro.value.filter: parametrosBusqueda | slice: desde :hasta">

                <td role="cell" data-title="Acciones" class="text-center">
                  <button mat-mini-fab  #tooltip="matTooltip" matTooltip="Escoger menu"
                    matTooltipPosition="left" (click)="escogerMenu(item)" class="buttonPrincipal">
                    <mat-icon aria-hidden="false">check_circle</mat-icon>
                  </button>
                  <button mat-mini-fab color="" #tooltip="matTooltip" matTooltip="Ver menus hijos"
                    matTooltipPosition="left" (click)="menuHijos(item)" *ngIf="item.idPadre == 0" class="buttonSecundary">
                    <mat-icon aria-hidden="false">visibility</mat-icon>
                  </button>
                </td>
                <td role="cell" data-title="Nombre" class="text-center">{{item.texto}}</td>
                <td role="cell" data-title="Descripción" class="text-center">{{item.descripcion}}</td>
              </tr>
            </tbody>
          </table>
          <mat-paginator [length]="(asignarMenusFacade.responseMenusAll$ | async).length " [pageSize]="pageSize "
            (page)="next($event) ">
          </mat-paginator>
        </div>
      </section>
    </mat-step>
    <mat-step label="Permisos">
      <div mat-subheader><b>Datos Menu</b></div>
      <div class="row">
        <mat-list>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <mat-list-item>
              <mat-icon mat-list-icon>widgets</mat-icon>
              <div mat-line>Nombre Menu</div>
              <div mat-line>
                <span class="d-inline-block text-truncate">{{menuData?.texto}}</span>
              </div>
            </mat-list-item>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <mat-list-item>
              <mat-icon mat-list-icon>description</mat-icon>
              <div mat-line>Descripcion:</div>
              <div mat-line>
                <span class="d-inline-block text-truncate">{{menuData?.descripcion}}</span>
              </div>
            </mat-list-item>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <mat-list-item>
              <mat-icon mat-list-icon>http</mat-icon>
              <div mat-line>Url</div>
              <div mat-line>
                <span class="d-inline-block text-truncate">
                  {{menuData?.url}}
                </span>
              </div>
            </mat-list-item>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <mat-list-item>
              <i mat-list-icon [class]="menuData?.icono"></i>
              <div mat-line>Icono</div>
            </mat-list-item>
          </div>
        </mat-list>
        </div>

        <form [formGroup]="asignarMenu" (ngSubmit)="guardarAsignacionMenu()" >
          <div *ngIf="dataAsignar.IdUsuario">
            <section class="example-section">
              <label class="example-margin"><b>Permisos:</b></label>
              <div class="row">
                <div class="col-md-3">
                  <mat-checkbox class="example-margin" formControlName="mostrar">Mostrar</mat-checkbox>

                </div>
                <div class="col-md-3">
                  <mat-checkbox class="example-margin" formControlName="ingresar">Ingresar</mat-checkbox>

                </div>
                <div class="col-md-3">
                  <mat-checkbox class="example-margin" formControlName="actualizar">Actualizar</mat-checkbox>

                </div>
                <div class="col-md-3">
                  <mat-checkbox class="example-margin" formControlName="eliminar">Eliminar</mat-checkbox>

                </div>
                <div class="col-md-3">
                  <mat-checkbox class="example-margin" formControlName="vista">Vista</mat-checkbox>

                </div>
              </div>
            </section>
          </div>

          <div class="text-right">
            <button mat-raised-button type="submit" class="buttonPrincipal">Guardar</button>
          </div>
        </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>

