<div *ngIf="(perfilFacade.responseCargando$ | async)">
    <app-loading [data]="4"></app-loading>
</div>

<div class="table-responsive-sm mt-4" *ngIf="!(perfilFacade.responseCargando$ | async)">

    <div *ngIf="!(perfilFacade.responseCargando$ | async) && (perfilFacade.responsePerfiles$| async ).length == 0" class="mt-4">
        <div class="alert alert-warning text-center" role="alert">
            No existen Perfiles para mostrar
        </div>
    </div>

    <table class="table tablep" *ngIf="(perfilFacade.responsePerfiles$| async )?.length != 0">
        <thead class="theadp">
            <tr class="trp">
                <th scope="col" class="text-center thp">Perfiles</th>
            </tr>
        </thead>
        <tbody role="rowgroup" class="tbodyp">
            <tr *ngFor="let item of (perfilFacade.responsePerfiles$ | async)" role="row" class="trp">
                <th role="cell" data-title="{{item.Nombre}}" class="tdp">
                    <div class="profile-list-item row">
                        <img src="../../../../../assets/images/undraw_Credit_card_re_blml (1).svg" class="column1">
                        <div class="profile-list-detail">
                            <div class="profile-name">
                                <div style="vertical-align: inherit;" class="column2">
                                    <p style="vertical-align: inherit; ">{{item.Nombre}} {{item.Perfil}}</p>
                                </div>
                            </div>
                            <div class="profile-description" class="column3">
                                <div style="vertical-align: inherit; ">
                                  <span class="badge badge-pill badge-success" *ngIf="item.Activo === true">Activo</span>
                                  <span class="badge badge-pill badge-warning" *ngIf="item.Activo === false">Desactivado</span>

                                </div>
                            </div>
                        </div>
                        <div class="profile-list-action" class="column4">
                            <!-- <button mat-mini-fab class="btn-delete" #tooltip="matTooltip" matTooltip="Eliminar Perfil" matTooltipPosition="left" (click)="eliminarPerfil(item)" *ngIf="permisos?.eliminar && dataFilter != 'usuarios'"> -->

                            <!-- <button mat-mini-fab class="btnDelete" #tooltip="matTooltip" matTooltip="Eliminar Perfil" matTooltipPosition="left" (click)="eliminarPerfil(item)" *ngIf=" dataFilter != 'usuarios'">
                                <mat-icon>delete_forever</mat-icon>
                            </button> -->

                            <button mat-mini-fab class="btnDelete" #tooltip="matTooltip" matTooltip="Quitar Perfil al usuario" matTooltipPosition="left"
                              (click)="quitarPerfil(item)" *ngIf="dataFilter == 'usuarios'">
                              <mat-icon>close</mat-icon>
                            </button>
                            
                            <!-- <button mat-mini-fab class="buttonPrincipal" #tooltip="matTooltip" matTooltip="Ver Menus" matTooltipPosition="above" (click)="menusPerfil(item)">
                                <mat-icon>visibility</mat-icon>
                            </button> -->
                            <!-- <button mat-mini-fab class="bntGray" #tooltip="matTooltip" matTooltip="Editar Perfil" matTooltipPosition="below" (click)="openDialog(item)" *ngIf="permisos?.actualizar"> -->

                            <button mat-mini-fab class="buttonView" #tooltip="matTooltip" matTooltip="Editar Perfil" matTooltipPosition="below" (click)="openDialog(item)" >
                                <mat-icon>create</mat-icon>
                            </button>
                        </div>
                    </div>
                </th>
            </tr>
        </tbody>
    </table>
</div>
