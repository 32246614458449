<!--==========================
    Header
    ============================-->
<nav class="mobile-nav d-lg-none">
    <ul>
        <li class="active"><a id="1intro" [routerLink] (click)="scrollHtml('intro')">Inicio</a></li>
        <li><a id="1about" [routerLink] (click)="scrollHtml('about')">Sobre nosotros</a></li>
        <li><a id="1services" [routerLink] (click)="scrollHtml('services')">Servicios</a></li>
        <li class="active"><a id="1services" [routerLink]="['/login']">Iniciar Sesión</a></li>
    
       
    </ul>
</nav>
<header id="header">

    <div id="topbar" >
        <div class="container">
            <div class="social-links">
                <a href="#" class="twitter"><i class="fab fa-twitter"></i></a>
                <a href="#" class="facebook"><i class="fab fa-facebook"></i></a>
                <a href="#" class="linkedin"><i class="fab fa-linkedin"></i></a>
                <a href="#" class="instagram"><i class="fab fa-instagram"></i></a>
            </div>
        </div>
    </div>

    <div class="container">

        <div class="logo float-left">
            <!-- Uncomment below if you prefer to use an image logo -->
            <h1>
                <span>GO</span> NOTIFY
            </h1>
            <!-- <a href="#header" class="scrollto"><img src="img/logo.png" alt="" class="img-fluid"></a> -->
        </div>

        <nav class="main-nav navbar navbar-expand-lg float-right d-none d-lg-block">

            <ul>
                <li class="active"><a [routerLink] (click)="scrollHtml('intro')">Inicio</a></li>
                <li><a [routerLink] (click)="scrollHtml('about')">Sobre nosotros</a></li>
                <li><a id="1services" [routerLink] (click)="scrollHtml('services')">Servicios</a></li>
                <li class="active"><a  id="2services" [routerLink]="['/login']">Iniciar Sesión</a></li>
                <!-- <li><a href="#services">Services</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#team">Team</a></li> -->

            
            </ul>
        </nav>
        <!-- .main-nav -->

    </div>
</header>
<!-- #header -->

<!--==========================
      Intro Section
    ============================-->
<section id="intro" class="clearfix">
    <div class="container d-flex h-100">
        <div class="row justify-content-center align-self-center">
            <div class="col-md-6 intro-info order-md-first order-last">
                <h2>¡Incluye todo lo<br><br>que necesitas<br><br>para <span>Crecer!</span></h2>
                <div>
                    <a [routerLink]="['/registro']" class="btn-get-started scrollto">Regístrarse</a>
                  
                </div>
            </div>

            <div class="col-md-6 intro-img order-md-last order-first">
                <img src="../../assets/images/notify/intro.svg" alt="" class="img-fluid">
            </div>
        </div>

    </div>
</section>
<!-- #intro -->

<main id="main">

    <!--==========================
        About Us Section
      ============================-->
    <section id="about">

        <div class="container">
            <div class="row">

                <div class="col-lg-5 col-md-6">
                    <div class="about-img">
                        <img src="../../assets/images/notify/teams.svg" alt="">
                    </div>
                </div>

                <div class="col-lg-7 col-md-6">
                    <div class="about-content">
                        <h2>Sobre nosotros</h2>
                        <h3>Aproveche al máximo las capacidades de GO NOTIFY</h3>
                        <p>Somos un equipo pequeño, por eso siempre buscamos formas de simplificar nuestro trabajo diario y ahorrar tiempo. Evidentemente, tener nuestras listas de contactos, SMS e email en una sola plataforma es de gran ayuda.</p>
                        <!-- <ul>
                            <li><i class="ion-android-checkmark-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                            <li><i class="ion-android-checkmark-circle"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                            <li><i class="ion-android-checkmark-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>

    </section>
    <!-- #about -->


    <!--==========================
        Services Section
      ============================-->
    <section id="services" class="section-bg">
        <div class="container">

            <header class="section-header">
                <h3>Servicios</h3>
                <p>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p>
            </header>

            <div class="row">

                <div class="col-md-6 col-lg-6 wow bounceInUp" data-wow-duration="1.4s">
                    <div class="box">
                        <div class="icon" style="background: #fceef3;"><i class="fas fa-envelope-open-text" style="color: #ff689b;"></i></div>
                        <h4 class="title"><a>Campañas de correo electrónicos</a></h4>
                        <p class="description">Para enviar email masivos, necesita un software que pueda funcionar de manera óptima sin ninguna complicación. Lo más importante es que nuestro equipo experimentado le brinda un alcance más amplio a medida que llega a todas las personas del planeta. Por lo tanto, utilizando la puerta de enlace de Email masivos , establecemos uno de los canales de comunicación más grandes que le brinda un alcance global.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 wow bounceInUp" data-wow-duration="1.4s">
                    <div class="box">
                        <div class="icon" style="background: #fff0da;"><i class="fas fa-sms" style="color: #e98e06;"></i></div>
                        <h4 class="title"><a>SMS</a></h4>
                        <p class="description">Enviar mensajes de texto a sus clientes es la manera perfecta de mantenerse en contacto con ellos y encontrar nuevos. Lo más importante es que este tipo de comunicación combina la rentabilidad. Además, requiere pequeñas inversiones pero produce un alto ROI. Por tanto, aproveche para enviar SMS masivos a disposición de todos los comercios. ¡Como resultado, puede ahorrar tiempo y dinero!</p>
                    </div>
                </div>

            
                <!-- <div class="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
                    <div class="box">
                        <div class="icon" style="background: #eafde7;"><i class="ion-ios-speedometer-outline" style="color:#41cf2e;"></i></div>
                        <h4 class="title"><a href="">Magni Dolores</a></h4>
                        <p class="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.2s" data-wow-duration="1.4s">
                    <div class="box">
                        <div class="icon" style="background: #e1eeff;"><i class="ion-ios-world-outline" style="color: #2282ff;"></i></div>
                        <h4 class="title"><a href="">Nemo Enim</a></h4>
                        <p class="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.2s" data-wow-duration="1.4s">
                    <div class="box">
                        <div class="icon" style="background: #ecebff;"><i class="ion-ios-clock-outline" style="color: #8660fe;"></i></div>
                        <h4 class="title"><a href="">Eiusmod Tempor</a></h4>
                        <p class="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
                    </div>
                </div> -->

            </div>

        </div>
    </section>
    <!-- #services -->



    <!--==========================
        Call To Action Section
      ============================-->
 
    <!-- #call-to-action -->

    <!--==========================
        Features Section
      ============================-->
  
    <!-- #about -->



    <!--==========================
        Clients Section
      ============================-->

    <!-- #testimonials -->

    <!--==========================
        Team Section
      ============================-->
 
    <!-- #team -->

    <!--==========================
        Clients Section
      ============================-->
 
    <!-- #clients -->


    <!--==========================
        Pricing Section
      ============================-->
 
    <!-- #pricing -->



</main>

<!--==========================
      Footer
    ============================-->
<footer id="footer">


    <div class="section-bg">
        <div class="copyright">
            &copy; Copyright <strong>Go504HN</strong>. All Rights Reserved
        </div>

    </div>
</footer>
<!-- #footer -->