import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, EMPTY } from 'rxjs';
import { MensajesHttpService } from '../../../utils/mensajesHttp/mensajes-http.service';
import { ToastrServiceLocal } from '../../../services/toast/toastr.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from '../../../Model/dataApi';
import { DataApiService } from '../../../services/data-api.service';
import { MenusFacadeService } from '../menus/menus-facade.service';
import { perfilInterface } from '../../../Model/Seguridad/perfil.Interface';
import { environment } from 'src/environments/environment';
import { SeguridadService } from 'src/app/services/seguridad/seguridad.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilesFacadeService {

  //Respuesta Acción
  private Action$ = new Subject<any>();
  public responseAction$: Observable<any> = this.Action$.asObservable();

  private Perfiles$ = new BehaviorSubject<perfilInterface[]>([]);
  public responsePerfiles$: Observable<perfilInterface[]> = this.Perfiles$.asObservable();

  private PerfilesSelect$ = new BehaviorSubject<perfilInterface[]>([]);
  public responsePerfilesSelect$: Observable<perfilInterface[]> = this.PerfilesSelect$.asObservable();

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private seleccionado$ = new BehaviorSubject<boolean>(false);
  public responseSeleccionado$: Observable<boolean> = this.seleccionado$.asObservable();


  private Error$ = new BehaviorSubject<any>({ hasError: true, recurso: '', messageError: '', errors: [] });
  public responseError$: Observable<any> = this.Error$.asObservable();


  public perfiles: any[] =
    [{ IdPerfil: "BF155015-5FE4-4A0F-A705-1276E715B4F1", Perfil: "Estudiante", Observaciones: '', Activo: true },
      { IdPerfil: "854D2EBA-15B6-4EE9-99AC-8736C62C226F", Perfil: "Admin", Observaciones: '', Activo: true },
      { IdPerfil: "9F350366-061D-42F3-9FBF-880764C877B1", Perfil: "Recepcionista", Observaciones: '', Activo: true },
      { IdPerfil: "A97EE550-BC30-4D73-81AA-581C08316C0E", Perfil: "Desarrollador", Observaciones: '', Activo: true }
    ];

  constructor(private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal, private _dataApi: SeguridadService, private _menuService: MenusFacadeService) { }

  MostrarPerfiles(params: any) {
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/profile/`, params).pipe(
      tap((result: DataApi | any) => {
        this.Perfiles$.next(result.data.table0);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar el perfil', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  MostrarComboboxPerfiles(params: any) {
    this.Cargando$.next(true);
    this.PerfilesSelect$.next([]);
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/profile/`, params).pipe(
      tap((result: DataApi | any) => {
        this.PerfilesSelect$.next(result.data.table0);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar el perfil', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  //Pendiente
  UsuariosPerfilesMostrar(params: any) {
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/userperfil/`, params).pipe(
      tap((result: DataApi | any) => {
        this.Perfiles$.next(result.data.table0);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Cargando$.next(false);
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los perfiles del usuario', '');
        
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  NuevoPerfil(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PutDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/profile`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        this.Action$.next(result);
        
        this._toast.mensajeSuccess('Se Inserto con exito el perfil', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al insertar el perfil', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  EditarPerfil(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PostDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/profile`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();

        this.Action$.next(result);
        
        this._toast.mensajeSuccess('Se actualizo con exito el perfil', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al actualizar el perfil', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  EliminarPerfil(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.DeleteDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/profile/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        this.Action$.next(result);
        this._toast.mensajeSuccess('Se elimino con exito el perfil', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al eliminar el perfil', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  //Pendiente
  ActualizarPerfilMenu(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PutDataApi('admin/v1/menu/perfil', params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();

        this.Action$.next(result);
        this._toast.mensajeSuccess('Se actualizo con exito los permisos', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._menuService.accionRealizada = true;
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al actualizar los permisos', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }
  
  //Pendiente
  EliminarPerfilUsuario(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.DeleteDataApiBody(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/user/profile`, params).pipe(
      tap((result: DataApi | any) => {
        console.log(result);

        this._toast.clearToasts();
        this.Action$.next(result);
        this._toast.mensajeSuccess('Se elimino el perfil al usuario con exito', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al actualizar el estado al perfil', '');
        this.Cargando$.next(false);

        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  insertarPerfilMenu(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PutDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/profile/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        this.Action$.next(result);
        this._toast.mensajeSuccess('Se inserto con exito el menu al perfil', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._menuService.accionRealizada = true;
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al actualizar los permisos', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  eliminarPerfilMenu(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.DeleteDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/profile/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();

        this.Action$.next(result);
        this._toast.mensajeSuccess('Se elimino con exito el menu al perfil', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._menuService.accionRealizada = true;
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al actualizar los permisos', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  perfilSeleccionado(){
    this.seleccionado$.next(true)
  }


}
