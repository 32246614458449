import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, EMPTY, Subject } from 'rxjs';
import { menuInterface } from '../../../Model/Seguridad/menu.Interface';
import { tap, catchError } from 'rxjs/operators';
import { DataApi } from '../../../Model/dataApi';
import { HttpErrorResponse } from '@angular/common/http';
import { MensajesHttpService } from '../../../utils/mensajesHttp/mensajes-http.service';
import { ToastrServiceLocal } from '../../../services/toast/toastr.service';
import { SeguridadService } from 'src/app/services/seguridad/seguridad.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenusFacadeService {

  constructor(private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal, private _dataApi: SeguridadService) { }


  //Respuesta Acción
  private Action$ = new Subject<any>();
  public responseAction$: Observable<any> = this.Action$.asObservable();

  private Menus$ = new BehaviorSubject<menuInterface[]>([]);
  public responseMenus$: Observable<menuInterface[]> = this.Menus$.asObservable();

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private Error$ = new BehaviorSubject<any>({ hasError: true, recurso: '', messageError: '', errors: [] });
  public responseError$: Observable<any> = this.Error$.asObservable();

  public itemMenus:any;
  public nombreMenu: string = '';

  public previousUrl: string = '';
  public currentUrl: string = '';

  public menusHijos: boolean = false;
  public accionRealizada: boolean = false;

  public idMenuPadre: number = 0;

  MostrarMenu(params: any) {
    this.Menus$.next([]);
    this.menusHijos = false;
    this.Cargando$.next(true);
    this.idMenuPadre = 0;
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/`, params).pipe(
      tap((result: DataApi | any) => {
        console.log(result);
        this.Menus$.next(result);
        this.accionRealizada = false;
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Menus$.next([]);
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los menus', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  MostrarMenuUsuario(params: any) {
    this.Menus$.next([]);
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/user/menu/`, params).pipe(
      tap((result: DataApi | any) => {
        console.log(result,'RESULT')
        this.Menus$.next(result);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Menus$.next([]);
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los menus', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  //Pendiente
  MostrarMenuByFilter(params: any) {
    this.menusHijos = false;
    this.Cargando$.next(true);
    this.idMenuPadre = 0;
    const request$ = this._dataApi.GetDataApi(``, params).pipe(
      tap((result: DataApi | any) => {
        this.Menus$.next(result);

        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Menus$.next([]);
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los menus', '');
        this.Cargando$.next(false);
        
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  NuevoMenu(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/`, params).pipe(
      tap((result: DataApi | any) => {

        this._toast.clearToasts();
        // this.Action$.next(result);
        this.accionRealizada = true;
        this._toast.mensajeSuccess('Se creo con exito el menu', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        // this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al insertar el menu', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  EditarMenu(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PostDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        //  this.Action$.next(result);
        this.accionRealizada = true;
        this._toast.mensajeSuccess('Se edito con exito el menu', '');
        this.Cargando$.next(false);
      }),
      catchError((err: HttpErrorResponse) => {
        this._toast.clearToasts();
        // this.Action$.next(err.error);
        this._mensajesHttp.mostrarErrorHttp(err, 'Ocurrio un error al insertar el menu', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  EliminarMenu(params: any) {
    this.Cargando$.next(true);

    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.DeleteDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        
        this.Action$.next(result);
        this.accionRealizada = true;
        this._toast.mensajeSuccess('Se elimino con exito el menu', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al eliminar el menu', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  EliminarMenuUsuario(params: any) {
    this.Cargando$.next(true);

    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.DeleteDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        this.Action$.next(result);
        this._toast.mensajeSuccess('Se quito con exito el menu', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al quitar el menu', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  EliminarMenuPerfil(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.DeleteDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/profile/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        this.Action$.next(result);
        this._toast.mensajeSuccess('Se quito con exito el menu', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al quitar el menu', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  rutaPrevia() {
    return this.previousUrl;
  }

  subMenuConstruir(idMenuPadre:any) {
    this.Cargando$.next(true);
    this.idMenuPadre = idMenuPadre;
    let arrayMenus: menuInterface[] = [];
    let menus: any[] = this.Menus$.value;
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].idMenu === idMenuPadre) {
        arrayMenus = menus[i].hijos;
      }
    }
    this.Cargando$.next(false);
    this.Menus$.next(arrayMenus);
    this.menusHijos = true;
  }

  MostrarPerfilesMenu(params: any) {
    this.Menus$.next([]);
    this.menusHijos = false;
    this.Cargando$.next(true);
    this.idMenuPadre = 0;
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/menu/`, params).pipe(
      tap((result: DataApi | any) => {
        this.Menus$.next(result);
        this.accionRealizada = false;
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Menus$.next([]);
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los menus', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  menuItem(item:any){
    this.itemMenus = item;
  }

  recargarMenu(){
    if(this.idMenuPadre == 0){
      this.MostrarMenu('');
    }
    else{
      this.subMenuConstruir(this.idMenuPadre);
    }
  }


}
