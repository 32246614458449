import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistroComponent } from './auth/registro/registro.component';
import { PerfilGuard } from './core/guards/perfil.guard';
import { HomeComponent } from './home/home.component';
import { CambioPasswordComponent } from './layout/cambio-password/cambio-password.component';
import { FullComponent } from './layout/full.component';
import { MenuAdminComponent } from './layout/menu-admin/menu-admin.component';
import { MosaicMenuComponent } from './layout/mosaic-menu/mosaic-menu.component';
import { PinSeguridadComponent } from './layout/pin-seguridad/pin-seguridad.component';
import { LoginComponent } from './login/login/login.component';
import { EmpresasComponent } from './modules/empresas/empresas.component';
import { MenusComponent } from './modules/seguridad/menus/menus.component';
import { NuevoMenuComponent } from './modules/seguridad/menus/nuevo-menu/nuevo-menu.component';
import { UsuariosComponent } from './modules/seguridad/usuarios/usuarios.component';
import { DocumentoComponent } from '../app/public/documento/documento.component';
import { TipoDosGuard } from './core/guards/tipo-dos.guard';
import { ClienteDetalleComponent } from './modules/notificaciones/clientes/cliente-detalle/cliente-detalle.component';

const routes: Routes = [
  

  // { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'seguridad',
        loadChildren: () => import('./modules/seguridad/seguridad.module').then(m => m.SeguridadModule)
      },
      {
        path: 'notificaciones',
        loadChildren: () => import('./modules/notificaciones/notificacion.module').then(m => m.NotificacionModule)
      },
      {
        path: 'reportes',
        loadChildren: () => import('./modules/reportes/reportes.module').then(m => m.ReportesModule)
      }

    ]
  },

 {
   path:'inicio',
   component:HomeComponent
 },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'registro',
    component: RegistroComponent
  },
  {
    path: 'mosaicMenu',
    component: MosaicMenuComponent,
    canActivate:[TipoDosGuard]

  },
  {
    path: 'cambioPassword/:idTipoAccion',
    component: CambioPasswordComponent
  },
  {
    path: 'pinSeguridad/:idTipoAccion',
    component: PinSeguridadComponent
  },
  {
    path: 'adminMenu',
    component: MenuAdminComponent,
     canActivate:[PerfilGuard]
     

  },
  {
    path: 'business',
    component: EmpresasComponent,
    canActivate:[PerfilGuard]
  },
  {
    path: 'users',
    component: UsuariosComponent,
    canActivate:[PerfilGuard]

  },
  {
    path: 'nuevoMenu/:idMenu/:idPadre',
    component: NuevoMenuComponent,
    canActivate:[PerfilGuard]

  },

  {
    path: 'menus',
    component: MenusComponent,
    canActivate:[PerfilGuard]

  },

  {
    path: 'document/:id',
    component: DocumentoComponent,

  },
  {
    path: 'd/:id',
    component: DocumentoComponent,

  },

  {
    path: 'clienteDetalle/:idCliente',
    component: ClienteDetalleComponent,

  }, 



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
