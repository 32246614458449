import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

  formregistro:FormGroup;
  constructor() { 
    this.formregistro = new FormGroup({
      nombre: new FormControl('',Validators.required),
      correo: new FormControl('',Validators.required),
      clave: new FormControl('',Validators.required),
      repiteclave: new FormControl('',Validators.required)
    })
  }

  ngOnInit(): void {
  }

  registrar(){
    
  }

}
