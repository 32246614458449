import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataApi } from '../Model/dataApi';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(private http:HttpClient) { }

  GetDataApi(url:string) {
    return this.http.get<DataApi>(environment.apiUrl + url);
  }
  GetDataApiFile(url:string) {
    return this.http.get<DataApi>(environment.apiFile + url);
  }

  PutDataApi(url: string, params: any) {
    return this.http.put<DataApi>(environment.apiUrl + url, params);
  }
  PostDataApi(url: string, params: any) {
    return this.http.post<DataApi>(environment.apiUrl + url, params);
  }
  DeleteDataApi(url: string, params: any) {
    return this.http.delete<DataApi>(environment.apiUrl + url, params);
  }

  PutDataApiFile(url: string, params: any) {
    return this.http.put<DataApi>(environment.apiFile + url, params);
  }


  
}
