import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PerfilesFacadeService } from '../../perfiles/perfiles-facade.service';
import { UsuariosFacadeService } from '../../usuarios/usuarios-facade.service';
import { Subscription } from 'rxjs';
import { MenusFacadeService } from '../menus-facade.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-information',
  templateUrl: './modal-information.component.html',
  styleUrls: ['./modal-information.component.css']
})
export class ModalInformationComponent implements OnInit, OnDestroy {

  public subscripciones: Subscription = new Subscription();
  public permisos: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public menuData: any,
    public menuFacade: MenusFacadeService, public dialog: MatDialog,
    public usuariosFacade:UsuariosFacadeService,
    public perfilFacade: PerfilesFacadeService) {
     console.log(menuData);
    this.permisos = new FormGroup({
      activo: new FormControl(this.valueForm(menuData.data.activo)),
      eliminar: new FormControl(this.valueForm(menuData.data.eliminar)),
      ingresar: new FormControl(this.valueForm(menuData.data.ingresar)),
      mostrar: new FormControl(this.valueForm(menuData.data.mostrar)),
      visible: new FormControl(this.valueForm(menuData.data.visible)),
      vista: new FormControl(this.valueForm(menuData.data.vista)),
      actualizar: new FormControl(this.valueForm(menuData.data.actualizar)),
      idMenu: new FormControl(this.valueForm(menuData.data.idMenu)),
      idPerfil: new FormControl(this.valueForm(menuData.idProfile)),
      idUsuario: new FormControl(this.valueForm(menuData.idProfile)),
    });
  }

  /*Inicio Creacion Formularios */
  valueForm(value:any) {
    return (this.menuData.idProfile) ? { value: value, disabled: false }
      : { value: value, disabled: true }
  }

  ngOnInit(): void {
  }
  ngOnDestroy() : void{
    this.subscripciones.unsubscribe();
  }

  editarPermisos() {
    if(this.menuData.filtro == 1){

      this.usuariosFacade.UsuarioMenuActualizar(this.permisos.value);
      console.log(this.permisos.value);
      this.subscripciones.add(this.usuariosFacade.responseAction$.subscribe((result)=>{
        if (result.hasError == false) {
          this.menuFacade.MostrarMenuByFilter({ idUsuario: this.menuFacade.itemMenus.IdUsuario });
          this.dialog.closeAll();
        };
      }));
    }
    if(this.menuData.filtro == 2){
      // console.log('Permisos perfil');

      this.perfilFacade.ActualizarPerfilMenu({
        idPerfil: this.menuData.idProfile,
        permisos: JSON.stringify([this.permisos.value])
      });

      this.subscripciones.add(this.perfilFacade.responseAction$.subscribe((result) => {
        if (result.hasError == false) {
          this.menuFacade.MostrarPerfilesMenu({ idPerfil: this.menuFacade.itemMenus.IdPerfil, opcion: 2 });
          this.dialog.closeAll();
        };
      }));
    }

  }

}
