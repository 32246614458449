
<div *ngIf="(menusFacade.responseCargando$ | async)">
    <app-loading [data]="4"></app-loading>
</div>
<div *ngIf="!(menusFacade.responseCargando$ | async)" class="mt-4 ">

    <div *ngIf="!(menusFacade.responseCargando$ | async) && (menusFacade.responseMenus$| async ).length == 0"
        class="mt-4">
        <div class="alert alert-warning text-center" role="alert">
            No existen menus para mostrar
        </div>
    </div>

    <section *ngIf="(menusFacade.responseMenus$| async ).length != 0">

        <div class="row">
            <div class="col-md-6 ">
                <form [formGroup]="filtro">
                    <mat-form-field appearance="legacy">
                        <mat-label>Busqueda</mat-label>
                        <input matInput placeholder="Buscar " formControlName="filter" autocomplete="off">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </form>
            </div>
        </div>

        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel
                *ngFor="let item of (menusFacade.responseMenus$ | async) | search: this.filtro?.value.filter: parametrosBusqueda | slice: desde :hasta "
                class="mat-expansion-panelPersonalizado" [disabled]="clickButton" (click)="clickButton=false">

                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>{{item.texto || item.Texto}}</b>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div style="justify-content: flex-start; width: 40%; display: flex; align-items: center;">
                            {{item.descripcion}}</div>

                        <div style="justify-content: center; width: 30%; display: flex; align-items: center;">
                            <i class="{{item.icono}} icon text-center" mat-list-icon></i>
                        </div>
                        <div style="justify-content: flex-end; width: 30%; display: flex; align-items: center;"
                            class="buttonPanel">
                            <button mat-mini-fab class="buttonSecundary " #tooltip="matTooltip"
                                matTooltip="Ver mas opciones" matTooltipPosition="above"
                                (click)="openBottomSheet( item.idMenu, 0, item, bottomSheet, 1)">
                                <mat-icon>control_point</mat-icon>
                            </button>
                            <!-- <button mat-mini-fab class="btn-Error" #tooltip="matTooltip" matTooltip="Eliminar Menu" matTooltipPosition="left"
                                (click)="eliminarMenu(item)" *ngIf="!dataProfile">
                                <mat-icon>delete_forever</mat-icon>
                            </button>
                            
                            <button mat-mini-fab class="btn-Error" #tooltip="matTooltip" matTooltip="Quitar Menu" matTooltipPosition="left"
                                (click)="quitarMenu(item)" *ngIf="dataProfile">
                                <mat-icon>backspace</mat-icon>
                            </button> -->
                        </div>

                    </mat-panel-description>
                </mat-expansion-panel-header>


                <div *ngFor="let menuitem of item.hijos" style="padding: 0%;" class="contentAccordion"
                    matExpansionPanelContent>
                    <mat-divider></mat-divider>
                    <div style="display: flex; padding: 5px 24px;">
                        <div style="justify-content: flex-start; width: 10%; display: flex; align-items: center;"
                            mat-line>
                            <i class="{{menuitem.icono}} icon text-center" mat-list-icon></i>
                        </div>
                        <div style="justify-content: flex-start; width: 30%; display: flex; align-items: center;">
                            {{menuitem.texto}}
                        </div>
                        <div style="justify-content: flex-start; width: 30%; display: flex; align-items: center;">
                            {{menuitem.descripcion}}
                        </div>
                        <div style="justify-content: center; width: 15%; display: flex; align-items: center;">
                            {{menuitem.url }}
                        </div>

                        <div style="justify-content: flex-end; width: 15%; display: flex; align-items: center; z-index: 2;"
                            class="buttonPanel">
                            <button mat-mini-fab class="buttonSecundary " #tooltip="matTooltip"
                                matTooltip="Ver mas opciones" matTooltipPosition="above"
                                (click)="openBottomSheet( menuitem.idMenu, menuitem.idPadre, menuitem, bottomSheet, 2)">
                                <mat-icon>control_point</mat-icon>
                            </button>

                        </div>

                    </div>

                </div>



            </mat-expansion-panel>
        </mat-accordion>
        <mat-paginator tourAnchor=" pagination " [length]="(menusFacade.responseMenus$ | async).length "
            [pageSize]="pageSize " (page)="next($event) ">
        </mat-paginator>
        <!-- <div class="table-responsive " *ngIf="(menusFacade.responseMenus$| async ).length !=0 ">

            <table class="table  bordeTabla tablep" role="table ">
                <thead class="theadp">

                    <tr class="trp bg-success text-center ">

                        <th class="thp " scope="col " role="columnheader ">Texto</th>
                        <th class="thp " scope="col " role="columnheader ">Descripcion</th>
                        <th class="thp " scope="col " role="columnheader ">Icono</th>
                        <th class="thp " scope="col " role="columnheader ">Url</th>
                        <th class="thp " scope="col " role="columnheader ">Activo</th>
                        <th class="thp " scope="col " role="columnheader ">Opciones</th>
                    </tr>
                </thead>


                <tbody role="rowgroup " class="tbodyp">

                    <tr class="text-center trp " role="row " *ngFor="let item of (menusFacade.responseMenus$ | async) | search: this.filtro?.value.filter: parametrosBusqueda | slice: desde :hasta ">
                        <td role="cell " data-title="Texto " class="tdp "> {{item.texto || item.Texto}}</td>
                        <td role="cell " data-title="Descripción " class="tdp "> {{item.descripcion}}</td>
                        <td role="cell " data-title="Icono " class="tdp "><i [class]="item.icono"></i></td>
                        <td role="cell " data-title="Url " class="tdp ">{{item.url}}</td>
                        <td role="cell " data-title="Activo " class="tdp ">
                            <span *ngIf="item.activo===true ">
                                <mat-icon class="success ">check_box</mat-icon>
                            </span>
                            <span *ngIf="item.activo===false ">
                                <mat-icon class="error ">indeterminate_check_box</mat-icon>
                            </span>
                        </td>

                        <td role="cell " data-title="Opciones " class="tdp ">
                            <div class="text-center ">
                                <!-- <button mat-mini-fab class="btn-Error" #tooltip="matTooltip" matTooltip="Eliminar Menu" matTooltipPosition="left" (click)="eliminarMenu(item)" *ngIf="permisos?.eliminar && !dataProfile"> -

                                <button mat-mini-fab class="btn-Error" #tooltip="matTooltip" matTooltip="Eliminar Menu" matTooltipPosition="left" (click)="eliminarMenu(item)" *ngIf="!dataProfile">
                                    <mat-icon>delete_forever</mat-icon>
                                </button>

                                <button mat-mini-fab class="btn-Error" #tooltip="matTooltip" matTooltip="Quitar Menu" matTooltipPosition="left" (click)="quitarMenu(item)" *ngIf="dataProfile">
                                    <mat-icon>backspace</mat-icon>
                                </button>
                                
                                
                                <button mat-mini-fab class="buttonSecundary" #tooltip="matTooltip" matTooltip="Ver mas opciones" matTooltipPosition="above" 
                                (click)="openBottomSheet( item.idMenu, item.idPadre, item, bottomSheet)">
                                    <mat-icon>control_point</mat-icon>
                                </button>
                            </div>
                        </td>

                    </tr>

                </tbody>
            </table>
            <mat-paginator tourAnchor=" pagination " [length]="(menusFacade.responseMenus$ | async).length " [pageSize]="pageSize " (page)="next($event) ">
            </mat-paginator>
        </div> -->
    </section>


</div>

<ng-template #bottomSheet>
    <mat-nav-list>
        <a mat-list-item (click)="openLink($event)" *ngFor="let bottom of dataBottomSheet" (click)="navegar(bottom)">
            <mat-icon>{{bottom.icono}}</mat-icon>
            <span mat-line><b>{{bottom.title}}</b></span>
            <span mat-line>{{bottom.subTitle}}</span>
            <mat-divider></mat-divider>
        </a>

    </mat-nav-list>
</ng-template>