<div class="fixed-plugin">
    <div class="dropdown show-dropdown open show">
        <a (click)="abrir()" aria-expanded="false">
            <i class="fa fa-cog fa-2x"> </i>
        </a>
        <ul #dropdownmenu class="dropdown-menu show" x-placement="bottom-start"
            style="position: absolute; top: 41px; left: -231px; will-change: top, left;">
            <li class="header-title"> Opciones</li>
            <li class="adjustments-line">
                <div class="row">
                    <div class="col-md-3 text-center">
                        <button mat-mini-fab class="btnExportar"
                            (click)="filtroFacade.imprimirContrato()">
                            <mat-icon>print</mat-icon>
                        </button>
                    </div>

                    <div class="col-md-3 text-center">
                        <button mat-mini-fab (click)="filtroFacade.cambiarOrientacion()" class="buttonSecundary">
                            <mat-icon>restore_page</mat-icon>
                        </button>
                    </div>

                    <div class="col-md-3 text-center">
                        <button mat-mini-fab (click)="filtroFacade.downloadPDF()" class="buttonPrincipal">
                            <mat-icon>download</mat-icon>
                        </button>
                    </div>

                    <div class="col-md-3 text-center">
                        <button mat-mini-fab (click)="filtroFacade.recorrerObjetos()" class="buttonPrincipal">
                            <mat-icon>table_view</mat-icon>
                        </button>
                    </div>

                    
                    <div class="ripple-container"></div>
                </div>

            </li>
            <li class="header-title">Estilo</li>

            <li class="button-container text-center">
                <mat-button-toggle-group #group="matButtonToggleGroup">
                    <mat-button-toggle value="text-left" aria-label="Text align left"
                        (click)="filtroFacade.cambiarAlign('text-left') ">
                        <mat-icon>format_align_left</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="text-center" aria-label="Text align center"
                        (click)="filtroFacade.cambiarAlign('text-center') ">
                        <mat-icon>format_align_center</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="text-right" aria-label="Text align right"
                        (click)="filtroFacade.cambiarAlign('text-right') ">
                        <mat-icon>format_align_right</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="text-justify" aria-label="Text align justify"
                        (click)="filtroFacade.cambiarAlign('text-justify') ">
                        <mat-icon>format_align_justify</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </li>
            <li>


            <li class="button-container">

                <div class="row">
                    <mat-form-field appearance="legacy" class="col-md-6 ">
                        <mat-label>Fuente</mat-label>
                        <mat-select [formControl]="font">
                            <mat-option *ngFor="let item of listFont" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="legacy" class="col-md-6">
                        <mat-label>Tamaño</mat-label>
                        <input matInput type="number" [formControl]="font">
                    </mat-form-field>
                </div>

            </li>

            <li class="button-container">
                <div class="text-center">
                    <button mat-raised-button class="buttonPrincipal" (click)="filtroFacade.resetearReporteFinal()">Volver</button>
                </div>

            </li>


        </ul>
    </div>
</div>