import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPipe } from './search.pipe';
import { SearchFormReactivePipe } from './search-form-reactive.pipe';



@NgModule({
  declarations: [SearchPipe, SearchFormReactivePipe],
  imports: [
    CommonModule
  ],
  exports: [
    SearchPipe,
    SearchFormReactivePipe
  ]
})
export class PipeModule { }
