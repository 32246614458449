import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MenusFacadeService } from '../menus-facade.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalInformationComponent } from '../modal-information/modal-information.component';
import { SweetAlertService } from '../../../../utils/sweetAlert/sweet-alert.service';
import { PermisosValidarService } from '../../../../services/seguridad/permisos-validar.service';
import { permisosInterface } from '../../../..//Model/Seguridad/permisos.Interface';
import { Subscription } from 'rxjs';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-table-menus',
  templateUrl: './table-menus.component.html',
  styleUrls: ['./table-menus.component.scss'],
  providers: [
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: MatBottomSheetRef, useValue: {} }
  ],
  encapsulation: ViewEncapsulation.None
})
export class TableMenusComponent implements OnInit, OnDestroy {

  @Input() dataProfile: any;
  public clickButton: boolean = false;
  public permisos: permisosInterface;
  public susbscripciones: Subscription = new Subscription();
  public dataBottomSheet: any[]= [];

  //Variables Menus Hijos
  public idMenuPadre: number = 0;
  public nombreMenu: string = "";

  //Variable del tipo FormGroup, se inicializa en el constructor
  public filtro: FormGroup;

  //Paginacion
  public pageSize = 10;
  public page = 0;
  public pageEvent!: PageEvent;
  public pageIndex: number = 0;
  public desde = 0;
  public hasta = 10;


  public parametrosBusqueda = ['texto'];

  constructor(public menusFacade: MenusFacadeService,
    public router: Router,
    private validarService: PermisosValidarService,
    private _bottomSheet: MatBottomSheet,
    public sweetAlert: SweetAlertService,
    public dialog: MatDialog,
    public menuFacade: MenusFacadeService,
    private _bottomSheetRef: MatBottomSheetRef<any>
    ) {
    this.permisos = this.validarService.validate('Menu');

    //Inicializar el formulario de busqueda
    this.filtro = new FormGroup({
      filter: new FormControl({ value: '', disabled: false })
    });

  }

  ngOnInit(): void {
    this.consumirMenus();
    
  }
  ngOnDestroy(): void {
    //Setear el valor del servicio en vacio ya que es el que nos sirve para filtrar el tipo de contenido
    this.menusFacade.itemMenus = '';
  }

  consumirMenus() {
    console.log(this.dataProfile,'PASO3');
    if (this.dataProfile == 1) {

      this.menusFacade.MostrarPerfilesMenu(this.menusFacade.itemMenus.IdPerfil);
    }
    if (this.dataProfile == 2) {

      this.menusFacade.MostrarMenuUsuario(this.menusFacade.itemMenus.IdUsuario);
    }
    if (!this.dataProfile) {
      //Llamado al servicio para que realice la petición de traer los menus
      this.menusFacade.MostrarMenu('');
    }
  }



  //LLamado al componente del bottom sheet
  openBottomSheet(idMenu:number, idMenuPadre:number, data:any, template:any, idTipo): void {
    this.clickButton = true;

    if (idTipo == 1 &&this.dataProfile!=2) {
      this.dataBottomSheet = [
          { title: 'Agregar Menu', subTitle: 'Agregar un sub menu', ruta: `/nuevoMenu/0/${idMenu}`, icono: 'edit', tipo: 1 },
        // {
        //   title: 'Ver Permisos', subTitle: 'Modal para editar los permisos del menu',
        //   icono: 'lock_open',
        //   data: data,
        //   tipo: 2,
        //   nombreProfile: this.menusFacade.itemMenus?.Nombre,
        //   idProfile: (this.menusFacade.itemMenus?.IdPerfil) ? this.menusFacade.itemMenus?.IdPerfil : this.menusFacade.itemMenus?.IdUsuario,
        //   filtro: this.menusFacade.itemMenus?.filtro
        // },
          { title: 'Eliminar Menu', subTitle: 'Eliminar Menu', icono: 'delete_forever', item: data ,tipo: 3 }
      ];
    } 
    if(idTipo == 2 &&this.dataProfile!=2) {

      this.dataBottomSheet = [
        //  { title: 'Agregar Menu', subTitle: 'Agregar un sub menu', ruta: `/nuevoMenu/0/${idMenu}`, icono: 'edit', tipo: 1 },
         //{ title: 'Eliminar Menu', subTitle: 'Eliminar Menu', icono: 'delete_forever', item: data ,tipo: 3 }

        // {
        //   title: 'Ver Permisos',
        //   subTitle: 'Modal para editar los permisos del menu',
        //   icono: 'visibility',
        //   data: data,
        //   tipo: 2,
        //   nombreProfile: this.menusFacade.itemMenus?.Nombre,
        //   idProfile: (this.menusFacade.itemMenus?.IdPerfil) ? this.menusFacade.itemMenus?.IdPerfil : this.menusFacade.itemMenus?.IdUsuario,
        //   filtro: this.menusFacade.itemMenus?.filtro
        // }
         { title: 'Eliminar Menu', subTitle: 'Eliminar Menu', icono: 'delete_forever', item: data ,tipo: 3 }
        
      ];
    }
    if (this.dataProfile==3) {
      this.dataBottomSheet.push({ title: 'Editar Menu', subTitle: 'Editar nombre, ruta, icono', ruta: `/nuevoMenu/${idMenu}/${idMenuPadre}`, icono: 'edit', tipo: 1 })
        
      //  this.dataBottomSheet.push({ title: 'Eliminar Menu', subTitle: 'Eliminar Menu', icono: 'delete_forever', item: data ,tipo: 3 })
      
    }
    if (this.dataProfile==2) {
      this.dataBottomSheet=[{ title: 'Quitar Menu', subTitle: 'Quitar Menu', item: data ,icono: 'backspace', tipo: 4 }]
    }
    this._bottomSheet.open(template);
  }

  //LLamado al servicio para eliminar un menu
  eliminarMenu(item:any) {
    this.sweetAlert.mensajeConConfirmacion('Mensaje Confirmación', `¿Esta seguro que desea eleminar el menu ${item.texto}?`, 'warning')
      .then((res: boolean) => {
        if (res) {
          this.menusFacade.EliminarMenu(`${item.idMenu}`);
          this.susbscripciones.add(this.menusFacade.responseAction$.subscribe((result) => {
            if (result.hasError == false) {

              this.menusFacade.MostrarMenu('');

            }
          }));
        }
      });
  }

  //Paginación de la tabla
  next(event: PageEvent) {

    if (event.pageIndex === this.pageIndex + 1) {
      this.desde = this.desde + this.pageSize;
      this.hasta = this.hasta + this.pageSize;
    }
    else if (event.pageIndex === this.pageIndex - 1) {
      this.desde = this.desde - this.pageSize;
      this.hasta = this.hasta - this.pageSize;
    }
    this.pageIndex = event.pageIndex;
  }

  openDialog(data:any) {
    const dialogRef = this.dialog.open(ModalInformationComponent, {
      width: '80vw',
      maxWidth: '100vw',
      data: data
    });
  }

  quitarMenu(item:any) {
    let result = this.sweetAlert.mensajeConConfirmacion(`¿Está seguro de que desea eliminar el menu ${item.texto}?`, '', 'warning');
    result.then((result) => {
      if (result == true) {
        if (this.menusFacade.itemMenus.IdUsuario) {
          this.menusFacade.EliminarMenuUsuario(`${this.menusFacade.itemMenus.IdUsuario}/${item.idMenu}`);
        }
        if (this.menusFacade.itemMenus.IdPerfil) {
          this.menusFacade.EliminarMenuPerfil(`${this.menusFacade.itemMenus.IdPerfil}/${item.idMenu}`);
        }
        this.susbscripciones.add(this.menusFacade.responseAction$.subscribe((result) => {
          if (result.hasError == false) {
            if (this.dataProfile == 1) {
              this.menusFacade.MostrarPerfilesMenu(this.menusFacade.itemMenus.IdPerfil);

            }
            if (this.dataProfile == 2) {
              this.menusFacade.MostrarMenuUsuario(this.menusFacade.itemMenus.IdUsuario);

            }
          }
        }));
      }
    });

  }

  //Bottom Sheet
  openLink(event: MouseEvent): void {
    this._bottomSheet.dismiss();
    event.preventDefault();
  }

  navegar(data:any) {
    if (data.tipo == 1) {
      this.router.navigateByUrl(data.ruta);
    }
    if (data.tipo == 2) {
      this.openModalInformacionMenu(data);
    }
    if (data.tipo == 3) {
      this.eliminarMenu(data.item)
    }
    if (data.tipo == 4) {
      this.quitarMenu(data.item);
    }
  }

  openModalInformacionMenu(data:any) {
    const dialogRef = this.dialog.open(ModalInformationComponent, {
      width: '80vw',
      maxWidth: '100vw',
      data: data
    });
  }


}
