import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MenusFacadeService } from '../menus/menus-facade.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { menuInterface } from '../../../Model/Seguridad/menu.Interface';
import { AsignarMenusFacadeService } from './asignar-menus-facade.service';
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-asinar-menus-modal',
  templateUrl: './asinar-menus-modal.component.html',
  styleUrls: ['./asinar-menus-modal.component.css']
})
export class AsinarMenusModalComponent implements OnInit, OnDestroy {

  public suscription: Subscription = new Subscription();

  @ViewChild('stepper') private myStepper!: MatStepper;
  public menuData!: menuInterface;
  public isLinear = true;
  public dataAsignar: any;
  public filtro: FormGroup;

  public asignarMenu: FormGroup;
  public selectMenu: FormGroup;

  //Paginacion
  public pageSize = 5;
  public page = 0;
  public pageEvent: PageEvent;
  public pageIndex: number = 0;
  public desde = 0;
  public hasta = 5;

  public parametrosBusqueda = ['texto'];

  constructor(public menusFacade: MenusFacadeService,
    @Inject(MAT_DIALOG_DATA) public data: any, private matDialog: MatDialog, public asignarMenusFacade: AsignarMenusFacadeService) {
    this.dataAsignar = this.menusFacade.itemMenus;
       
    this.asignarMenusFacade.MostrarMenu('');

    //Inicializar el formulario de busqueda
    this.filtro = new FormGroup({
      filter: new FormControl({ value: '', disabled: false })
    });

    this.selectMenu = new FormGroup({
      idMenu: new FormControl('', [Validators.required])
    })

    this.asignarMenu = new FormGroup({
      idMenu: new FormControl(''),
      idPerfil: new FormControl((this.dataAsignar.IdPerfil) ? this.dataAsignar.IdPerfil : ''),
      idUsuario: new FormControl((this.dataAsignar.IdUsuario) ? this.dataAsignar.IdUsuario : ''),
      mostrar: new FormControl(true),
      ingresar: new FormControl(true),
      actualizar: new FormControl(true),
      eliminar: new FormControl(true),
      vista: new FormControl(true)
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy():void{
    this.suscription.unsubscribe();
  }

  //Paginación de la tabla
  next(event: PageEvent) {

    if (event.pageIndex === this.pageIndex + 1) {
      this.desde = this.desde + this.pageSize;
      this.hasta = this.hasta + this.pageSize;
    }
    else if (event.pageIndex === this.pageIndex - 1) {
      this.desde = this.desde - this.pageSize;
      this.hasta = this.hasta - this.pageSize;
    }
    this.pageIndex = event.pageIndex;
  }

  menuHijos(item:any) {
    this.asignarMenusFacade.subMenuConstruir(item?.idMenu);
  }
  escogerMenu(item:any) {
    this.isLinear = false;
    this.selectMenu.get('idMenu').setValue(item.idMenu);
    this.asignarMenu.controls['idMenu'].setValue(item.idMenu);
    this.myStepper.next();
    this.menuData = item;
  }

  guardarAsignacionMenu() {
    // console.log(this.asignarMenu.value);
    if (this.dataAsignar.IdPerfil) {
      this.asignarMenusFacade.asignarMenusPerfil(this.asignarMenu.value);
    }
    if (this.dataAsignar.IdUsuario) {
      // console.log('asignar menu usuario');
      this.asignarMenusFacade.asignarMenusUsuario(this.asignarMenu.value);
    }

    this.suscription.add(this.asignarMenusFacade.responseAction$.subscribe((result) => {
      if (result.hasError == false) {
        this.matDialog.closeAll();
        if (this.dataAsignar.IdPerfil) {
          // console.log(this.menusFacade.itemMenus);
          this.menusFacade.MostrarPerfilesMenu(`/${this.menusFacade.itemMenus.IdPerfil}`);
        }
        if (this.dataAsignar.IdUsuario) {
          this.menusFacade.MostrarMenuUsuario(`${this.menusFacade.itemMenus.IdUsuario}` );
        }

      }
    }));

  }
}
