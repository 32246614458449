<div class="bookshelf_wrapper" *ngIf="data === 1">
    <ul class="books_list">
        <li class="book_item first"></li>
        <li class="book_item second"></li>
        <li class="book_item third"></li>
        <li class="book_item fourth"></li>
        <li class="book_item fifth"></li>
        <li class="book_item sixth"></li>
    </ul>
    <div class="shelf"></div>
</div>

<div *ngIf="data === 2" class="text-center">
    <img src="../../../assets/images/Loading/animation_500_kdqe72ts.gif" alt="" class="loadingWidht img-fluid">
</div>

<div *ngIf="data === 4" style="display: flex; align-items: center; justify-content: center; ">
    <mat-spinner></mat-spinner>
</div>
