import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { paginatorInterface } from 'src/app/Model/Shared/paginator.interface';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit{
  @Output() eventPaginator = new EventEmitter();

  @Input() dataPaginator: paginatorInterface = {desde: 0, hasta: 0, itemPagina: 0, pageIndex: 0, totalItem: 0, lenght: 0}

  public pageSize = 10;
  public pageIndex: number = 1;
  public desde = 1;
  public hasta = 10;
  public totalItem: number = 0;

  constructor() {
   
  }
  
  ngOnInit(): void {
    
    
  }

  next(idAccion) {
    this.eventPaginator.emit(idAccion);
  }

}
