<mat-dialog-content class="mat-typography">
    <mat-list role="list">
        <mat-list-item role="listitem" class="title"><b>{{menuData?.data.texto}}</b></mat-list-item>
        <mat-list-item role="listitem">
            <b>Descripción:</b> <span class="d-inline-block text-truncate">{{ menuData?.data.descripcion}}</span>
        </mat-list-item>
        <mat-list-item role="listitem">
            <b>Icono:</b> <span class="d-inline-block text-truncate">{{ menuData?.data.icono}} </span>
            <i class="{{menuData?.data.icono}}"></i>
        </mat-list-item>
        <mat-list-item role="listitem">
            <b>Url:</b> <span class="d-inline-block text-truncate">{{menuData?.data.url}}</span>
        </mat-list-item>
        <mat-list-item role="listitem">
            <b>Template Url:</b> <span class="d-inline-block text-truncate">{{ menuData?.data.urlTemplate}}</span>
        </mat-list-item>
    </mat-list>
    <form [formGroup]="permisos" (ngSubmit)="editarPermisos()">
        <div class="row">
            <div class="col-md-4">
                <section class="example-section">
                    <mat-checkbox formControlName="visible">Visible</mat-checkbox>
                </section>
            </div>
            <div class="col-md-4">
                <section class="example-section">
                    <mat-checkbox formControlName="activo">Activo</mat-checkbox>

                </section>
            </div>
            <div class="col-md-4">
                <section class="example-section">
                    <mat-checkbox formControlName="actualizar">Actualizar</mat-checkbox>

                </section>
            </div>
            <div class="col-md-4">
                <section class="example-section">
                    <mat-checkbox formControlName="eliminar">Eliminar</mat-checkbox>

                </section>
            </div>
            <div class="col-md-4">
                <section class="example-section">
                    <mat-checkbox formControlName="ingresar">Ingresar</mat-checkbox>

                </section>
            </div>
            <div class="col-md-4">
                <section class="example-section">
                    <mat-checkbox formControlName="mostrar">Mostrar</mat-checkbox>
                </section>
            </div>
            <div class="col-md-4">
                <section class="example-section">
                    <mat-checkbox formControlName="vista">Vista</mat-checkbox>
                </section>
            </div>

        </div>
        <div class="text-right" *ngIf="menuData?.idProfile">
            <button mat-raised-button class="button-principal" type="submit"
                [disabled]="perfilFacade.responseCargando$ | async">Guardar</button>
        </div>
    </form>
</mat-dialog-content>