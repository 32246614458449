import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MosaicMenuFacadeService } from 'src/app/layout/mosaic-menu/mosaic-menu-facade.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilGuard implements CanActivate {
  constructor(private srvPerfil :MosaicMenuFacadeService){
  }
  PIN:String=''

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


      this.PIN=localStorage.getItem('RLZ');
      if (this.PIN=='8C7FDBBE-DY4E-40L0-83A1-8AFN55DE9522') {
        return true
      } else {
        return false
      }

      
    }
  
}
