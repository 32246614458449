import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { DatosGeneralesFacadeService } from 'src/app/services/utils/datos-generales-facade.service';
import { FiltroFacadeService } from './filtro-facade.service';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.css']
})
export class FiltroComponent implements OnInit, OnDestroy {
  @Input() idCliente: number;
  
  public suscripciones = new Subscription();
  public elementoForm = new FormArray([]);
  public idReporte = new FormControl(0);
  public reporte = '';
  public verReportes:boolean = true;
  public elementosFiltro: any[] = [


    { tipoElemento: 'select', titulo: 'Institución', orden: 1, propiedad: 'idInstitucion', obligatorio: true, grid: 'col-md-12', data: [{ id: 1, nombre: 'G0504' }, { id: 2, nombre: 'G0505' }] },
    { tipoElemento: 'date', titulo: 'Fecha Inicial', orden: 2, propiedad: 'fechaInicial', obligatorio: true, grid: 'col-md-6' },
    { tipoElemento: 'date', titulo: 'Fecha Final', orden: 4, propiedad: 'fechaFinal', obligatorio: true, grid: 'col-md-6' },
    { tipoElemento: 'select', titulo: 'Estado', orden: 6, propiedad: 'estado', data: [{ id: 1, nombre: 'Aprobado' }, { id: 2, nombre: 'Rechazada' }] },

  ];

  constructor(public filtroFacade: FiltroFacadeService, public toast: ToastrServiceLocal, public datosGeneralesFacade: DatosGeneralesFacadeService) {
    this.filtroFacade.mostrarReportes('');

    this.suscripciones.add(
      this.idReporte.valueChanges.subscribe((result) => {
        if (result != 0) {
          let idReporte = result;
          this.filtroFacade.mostrarConfiguracionReportes(idReporte);
        }
      })
    );

    
    this.suscripciones.add(
      this.filtroFacade.responseConfiguracionReporte$.subscribe((result) => {
        if (result.length > 0) {
          this.setearElementos(result);
        }
      })
    );


    // this.filtroFacade.generarReporte({
    //   "idReporte": 1,
    //   "parametro": {
    //     "idInstitucion": "3",
    //     "fechaInicial": "2021-01-01",
    //     "fechafinal": "2021-12-31"
    //   },
    //   "idModulo": 1002
    // });

  }


  ngOnInit(): void {

  }

  ngOnDestroy(){
    this.filtroFacade.resetearReportes();
  }

  setearElementos(elementosFiltro: any[]) {
    //Remueve los controles anteriores en caso de existir
    this.elementoForm.clear();

    elementosFiltro.forEach(e => {

      let elemento = new FormGroup({

        tipoElemento: new FormControl(e.elemento ? e.elemento : ''),
        titulo: new FormControl(e.titulo ? e.titulo : ''),
        orden: new FormControl(e.orden ? e.titulo : 1),
        propiedad: new FormControl(e.propiedad ? e.propiedad : ''),
        grid: new FormControl(e.grid ? e.grid : ''),
        data: new FormControl(e.data ? JSON.parse(e.data) : []),
        multiSelectValue: new FormControl(''),
        value: new FormControl('', e.obligatorio && [Validators.required]), //Si la propiedad obligatorio es true entonces pondra obligatorio que el usuario devuelva un valor

      });

      this.elementoForm.push(elemento);

    });

  }

  generarReporte() {
    if (this.elementoForm.invalid) {
      this.toast.mensajeWarning('Es necesario completar los campos obligatorios', '');
      this.elementoForm.markAllAsTouched();
      return;
    }

    let bodyObject = {}
    this.elementoForm.value.forEach((res) => {
      bodyObject[res.propiedad.replace(/ /g, "")] = String(res.value);
    });
    bodyObject["idCliente"] = this.idCliente
    let params = {

      idReporte: this.idReporte.value,
      parametro: bodyObject
    }
    this.filtroFacade.generarReporte(params);
  }

  onChange(i, e:any[]){
 
    let descripciones:any[] = [];
    e.forEach(e => {
      this.elementoForm.controls[i].value.data.forEach(element => {
        if(e === element.id){
          descripciones.push(element.descripcion);
        }
      });
    });

    this.elementoForm.controls[i].get('multiSelectValue').setValue(descripciones);
    
    
    console.log(this.elementoForm.value)

  }

  seleccionar(item){
    this.verReportes = false;
    this.reporte = item.descripcion;
    this.idReporte.setValue(item.id)
  }


}
