import { HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  public token: string;
  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    this.token = localStorage.getItem('token_go504');
   
    if (this.token == null) {
      this.token = '';
    }

    let request = req;
    // req.body.idSistema = environment.idSistema;
    if (this.token) {
      request = req.clone({
        setHeaders: {
          authorization: 'Bearer ' + this.token,
        }
      });


    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.router.navigateByUrl('/login');
        }

        return throwError(err);

      })
    );
  }
}
