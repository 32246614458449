import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatGridList } from '@angular/material/grid-list';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SidemeduFacadeService } from '../side-menu/sidemedu-facade.service';
import { MosaicMenuFacadeService } from './mosaic-menu-facade.service';


export interface Tile {
  color: string;
  cols?: number;
  rows: number;
  text: string;
  image?: string;
}

@Component({
  selector: 'app-mosaic-menu',
  templateUrl: './mosaic-menu.component.html',
  styleUrls: ['./mosaic-menu.component.scss']
})
export class MosaicMenuComponent implements OnInit, AfterContentInit{

  @ViewChild('grid', { static: true }) grid: MatGridList;
  cols: Subject<any> = new Subject();
  public breakpoint: number;
  public suscripciones: Subscription = new Subscription();
  admin:boolean=true;
  // tiles: Tile[] = [
  //   { text: '', rows: 1, color: 'lightblue', image:'https://www.flexxus.com.ar/wp-content/uploads/2019/02/25e2f30a103dfee5ee8d753f84e399a2.jpg' },
  //   { text: 'Contabilidad', rows: 1, color: 'lightblue', image: 'https://economipedia.com/wp-content/uploads/Tipos-de-contabilidad-1.jpg' },
  //   { text: '', rows: 2, color: 'lightgreen', image: 'https://previews.123rf.com/images/merznatalia/merznatalia1510/merznatalia151001411/46269696-carrito-de-compras-con-iconos-de-software-de-aplicaci%C3%B3n-en-la-computadora-port%C3%A1til.jpg' },
  //   { text: 'Recursos Humanos', rows: 1, color: 'lightpink' },
  //   { text: 'Lotificación', rows: 1, color: '#DDBDF1' },
  // ];
  tiles: Tile[] = [
    { text: '', rows: 1, color: 'lightblue', image:'https://www.flexxus.com.ar/wp-content/uploads/2019/02/25e2f30a103dfee5ee8d753f84e399a2.jpg' },
    { text: 'Contabilidad', rows: 1, color: 'lightblue', image: 'https://economipedia.com/wp-content/uploads/Tipos-de-contabilidad-1.jpg' },
    { text: '', rows: 2, color: 'lightgreen', image: 'https://previews.123rf.com/images/merznatalia/merznatalia1510/merznatalia151001411/46269696-carrito-de-compras-con-iconos-de-software-de-aplicaci%C3%B3n-en-la-computadora-port%C3%A1til.jpg' },
    { text: 'Recursos Humanos', rows: 1, color: 'lightpink' },
    { text: 'Lotificación', rows: 1, color: '#DDBDF1' },
  ];
  titulos:any[] = [
    {text:'Mensajes',rows:1,color:'',title:'Mensajes',description:'Mensajes',url:'notificaciones/sms',idModule:3002, image:'https://www.vocalcom.com/wp-content/uploads/2020/09/7-ways-to-optimize-email-customer-service-1.jpg'},
    {text:'Campaña Mensajes',rows:1,color:'',title:'Campaña Mensajes',description:'Campaña Mensajes',idModule:3002,url:'notificaciones/campasms',image:'https://www.marketingdirecto.com/wp-content/uploads/2019/06/mailrelay.jpg'},
    {text:'Campaña Mensajes',rows:2,color:'',title:'Email',description:'Email',url:'notificaciones/email',idModule:3002,image:'https://blog.mailrelay.com/wp-content/uploads/2017/06/emailing.png'},
    {text:'Campaña Mensajes',rows:2,color:'',title:'Campaña Email',description:'Campaña Email',idModule:3002,url:'notificaciones/campaemail',image:'https://www.womgp.com/blog/wp-content/uploads/2019/06/e-mail-marketing-2019.png'}
  ]
  
  public gridByBreakpoint = {
    xl: 3,
    lg: 3,
    md: 3,
    sm: 2,
    xs: 1
  }


  constructor(private observableMedia: MediaObserver, public mosaicMenu: MosaicMenuFacadeService, public authService:AuthService, public sideMenuFacade:SidemeduFacadeService, public router:Router) {
    this.cols.next(4);
    this.mosaicMenu.mostrarModulo('');
    
  }
  validarMenu:boolean=false;
  ngOnInit() {
    this.cols.subscribe((result) => {
      
    });
    this.getPerfil();
  }
  PerfilAdministrador:boolean=false
  PerfilNotificaciones:boolean=false
  getPerfil(){
    var objeto=JSON.parse(localStorage.getItem("data_go504"))
    var id=objeto.IdUsuario;
  this.mosaicMenu.getPerfil(id,data=>{
   var perfiles= data.data.table0

   perfiles.map((item=>{

   }))

   for (let i = 0; i < perfiles.length; i++) {
     
     if (perfiles[i].IdPerfil=='8C7F9BBE-D04E-40E0-8391-8AFB55DE9522') {
       this.PerfilAdministrador=true;
       localStorage.setItem('RLZ','8C7FDBBE-DY4E-40L0-83A1-8AFN55DE9522');
     }
     if (perfiles[i].IdPerfil=='F30E30BE-1FBD-4E84-8560-AE0E75C64E0E') {
      this.PerfilNotificaciones=true;
    }
     
   }
  })
  }

  ngAfterContentInit() {
    this.observableMedia.asObservable().subscribe((change: MediaChange[]) => {
      this.cols.next(this.gridByBreakpoint[change[0].mqAlias]);
    });
  }

  salir() {
    this.authService.CerrarSesion();
  }

  navegar(item) {
     let menu = JSON.parse(localStorage.getItem("menusTotales"));
     menu = menu.filter(m => m.idModulo === item.idModule);
     localStorage.setItem("idModulo", item.idModule);
    this.sideMenuFacade.setearMenusFiltrado(menu);
    this.router.navigateByUrl('/dashboard');
    localStorage.setItem('dasboard',item.idModule);
  }

  goAdmin(){
    this.router.navigate(['adminMenu']);
  }
}
