import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFormReactive'
})
export class SearchFormReactivePipe implements PipeTransform {

  transform(controls: any[], arg: any, columna: string[]): any {
    const resultPosts: any = [];
    if (arg == '') {
      return controls;
    }

    for (let i = 0; i < columna.length; i++) {
      for (const post of controls) {
        if (post.value[columna[i]].toLowerCase().indexOf(arg.toLowerCase()) > -1) {
          if (!resultPosts.includes(post.value)) {
            resultPosts.push(post);
          }
        }
      }
    }
    return resultPosts;
  }
}
