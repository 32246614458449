import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FiltroFacadeService } from '../filtro/filtro-facade.service';

@Component({
  selector: 'app-fixed-filtro',
  templateUrl: './fixed-filtro.component.html',
  styleUrls: ['./fixed-filtro.component.css']
})
export class FixedFiltroComponent implements OnInit {
  @ViewChild('dropdownmenu') dropDown: ElementRef<HTMLInputElement>;

  public fontSize = new FormControl(12);
  public font = new FormControl('');

  public listFont = ['Arial, sans-serif','Times New Roman, serif','Verdana, sans-serif','Helvetica, sans-serif','Georgia, serif'];

  constructor(public filtroFacade: FiltroFacadeService) { }

  ngOnInit(): void {
    this.font.valueChanges.subscribe((result)=>{
      if(result != ''){
        this.filtroFacade.cambiarTipoLetra(result);
      }
    });
    this.font.valueChanges.subscribe((result)=>{
      if(result != 0){
        this.filtroFacade.cambiarTamañoLetra(result);
      }
    })
  }

  abrir(){
    this.dropDown.nativeElement.classList.toggle("visibility");
  }



}
