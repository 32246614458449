import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { DataApi } from 'src/app/Model/dataApi';
import { ConfiguracionReportes, ReporteFinalInterface, ReportesModulo } from 'src/app/Model/Reportes/ReportesInterface';
import { DataApiService } from 'src/app/services/data-api.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { environment } from 'src/environments/environment';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { PrintService } from 'src/app/services/utils/print.service';
@Injectable({
  providedIn: 'root'
})
export class FiltroFacadeService {

  constructor(private _mensajesHttp: MensajesHttpService, private _toast: ToastrServiceLocal, public _dataApi: DataApiService, public printService: PrintService) { }

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private ReportesModulo$ = new BehaviorSubject<ReportesModulo[]>([]);
  public responseReportesModulo$: Observable<ReportesModulo[]> = this.ReportesModulo$.asObservable();

  private ConfiguracionReporte$ = new BehaviorSubject<ConfiguracionReportes[]>([]);
  public responseConfiguracionReporte$: Observable<ConfiguracionReportes[]> = this.ConfiguracionReporte$.asObservable();

  public Reporte$ = new BehaviorSubject<ReporteFinalInterface>({});
  public responseReporte$: Observable<ReporteFinalInterface> = this.Reporte$.asObservable();

  private ReporteFinal$ = new BehaviorSubject<boolean>(false);
  public responseReporteFinal$: Observable<boolean> = this.ReporteFinal$.asObservable();

  public orientacionPagina = 2;

  private CargandoDescarga$ = new BehaviorSubject<boolean>(false);
  public responseCargandoDescarga$: Observable<boolean> = this.ReporteFinal$.asObservable();

  public estiloContenido = {
    align: 'text-center',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '12px'
  };

  resetearReporteFinal() {
    this.ReporteFinal$.next(false);
  }

  mostrarReportes(params?: any) {
    let idModulo = JSON.parse(localStorage.getItem("idModulo"));
    this.ReportesModulo$.next([]);
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/report/${idModulo}`, params).pipe(
      tap((result: DataApi | any) => {
        if (result.hasError === false) {
          this.ReportesModulo$.next(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los reportes', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  mostrarConfiguracionReportes(params?: any) {
    let idModulo = JSON.parse(localStorage.getItem("idModulo"));

    this.ConfiguracionReporte$.next([]);
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/report/filterconfig/${params}/${idModulo}`, '').pipe(
      tap((result: DataApi | any) => {
        if (result.hasError === false) {
          this.ConfiguracionReporte$.next(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los reportes', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  generarReporte(params?: any) {
    this.ReporteFinal$.next(false);
    this.Reporte$.next({});
    let idModulo = JSON.parse(localStorage.getItem("idModulo"));
    params.idModulo = idModulo;
    this.Cargando$.next(true);
    const request$ = this._dataApi.PostDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/report/`, params).pipe(
      tap((result: DataApi | any) => {
        this.Reporte$.next(result.data.table0[0]);
        this.ReporteFinal$.next(true);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al generar el reporte', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  mostrarCargando() {
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
  }
  cerrarCargando() {
    this._toast.clearToasts();

  }
  public cargandoVoucher: boolean = false;

  mostrasVoucher(params: any, callback) {
    this.cargandoVoucher = true;
    let url = `${environment.site}/api/utils/${environment.processEnv}/v1/repo/go504hnrepo/${params}/60`;
    const request$ = this._dataApi.GetDataApi(url, '').pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargandoVoucher = false;
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
            this.cargandoVoucher = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar las clinicas', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  cambiarOrientacion() {
    if (this.orientacionPagina === 1) {
      this.orientacionPagina = 2;
      return;
    }
    if (this.orientacionPagina === 2) {
      this.orientacionPagina = 1;
      return;
    }
  }

  imprimirContrato() {
    var div = (this.orientacionPagina === 1) ? document.getElementById("ReporteFinalVertical") : document.getElementById("ReporteFinalHorizontal");

    var estilo1 = `
      body {
      font-family: "Inter", sans-serif;
      color: black;
      font-size: 1.2em;
      background-color: mix(#fff, #222222, 90%);
      }
      p{
          margin-bottom: 0 !important;
      }
      .thp {
        color: black;
        background: #333a56;
        font-weight: 700;
      }
      .contenido{
          margin: 20px !important;
      }
      .tr{
        font-size: 1.4em;
        color: black;
      }
      .td{
        color: black !important;
      }
      .trp{
        color: black;
      }
      
    `;

    this.printService.imprimirElemento(div, [{ estilo: estilo1 }]);

  }

  downloadPDF() {
    this.CargandoDescarga$.next(true);
    this.mostrarCargando();

    let DATA = (this.orientacionPagina === 1) ? document.getElementById("ReporteFinalVertical") : document.getElementById("ReporteFinalHorizontal");
    let orientation = (this.orientacionPagina === 1) ? 'p' : 'l';
    const doc = new jspdf('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };

    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      const bufferX = 10;
      const bufferY = 10;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      return doc;
    }).then((docResult) => {
      this.CargandoDescarga$.next(false);
      this.cerrarCargando();
      docResult.save(`${new Date().toISOString()}.pdf`);
    });

  }

  cambiarAlign(alineacion) {
    this.estiloContenido.align = alineacion;
  }

  cambiarTipoLetra(letra) {
    this.estiloContenido.fontFamily = letra;
  }

  cambiarTamañoLetra(tamaño) {
    this.estiloContenido.fontSize = `${tamaño}px`;
  }

  resetearReportes() {
    this.ReporteFinal$.next(false);
    this.ConfiguracionReporte$.next([]);
  }

  recorrerObjetos() {

    let columna = this.Reporte$.getValue().tables[0].th;
    let items = this.Reporte$.getValue().tables[0].data;
    let name = this.Reporte$.getValue().tables[0].tableTitle;

    let objetoDatos = [];

    for (const i in columna){
      let obj = {columna: columna[i], value: columna[i]};
      objetoDatos.push(obj);
    }

    let columnas: string[] = columna;
    let body: any[] = [];

  
    //Recorrer la data y crear un arreglo de objetos apartir de ella usando como key las columnas antes especificadas
    for (let i = 0; i < items.length; i++) {
      let bodyObject = {}

      objetoDatos.forEach((res) => {
        if (String(items[i][res.value]) === 'null' || String(items[i][res.value]) === 'undefined') {
          bodyObject[res.columna.replace(/ /g, "")] = '-';
        } else {

          bodyObject[res.columna.replace(/ /g, "")] = String(items[i][res.value]);
        }
      });

      body.push(bodyObject);
    }
    let date = new Date();

    let nombreArchivo = String(`${name}-0${date.getDate()}-0${date.getMonth() + 1}-${date.getFullYear()}-${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`);

    this.exportarExcel({
      nameFile: `${nombreArchivo}.xlsx`,
      Worksheet: "info",
      bk: "go504hnrepo",
      titulo: "",
      empresa: environment.nombreEmpresa,
      columns: columnas,
      body: body
    });

  }

  
  exportarExcel(params?) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    console.log(params)
    const request$ = this._dataApi.PostDataApiUtils(`${environment.site}/api/utils/${environment.processEnv}/v1/exportExcel`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        this.Cargando$.next(false);
        if (result.hasError === false) {
          this._toast.clearToasts();
          this.mostrarArchivo(result.data[0].table0.key);
          this._toast.mensajeSuccess('Se exporto con exito el archivo de excel', '');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al generar el archivo excel', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  mostrarArchivo(params: any) {
    this.Cargando$.next(true);
    let url = `${environment.site}/api/utils/${environment.processEnv}/v1/repo/go504hnrepo/${params}/600000`;
    const request$ = this._dataApi.GetDataApiUtils(url, '').pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          this.manageExcelFile(result.data[0].table0[0].url);
          this.Cargando$.next(false);

        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
            this.Cargando$.next(false);

          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar el archivo', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  manageExcelFile(url) {
    window.open(url);
  }
}
