import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoDosGuard implements CanActivate {
  PIN:any 
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.PIN=localStorage.getItem('typeTwo');
    if (this.PIN=='F676E28C-067F-4085-8204-9463062D8581') {
      return true
    } else {
      return false
    }
  }
  
}
