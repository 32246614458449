<br>


<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-accordion class="example-headers-align" >
            <mat-expansion-panel style="background-color: rgb(123, 161, 202);" [expanded]="step ==0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header  style="padding-left: 30%;">
                    <mat-panel-title >
                       <span> Documentos </span>
                        <mat-icon class="text-left">insert_drive_file</mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mt-2 text-right" style="padding-right: 2%;">
                <button mat-raised-button (click)="nueva()" class="buttonPrincipal">Nueva</button>
                </div>
            <br>
                <div class="table-responsive" id="table-responsive">
                    <table class="table  bordeTabla tablep" role="table ">
                        <thead class="theadp">
                            <tr class="trp bg-success text-center ">
                                <!-- <th class="thp " scope="col " role="columnheader ">N#</th> -->
                                <th class="thp " scope="col " role="columnheader ">Asunto</th>
                                <th class="thp " scope="col " role="columnheader ">Opciones</th>   
                            </tr>
                        </thead>
                        <tbody role="rowgroup " class="tbodyp" >
                            <tr class="text-center trp " role="row" *ngFor="let item of (plantillas)">
                                <!-- <td role="cell " data-title="N" class="tdp">{{i+1}}</td>  -->
                                <td role="cell " data-title="asunto " class="tdp">{{item.asunto}}</td>
                                <td  role="cell " class="tdp">
                                    <button mat-mini-fab class="btn-Error"  matTooltipPosition="left" (click)="openPlantilla(item)">
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button mat-mini-fab style="background-color: red;"  matTooltipPosition="left" (click)="deletePlantilla(item)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                  </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                
            
            </mat-expansion-panel>
    
        </mat-accordion>
    </div>
    <div  class="col-lg-4">
        <section (click)="cambiarEditor()" class="example-section">
            <mat-slide-toggle
                class="example-margin"
                [color]="color">
              Cambiar editor
            </mat-slide-toggle>
          </section>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <form [formGroup]="formPlantilla" autocomplete="off" (ngSubmit)="guardarPlantilla()" >

            <div class="mt-2 col-md-12">
                <mat-form-field hintLabel="Max 500 Caracteres" appearance="legacy">
                    <mat-label>Asunto</mat-label>
                    <input matInput #text maxlength="500" placeholder="Asunto" formControlName="asunto">
                    <mat-hint align="end">{{text.value?.length || 0}}/500</mat-hint>
                </mat-form-field>
            </div>
            
            <p-editor *ngIf="!mostrarEditor" formControlName="detallehtml" [style]="{'height':'320px'}"></p-editor>
            <email-editor *ngIf="mostrarEditor"  name="detallehtml"
                                  [options]="options"
                                  (loaded)="editorLoaded ? editorLoaded($event) : null"
                                  #editor
                                  ></email-editor>
            <div class="mt-2 col-md-12">
                <mat-form-field hintLabel="Max 500 Caracteres" appearance="legacy">
                    <mat-label>Descripción mensaje a enviar ejemplo.("Estimado Usuario: Carlos Nieto, su factura No. 23443 fue enviada")</mat-label>
                    <input matInput #text1 maxlength="500" placeholder="sms" formControlName="sms">
                    <mat-hint align="end">{{text1.value?.length || 0}}/500</mat-hint>
                </mat-form-field>
            </div>
            <div class="mt-2 col-md-12">
                <mat-form-field appearance="legacy">
                    <mat-label> Campos a obtener los numeros de teléfono a enviar en la plantilla o archivo CSV</mat-label>
                    <input matInput #text1 maxlength="500" placeholder="Teléfono" formControlName="telefono">
                </mat-form-field>
            </div>
        
            <div  class="col-lg-4">
                <section class="example-section">
                    <mat-slide-toggle
                        formControlName="descargarArchivo"
                        class="example-margin"
                        [color]="color">
                      Descargar archivo
                    </mat-slide-toggle>
                  </section>
            </div>
            <div class="mt-2 text-right">
                <button mat-raised-button class="buttonPrincipal">{{flagAccion==1&&'Guardar'||'Actualizar'}}</button>
            </div>
        </form>

    </div>
</div>
