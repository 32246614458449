<mat-toolbar>
    <span class="titleMosaic">Sistema Notificaciones</span>
    <span class="example-spacer"></span>
    <span fxFlex></span>

    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon style="color: blue;">menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)='goMosaic()'>
            <mat-icon>supervised_user_circle</mat-icon>
            <span>Notificaciones</span>
          </button>
        <button mat-menu-item (click)="salir()">
            <mat-icon>exit_to_app</mat-icon>
          <span>Cerrar sesión</span>

        </button>
      </mat-menu>
      
</mat-toolbar>

<div class="row">
    <div class="col-lg-3" style="padding-top: 5%;">
        <mat-grid-list cols="1" class="myGridList" rowHeight="fit" style="height: 50vh !important" [gutterSize]="'15px'">
            <mat-grid-tile [ngStyle]="{'background-image':'url(' + fotoEmpresa + ')' , 'color': 'white', 'font-weight': 'bold'}" (click)='goEmpresas()' class="configImage">
                <div class="textGrid">
                    <span>Empresas</span>
                </div>
                <div class="overlay">
                    <span>
                        Gestionar empresas
                    </span>
                </div>

            </mat-grid-tile>

        </mat-grid-list>

    </div>
    <div class="col-lg-3" style="padding-top: 5%;">
        <mat-grid-list cols="1" class="myGridList" rowHeight="fit" style="height: 50vh !important" [gutterSize]="'15px'">
            <mat-grid-tile [ngStyle]="{'background-image':'url(' + fotoUsers + ')' , 'color': 'white', 'font-weight': 'bold'}" (click)='goUsers()' class="configImage">
                <div class="textGrid">
                    <span>Usuarios</span>
                </div>
                <div class="overlay">
                    <span>
                        Gestionar usuarios
                    </span>
                </div>

            </mat-grid-tile>

        </mat-grid-list>

    </div>

    <div class="col-lg-3" style="padding-top: 5%;">
        <mat-grid-list cols="1" class="myGridList" rowHeight="fit" style="height: 50vh !important" [gutterSize]="'15px'">
            <mat-grid-tile [ngStyle]="{'background-image':'url(' + fotoMenu + ')' , 'color': 'white', 'font-weight': 'bold'}" (click)='goMenus()' class="configImage">
                <div class="textGrid">
                    <span>Menus</span>
                </div>
                <div class="overlay">
                    <span>
                        Gestionar menus
                    </span>
                </div>

            </mat-grid-tile>

        </mat-grid-list>

    </div>
</div>
