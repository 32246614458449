<div class="contenedorPadre">
    <mat-card class="container">
        <mat-card-content>
            <div class="forms-container">
                <div class="closeAlert">
                    <button mat-mini-fab class="buttonPrincipal" (click)="navegar()">
                        <mat-icon>exit_to_app</mat-icon>
                    </button>
                </div>
                <div class="img-signup">
                    <img src="../../../../assets/images/undraw_security_o890.svg" class="img-fluid">
                </div>
                <div class="signin-signup">
                    <form [formGroup]="pinForm" class="sign-in-form">
                        <h2 class="title">Codigo PIN</h2>
                        <div class="row">
                            <mat-form-field appearance="legacy" class="col-md-12">
                                <mat-label>Ingresar Codigo Pin</mat-label>
                                <input matInput placeholder="Ingresar Codigo Pin" formControlName="pin">
                            </mat-form-field>

                            <mat-form-field appearance="legacy" class="col-md-12">
                                <mat-label>Ingresar Codigo Pin</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Ingresar Codigo Pin" formControlName="pin"
                                    autocomplete="false" required>
                                <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
    
 
                            <mat-form-field appearance="legacy" class="col-md-12">
                                <mat-label>Repetir Codigo Pin</mat-label>
                                <input matInput [type]="hide1 ? 'password' : 'text'" placeholder="Repetir Codigo Pin" formControlName="confirmarPin"
                                    autocomplete="false" required>
                                <button type="button" mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide1 password'"
                                    [attr.aria-pressed]="hide1">
                                    <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
    
                        <div class="text-right">
                            <button mat-raised-button class="buttonPrincipal margenButton" *ngIf="idTipoAccion === 1 "
                                (click)="cambioPassWordPin()"> Guardar</button>
                        </div>
    
    
    
                    </form>
                </div>
            </div>
    
        </mat-card-content>
    </mat-card>
</div>
