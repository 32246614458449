<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/adminMenu']">Inicio</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/menus']">Menus</a></li>
        <li class="breadcrumb-item active" aria-current="page">Nuevo Menu</li>
    </ol>
</nav>
<mat-card class="matCardPersonalizada">
    <mat-card-header>
        <mat-card-title tourAnchor="titleCard">Nuevo Menu</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="menus" (ngSubmit)="nuevoMenu()">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field hintLabel="Max 100 Caracteres" appearance="legacy">
                        <mat-label>Nombre Menu</mat-label>
                        <input matInput #texto maxlength="100" placeholder="Nombre Menu" formControlName="texto">
                        <mat-hint align="end">{{texto.value?.length || 0}}/100</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field hintLabel="Max 100 Caracteres" appearance="legacy">
                        <mat-label>Descripción</mat-label>
                        <input matInput #descripcion maxlength="100" placeholder="Descripción" formControlName="descripcion">
                        <mat-hint align="end">{{descripcion.value?.length || 0}}/100</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="legacy">
                        <mat-label>Icono</mat-label>
                        <input #icono matInput placeholder="Placeholder" formControlName="icono">
                        <span matSuffix><i class="{{icono?.value}}"></i></span>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field hintLabel="Max 200 Caracteres" appearance="legacy">
                        <mat-label>Url</mat-label>
                        <input #url matInput maxlength="200" placeholder="Placeholder" formControlName="url">
                        <mat-hint align="end">{{url.value?.length || 0}}/200</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="legacy">
                        <mat-label>Orden</mat-label>
                        <input  matInput #orden placeholder="Orden" formControlName="orden" type="number" min="0">
                    </mat-form-field>
                </div>
                <div class="col-md-6" *ngIf="idMenuPadre == 0">
                    <mat-form-field appearance="legacy">
                        <mat-label>Modulo</mat-label>
                        <mat-select formControlName="idModulo">
                            <mat-option *ngFor="let item of (modulosMenuFacade.responseModules$ | async)" [value]="item.idModule">
                                {{item.Module}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
            </div>
            <div class="col-md-12">
                <section class="example-section">
                    <b>Visible</b>
                    <mat-radio-group formControlName="visible">
                        <mat-radio-button class="example-margin" value="1">Sí</mat-radio-button>
                        <mat-radio-button class="example-margin" value="2">No</mat-radio-button>
                    </mat-radio-group>
                </section>
                <section class="example-section">
                    <b>Activo</b>
                    <mat-radio-group formControlName="activo">
                        <mat-radio-button class="example-margin" value="1">Sí</mat-radio-button>
                        <mat-radio-button class="example-margin" value="2">No</mat-radio-button>
                    </mat-radio-group>
                </section>
            </div>


            <div class="text-right">
                <button class="buttonPrincipal" mat-raised-button type="submit" [disabled]="menus.invalid || (menusFacade.responseCargando$ | async)">Guardar</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>