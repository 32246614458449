import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from 'src/app/Model/dataApi';
import { DataApiService } from 'src/app/services/data-api.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardPackageService {

  constructor(private servi: NotificacionesService,  public _toast: ToastrServiceLocal,
    public _dataApi: DataApiService,
    public _mensajesHttp: MensajesHttpService) { }

  private graficas$ = new BehaviorSubject<any>([]);
  public responsegrafica$: Observable<any> = this.graficas$.asObservable();

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private Dashboard$ = new BehaviorSubject<any>([]);
  public responseDashboard$: Observable<any> = this.Dashboard$.asObservable();

  mostrargraficas() {

    const request$ = this.servi.GetDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/dashboard`).pipe(

      tap((result: any) => {
        this.graficas$.next(result?.data?.table0);
      }));

    return request$.subscribe();

  }

  mostrarDashboard(params?: any) {
    this.Dashboard$.next([]);
    this.Cargando$.next(true);
    console.log(params)
    const request$ = this._dataApi.PostDataApi(`${environment.site}/api/notification/${environment.processEnv}/v1/dashboard `, params).pipe(
      tap((result: DataApi | any) => {
        if (result.hasError === false) {
          console.log(result)
          this.Dashboard$.next(result.data.table0[0]);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar el dashboard', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
}
