import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MenusFacadeService } from '../../menus/menus-facade.service';
import { perfilInterface } from '../../../../Model/Seguridad/perfil.Interface';
import { PerfilesFacadeService } from '../../perfiles/perfiles-facade.service';
import { UsuariosFacadeService } from '../usuarios-facade.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-perfiles',
  templateUrl: './modal-perfiles.component.html',
  styleUrls: ['./modal-perfiles.component.css']
})
export class ModalPerfilesComponent implements OnInit, OnDestroy {
  public suscripciones :Subscription = new Subscription();
  public usuarioPerfil: FormGroup;
  public data:perfilInterface;
  constructor(private menusFacade:MenusFacadeService, public perfilFacade: PerfilesFacadeService,
    private usuariosFacade: UsuariosFacadeService, public dialog: MatDialog) {

    this.data = this.usuariosFacade.consumirUsuario();
    this.perfilFacade.MostrarComboboxPerfiles('');

    this.usuarioPerfil = new FormGroup({
        idUsuario: new FormControl(this.data.IdUsuario),
        idPerfil: new FormControl('', [Validators.required]),
        estado: new FormControl(true),
        observaciones: new FormControl('')
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void{
    this.suscripciones.unsubscribe();
  }

  guardarData(){
    // console.log(this.usuarioPerfil.value);
    this.usuariosFacade.UsuarioPerfilIngresar(this.usuarioPerfil.value);

    this.suscripciones.add(this.usuariosFacade.responseAction$.subscribe((result)=>{
      if (result.hasError === false) {
        this.perfilFacade.UsuariosPerfilesMostrar(`${this.data.IdUsuario}/activo/true`);
        this.dialog.closeAll();
      }
    }));
  }

}
