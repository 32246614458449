import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from 'src/app/Model/dataApi';
import { mosaicInterface } from 'src/app/Model/Seguridad/mosaic.interface';
import { SeguridadService } from 'src/app/services/seguridad/seguridad.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModulosFacadeService {

  constructor(private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal, private _dataApi: SeguridadService) { }

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private Action$ = new BehaviorSubject<any>({});
  public responseAction$: Observable<any> = this.Action$.asObservable();

  private Modules$ = new BehaviorSubject<mosaicInterface[]>([]);
  public responseModules$: Observable<mosaicInterface[]> = this.Modules$.asObservable();

  //Mostrar todos las requisiciones 
  mostrarModulo(params?: string) {
    this.Modules$.next([]);
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/module/`, params).pipe(
      tap((result: DataApi | any) => {
        // console.log(result);
        if (result.hasError === false) {
          this.Modules$.next(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar las modulos', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  NuevoModulo(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.PutDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/module`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        console.log(result);
        this.Action$.next(result);
        this._toast.mensajeSuccess('Se Inserto con exito el modulo', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al insertar el modulo', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  actualizarModulo(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PostDataApi(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/module`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        this.Action$.next(result);
        this._toast.mensajeSuccess('Se actualizo con exito el modulo', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al actualizar el modulo', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

  eliminarModulo(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.DeleteDataApiBody(`/${environment.site}/api/notification/${environment.processEnv}/v1/admin/module`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        this._toast.mensajeSuccess('Se elimino con exito el modulo', '');
        this.Cargando$.next(false);
        this.Action$.next(result);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();

        this.Action$.next({});
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al eliminar el modulo', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }
}
