<div class="matCardHeader">
    Asignar Perfiles
</div>
<mat-dialog-content class="mat-typography">
    <div>
        <form [formGroup]="usuarioPerfil" >
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Perfiles</mat-label>
                        <mat-select formControlName="idPerfil">
                            <mat-option *ngFor="let item of (perfilFacade.responsePerfilesSelect$ | async)" [value]="item.IdPerfil">{{item.Nombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observaciones</mat-label>
                        <input matInput placeholder="Observaciones" formControlName="observaciones">
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <section class="example-section">
                        <mat-checkbox class="example-margin" formControlName="estado">Activo</mat-checkbox>
                    </section>
                </div>
            </div>
            

        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Salir</button>
    <button mat-raised-button class="buttonPrincipal" type="submit" [disabled]="usuarioPerfil.invalid" (click)="guardarData()">Guardar</button>

</mat-dialog-actions>
