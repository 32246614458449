import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { mosaicInterface } from 'src/app/Model/Seguridad/mosaic.interface';
import { perfilInterface } from 'src/app/Model/Seguridad/perfil.Interface';
import { EmpresaServicesService } from 'src/app/services/empresas/empresa-services.service';
import { ModulosFacadeService } from '../../modulos/modulos-facade.service';
import { PerfilesFacadeService } from '../../perfiles/perfiles-facade.service';
import { UsuariosFacadeService } from '../usuarios-facade.service';

@Component({
  selector: 'app-nuevo-usuario-modal',
  templateUrl: './nuevo-usuario-modal.component.html',
  styleUrls: ['./nuevo-usuario-modal.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class NuevoUsuarioModalComponent implements OnInit, OnDestroy {

  public formUser: FormGroup;
  public generalForm: FormGroup;
  public optionForm: FormGroup;
  public modulosArray = new FormArray([]);
  public profilesArray = new FormArray([]);

  public filter = new FormControl('');
  public filterProfile = new FormControl('');


  public isLinear = true;
  public subscripciones: Subscription = new Subscription();

  hide = true;
  idBusiness:any=(JSON.parse(localStorage.getItem("data_go504")));

  constructor(public usuarioFacade: UsuariosFacadeService, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, public perfilesFacade:PerfilesFacadeService, public modulosFacade:ModulosFacadeService,public srvEmpresa:EmpresaServicesService ) {
    this.perfilesFacade.MostrarComboboxPerfiles('');
    this.modulosFacade.mostrarModulo('');

    this.formUser = new FormGroup({
      idUsuario: new FormControl((data.IdUsuario)? data.IdUsuario : null),
      user: new FormControl((data.Usuario) ? { value: data.Usuario, disabled: true } : '', [Validators.required]),
      codEmpleado: new FormControl((data.CodEmpleado) ? { value: data.CodEmpleado, disabled: true } : ''),
      observaciones: new FormControl((data.Observaciones) ? data.Observaciones : ''),
      clave: new FormControl(''),
      idEmpresa: new FormControl((data.idEmpresa)? data.idEmpresa: this.idBusiness.idEmpresa),
      idTipo: new FormControl(data.idTipo!),
      idCliente: new FormControl(data.idCliente!),
    });

    this.generalForm = new FormGroup({
      nombre: new FormControl((data.Nombre) ? { value: data.Nombre, disabled: true} : '', [Validators.required]),
      correo: new FormControl((data.correoElectronico) ? { value: data.correoElectronico, disabled: true}  : '', [Validators.email, Validators.required]),
    });

    this.optionForm = new FormGroup({
      activo: new FormControl((data.Activo) ?  data.Activo  : false, [Validators.required]),
      envioCorreoEmail: new FormControl((data.envioCorreoEmail) ? { value: data.envioCorreoEmail, disabled: true}  : false),
    });

    this.subscripciones.add(
      this.modulosFacade.responseModules$.subscribe((modulos)=>{
        this.setearModulos(modulos);
      })
    );

    this.subscripciones.add(
      this.perfilesFacade.responsePerfilesSelect$.subscribe((perfiles)=>{
        this.setearPerfiles(perfiles);
      })
    );

    this.formUser.value.idUsuario== null ? this.block=false: this.block=true
    this.isLinear = (this.data.IdUsuario) ? false : true;

    // console.log(this.formUser.value,'DATA');
    
    
  }
  listEmpresas:any=[]
  getEmpresas(){
    this.srvEmpresa.mostrarEmpresas();
    this.subscripciones.add(
      this.srvEmpresa.repoEmpresa$.subscribe((result)=>{
        this.listEmpresas=result
      //  console.log(result);
      })
    );

    
  }
block:boolean
  ngOnInit(): void {
    this.getEmpresas();
  }

  ngOnDestroy(): void {
    this.subscripciones.unsubscribe();
  }

  guardarUsuario() {
    // console.log(this.formUser);
    // console.log(this.optionForm);
    // console.log(this.generalForm);
    
    if (this.formUser.invalid || this.optionForm.invalid || this.generalForm.invalid) {
      this.formUser.markAllAsTouched();
      this.optionForm.markAllAsTouched();
      this.generalForm.markAllAsTouched();

      return;
    }

    let params:any = {modulos: [], perfiles: []};
    let modulos:any[] = [];
    let perfiles:any[] = [];


    Object.assign(params, this.formUser.value);
    Object.assign(params, this.generalForm.value);
    Object.assign(params, this.optionForm.value);

    this.modulosArray.controls.forEach((m)=>{
      if(m.value.asignacion === true){
        modulos.push(m.value.idModule);
      }
    });

    this.profilesArray.controls.forEach((p)=>{
      if(p.value.asignacion === true){
        perfiles.push(p.value.idPerfil);
      }
    });

    params.modulos = modulos;
    params.perfiles = perfiles;
    
    if (this.data.IdUsuario) {
      // console.log('UPDATE',params);
      this.usuarioFacade.ActualizarUsuario(params);
    } else {
      // console.log(this.formUser.value);
      if (this.formUser.value.clave === '') {
        this.generarPassRandom();
      }
      // console.log('INSERT',params);

      this.usuarioFacade.NuevoUsuario(params);
    }
    this.subscripciones.add(this.usuarioFacade.responseAction$.subscribe((result) => {
      if (result.hasError == false) {

        if (this.data.idCliente != null) {
          this.usuarioFacade.MostrarUsuariosByCliente(this.data.idCliente);
          
        } else {

          this.usuarioFacade.MostrarUsuarios('');
          
        }
        this.dialog.closeAll();
      }
    }));

  }

  generarPassRandom() {
    var randomstring = Math.random().toString(36).slice(-12);
    this.formUser.get('clave').setValue(randomstring);
  }

  setearModulos(modulesArray: mosaicInterface[]) {
    modulesArray.forEach((p) => {
      let modulos = new FormGroup({
        id: new FormControl(0),
        idModule: new FormControl(p.idModule),
        descripcion: new FormControl(p.Module),
        asignacion: new FormControl(false),
      });

      this.modulosArray.push(modulos);
    });

    if(this.data.modulo != null){
      this.cambiarEstadoPermisos(JSON.parse(this.data.modulo));
    }

  }

  cambiarEstadoPermisos(modulos: mosaicInterface[]) {
    modulos.forEach((u) => {
      this.modulosArray.controls.forEach((p) => {
        if (u.idModule === p.value.idModule) {
          p.get('asignacion').setValue(true);
        }
      });
    });
  }

  setearPerfiles(perfilesArray: perfilInterface[]) {
    perfilesArray.forEach((p) => {
      let perfiles = new FormGroup({
        id: new FormControl(0),
        idPerfil: new FormControl(p.IdPerfil),
        descripcion: new FormControl(p.Nombre),
        asignacion: new FormControl(false),
      });

      this.profilesArray.push(perfiles);
    });
    if(this.data.perfiles != null){
      this.cambiarEstadoPefiles(JSON.parse(this.data.perfiles));
    }

  }

  cambiarEstadoPefiles(perfiles: perfilInterface[]) {
    perfiles.forEach((u) => {
      this.profilesArray.controls.forEach((p) => {
        if (u.idPerfil === p.value.idPerfil) {
          p.get('asignacion').setValue(true);
        }
      });
    });
  }


}
