<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/adminMenu']">Inicio</a></li>
        <li class="breadcrumb-item active" aria-current="page">Empresas</li>
    </ol>
</nav>


<div class="container-fluid" style="zoom: 100%;">
    <div class="row">
        <div class="col-lg-12">
            <mat-card>
                <div class="col-lg-12">
                    <br>
                    <form [formGroup]="filtro">

                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="legacy">
                                    <mat-label>Buscar</mat-label>
                                    <input matInput placeholder="Buscar" formControlName="filterEmpresa">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <div class="text-right">
                                    <button mat-raised-button class="buttonPrincipal" (click)="openAgregarEmpresa(modalAgregarEmpresa)">Agregar empresa</button>
                                </div>
                            </div>

                        </div>

                    </form>
                    <div>
                        <table class="table  bordeTabla tablep" role="table ">
                            <thead class="theadp">

                                <tr class="trp bg-success text-center ">

                                    <th class="thp " scope="col " role="columnheader "></th>
                                    <th class="thp " scope="col " role="columnheader ">Nombre de la empresa</th>
                                    <th class="thp " scope="col " role="columnheader ">RTN</th>
                                    <th class="thp " scope="col " role="columnheader ">Correo</th>
                                    <th class="thp " scope="col " role="columnheader ">Direccion</th>
                                    <th class="thp " scope="col " role="columnheader ">Telefono</th>
                                    <th class="thp " scope="col " role="columnheader ">Celular</th>
                                    <th class="thp " scope="col " role="columnheader ">Precio correo</th>
                                    <th class="thp " scope="col " role="columnheader ">Precio SMS</th>
                                </tr>
                            </thead>


                            <tbody role="rowgroup " class="tbodyp">

                                <tr class="text-center trp " role="row " *ngFor="let item of (listEmpresas)|search: this.filtro?.value.filterEmpresa: ['nombreEmpresa']| slice:desde:hasta">
                                    <td role="cell " data-title="" class="tdp">
                                    <div class="text-center">
                                        <button class="botonEditar"(click)='openEditar(modalAgregarEmpresa,item)' mat-mini-fab #tooltip="matTooltip" matTooltip="Editar empresa" matTooltipPosition="right">
                                                    <mat-icon>create</mat-icon>
                                        </button>
                                    </div>
                                </td>
                                    <td role="cell " data-title="id" class="tdp">{{item.nombreEmpresa}}</td>
                                    <td role="cell " data-title="Identidad" class="tdp">{{item.rtn}}</td>
                                    <td role="cell " data-title="Nombre" class="tdp">{{item.email}}</td>
                                    <td role="cell " data-title="AREA" class="tdp "> {{item.direccion}}</td>
                                    <td role="cell " data-title="Sueldo" class="tdp">{{item.telefono}}</td>
                                    <!-- <td role="cell " data-title="Nombre" class="tdp"><input style="text-align: center;" (blur)="onBlurEvent($event,item)" type="number" [(ngModel)]="item.sueldo" /></td> -->
                                    <td role="cell " data-title="Deducciones" class="tdp">{{item.celular}}</td>
                                    <td role="cell " data-title="Sueldo neto" class="tdp">{{item.precioxEmail|currency:'L '}}</td>
                                    <td role="cell " data-title="Sueldo neto" class="tdp">{{item.precioxMsj|currency:'L '}}</td>
                                    <!-- <td role="cell " data-title="AÑO" class="tdp "> {{item.anio}} </td>
                                <td role="cell " data-title="EMPLEADOS" class="tdp ">{{item.empleados}} </td>
                                <td role="cell " data-title="REGISTROS" class="tdp ">{{item.registros}}</td>
                                <td role="cell " data-title="NOMINAL" class="tdp ">{{item.nominal| currency:'L '}}</td>
                                <td role="cell " data-title="DEDUCCIONES" class="tdp ">{{item.deducciones|currency:'L'}}</td>
                                <td role="cell " data-title="NETO" class="tdp ">{{item.neto|currency:'L'}}</td> -->





                                </tr>
                            </tbody>
                        </table>

                        <mat-paginator tourAnchor=" pagination " [length]="listEmpresas.length " [pageSize]="pageSize " (page)="next($event) ">
                        </mat-paginator>
                    </div>
                </div>

            </mat-card>
        </div>
    </div>
</div>

<ng-template #modalAgregarEmpresa>
    <mat-card-header style="background-color: #333a56; border-radius: 10px; color: white;">
        <!-- <div class="mat-card-header-text"> -->
        <div class="mat-dialog-title;" style="padding-left: 35%; padding-right: 35%; padding-top: 1%;padding-bottom: 1%; font-size: medium;">{{flagAccion==1&&'Nueva'||'Editar'}} Empresa </div>
        <!-- </div> -->
    </mat-card-header>
    <form [formGroup]="empresaFrom" (ngSubmit)="guardar()">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-list-item>
                    <mat-form-field appearance="standard">
                        <mat-label>Nombre empresa</mat-label>
                        <input formControlName="nombreEmpresa" placeholder="NOMBRE" type="text" matInput>
                        <mat-hint></mat-hint>
                    </mat-form-field>
                </mat-list-item>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-list-item>
                    <mat-form-field appearance="standard">
                        <mat-label>RTN</mat-label>
                        <input formControlName="rtn"  placeholder="rtn" matInput>
                        <mat-hint></mat-hint>
                    </mat-form-field>
                </mat-list-item>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <mat-list-item>
                    <mat-form-field appearance="standard">
                        <mat-label>Correo</mat-label>
                        <input formControlName="email"  placeholder="email" matInput>
                        <mat-hint></mat-hint>
                    </mat-form-field>
                </mat-list-item>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <mat-list-item>
                    <mat-form-field appearance="standard">
                        <mat-label>Correo contacto</mat-label>
                        <input formControlName="emailContacto"  placeholder="email Contacto" matInput>
                        <mat-hint></mat-hint>
                    </mat-form-field>
                </mat-list-item>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <mat-list-item>
                    <mat-form-field appearance="standard">
                        <mat-label>Telefono</mat-label>
                        <input (keydown)="validarNumeros($event)" formControlName="telefono"  placeholder="telefono" matInput>
                        <mat-hint></mat-hint>
                    </mat-form-field>
                </mat-list-item>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <mat-list-item>
                    <mat-form-field appearance="standard">
                        <mat-label>Celular</mat-label>
                        <input (keydown)="validarNumeros($event)"formControlName="celular"  placeholder="celular" matInput>
                        <mat-hint></mat-hint>
                    </mat-form-field>
                </mat-list-item>
            </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <mat-list-item>
                        <mat-form-field appearance="standard">
                            <mat-label>Nombre</mat-label>
                            <input formControlName="nombre"  placeholder="nombre" matInput>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </mat-list-item>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <mat-list-item>
                        <mat-form-field appearance="standard">
                            <mat-label>Apellido</mat-label>
                            <input formControlName="apellido"  placeholder="apellido" matInput>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </mat-list-item>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <mat-list-item>
                        <mat-form-field appearance="standard">
                            <mat-label>Alias</mat-label>
                            <input formControlName="alias"  placeholder="alias" matInput>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </mat-list-item>
                </div>
            </div>
            <div class="row">   
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <mat-list-item>
                        <mat-form-field appearance="standard">
                            <mat-label>Precio SMS</mat-label>
                            <input (keydown)="validarNumeros($event)" formControlName="precioMsj"  placeholder="precio sms" matInput>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </mat-list-item>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <mat-list-item>
                        <mat-form-field appearance="standard">
                            <mat-label>Precio correo</mat-label>
                            <input (keydown)="validarNumeros($event)" formControlName="precioEmail"  placeholder="precio email" matInput>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </mat-list-item>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-list-item>
                        <mat-form-field appearance="standard">
                            <mat-label>Dirección</mat-label>
                            <input formControlName="direccion"  placeholder="direccion" matInput>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </mat-list-item>
                </div>
            </div>
        <div class="text-right">
            <button mat-button mat-dialog-close>Salir</button>
            <button mat-raised-button type="submit" class="buttonPrincipal"
                [ngClass]="{ buttonPrincipal: flagAccion==1, botonEditar: flagAccion==2}">
                {{flagAccion==1&&'Guardar'||'Actualizar'}}
            </button>
        </div>
    </form>
</ng-template>