import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EmailEditorComponent } from 'angular-email-editor';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { EmpresaServicesService } from 'src/app/services/empresas/empresa-services.service';
import sample from "../../json/sample.json";
import Swal from 'sweetalert2';
import { tr } from 'date-fns/locale';



@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.css']
})
export class PlantillasComponent implements OnInit {
  ejemplo: object = sample
  options = { "width": '8px' };
  color = 'primary';
  flagAccion: number = 1
  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;
  constructor(private toastr: ToastrModule, private toast: ToastrService, private srvClientes: EmpresaServicesService, private ActivatedRoute: ActivatedRoute) { }
  idCliente: any
  plantillas: Array<any> = []
  formPlantilla: FormGroup
  public mostrarEditor: boolean = false
  // called when the editor has finished loading
  // editorReady(e) {
  // }

  saveDesign() {
    this.emailEditor.editor.saveDesign((dataD) => {
    })
  }
  exportHtml() {
    this.emailEditor.editor.exportHtml((data) => {
      var json = data.design; // design json
      var html = data.html; // design html

    });
  }

  ngOnInit(): void {
    this.idCliente = this.ActivatedRoute.snapshot.paramMap.get("idCliente");
    if (this.idCliente == '1UBHCKP') {

    } else {
      this.getPlantilla();
    }
    this.formPlantilla = new FormGroup({
      id: new FormControl(''),
      asunto: new FormControl('', Validators.required),
      detallehtml: new FormControl('', Validators.required),
      detallejson: new FormControl(''),
      idCliente: new FormControl('', Validators.required),
      sms: new FormControl(''),
      telefono: new FormControl(''),
      descargarArchivo : new FormControl(false)
    })
  }
  getPlantilla() {
    this.srvClientes.getPlantillaCliente(this.idCliente, data => {
      this.plantillas = data.data.table0
    })
  }

  openPlantilla(item) {
    console.log(item);
    this.nextStep();
    this.flagAccion = 2
    this.formPlantilla.patchValue({
      id: item.id,
      asunto: item.asunto,
      detallehtml: item.detallehtml,
      idCliente: this.idCliente,
      sms: item.mensajeSMS,
      telefono: item.telefono,
      detallejson: JSON.parse(item.detallejson),
      descargarArchivo: item.descargarArchivo

    })
    if (item.detallejson != 'null' && item.detallejson != null) {
      this.obj = JSON.parse(item.detallejson);
      // delete obj.body.id
      // this.obj=obj
      this.mostrarEditor = true
      setTimeout(() => {
        this.editorLoaded();
      }, 1000);
    }
    if (item.detallejson == 'null' || item.detallejson == null) {
      this.mostrarEditor = false

    }
  }

  nueva() {
    this.flagAccion = 1
    this.formPlantilla.reset();
    this.formPlantilla.patchValue({ id: 0 })
    this.mostrarEditor = false
    this.nextStep();
  }
  obj: any
  editorLoaded() {
    this.emailEditor.editor.loadDesign(this.obj);

  }
  cambiarEditor() {
    this.mostrarEditor = this.mostrarEditor ? false : true;
    this.formPlantilla.reset();
    this.formPlantilla.patchValue({ id: 0 })



  }
  descargarArchivo() {
    console.log(12312);
  }
  step: number = 1
  nextStep() {
    this.step++;
  }
  setStep(index: number) {
    this.step = index;
  }
  prevStep() {
    this.step--;
  }

  deletePlantilla(item) {
    console.log(item);
    Swal.fire({
      title: 'Confirmación',
      html: ` <p> ¿Desea eliminar la plantilla: <b>${item.asunto}</b>? </p>`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#003399',
      cancelButtonColor: '#d33',
      cancelButtonText: 'cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.srvClientes.deletePlantilla(item.id, data => {
          this.toast.info(data.data.table0[0].descripcion)
          this.getPlantilla();
        })
      }
    });

  }

  guardarPlantilla() {
    this.flagAccion == 1 ? this.formPlantilla.patchValue({ id: 0 }) : ''
    this.formPlantilla.patchValue({
      idCliente: this.idCliente
    })

    if (this.mostrarEditor) {

      this.emailEditor.editor.exportHtml((data) => {
        var json = data.design; // design json
        var html = data.html; // design html
        this.formPlantilla.patchValue({
          detallehtml: html,
          detallejson: json
        })

        if (this.formPlantilla.valid) {
          this.mostrarEditor = false

          if (this.formPlantilla.value.id == 0) {

            this.srvClientes.InsertPlantillaCliente(this.formPlantilla.value, data => {
              this.toast.info(data.data.table0[0].descripcion)
              this.getPlantilla();
            })
          } else {
            this.srvClientes.UpdatePlantillaCliente(this.formPlantilla.value, data => {
              this.getPlantilla()

              this.toast.info(data.data.table0[0].descripcion)

            })
          }
          this.flagAccion = 1
          this.formPlantilla.reset();
        } else {
          this.toast.warning('Llene todos los campos')
        }
      })
    } else {
      if (this.formPlantilla.valid) {
        this.mostrarEditor = false
        if (this.formPlantilla.value.id == 0) {

          this.srvClientes.InsertPlantillaCliente(this.formPlantilla.value, data => {
            this.toast.info(data.data.table0[0].descripcion)
            this.getPlantilla();
          })
        } else {
          this.srvClientes.UpdatePlantillaCliente(this.formPlantilla.value, data => {
            this.toast.info(data.data.table0[0].descripcion)
            this.getPlantilla()

          })
        }
        this.flagAccion = 1
        this.formPlantilla.reset();
      } else {
        this.toast.warning('Llene todos los campos')
      }


    }




  }

}
