import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {ChangeDetectorRef, Component,OnDestroy,AfterViewInit} from '@angular/core';
import { AppNavbarComponent } from '../shared/app-navbar/app-navbar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.style.css']
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  opened:boolean = true;
  private _mobileQueryListener: () => void;
  tipoDos:boolean
  tipo:string
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.tipo= localStorage.getItem('typeTwo');
   this.tipo=='F676E28C-067F-4085-8204-9463062D8581'?this.tipoDos=true:this.tipoDos=false;
    // this.elemento.ontoggle("toggled");
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {}
}
