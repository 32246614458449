

<div *ngIf="!verMenus && !verPerfiles">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/adminMenu']">Inicio</a></li>
            <li class="breadcrumb-item active" aria-current="page">Usuarios</li>
        </ol>
    </nav>
    <mat-card>
        <mat-card-content>

            <div *ngIf="(usuariosFacade.responseCargando$ | async)">
                <app-loading [data]="4"></app-loading>
            </div>

            <section *ngIf="!(usuariosFacade.responseCargando$ | async)">
                <!-- <div class="text-right" *ngIf="permisos?.ingresar"> -->

                <div class="text-right">
                    <button mat-raised-button class="buttonPrincipal" (click)="openDialog({})">
                        <mat-icon>
                            account_circle
                        </mat-icon>
                        Agregar Usuario
                    </button>
                </div>

                <div *ngIf="(usuariosFacade.responseUsuarios$| async ).length == 0 && !(usuariosFacade.responseCargando$ | async)" class="mt-4">
                    <div class="alert alert-warning text-center" role="alert">
                        No existen usuarios para mostrar
                    </div>
                </div>
                <div *ngIf="!(usuariosFacade.responseCargando$ | async) && (usuariosFacade.responseUsuarios$| async ).length != 0" class="mt-4">
                    <div class="row">
                        <div class="col-md-6">
                            <form [formGroup]="filtro">
                                <mat-form-field appearance="fill">
                                    <mat-label>Busqueda</mat-label>
                                    <input matInput placeholder="Buscar" formControlName="filtrar">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>

                    <div class="table-responsive" *ngIf="(usuariosFacade.responseUsuarios$| async ).length != 0">

                        <table class="table  bordeTabla tablep" role="table">
                            <thead class="theadp">

                                <tr class="trp bg-success text-center">

                                    <th class="thp" scope="col" role="columnheader">Nombre</th>
                                    <th class="thp" scope="col" role="columnheader">Usuario</th>
                                    <th class="thp" scope="col" role="columnheader">Observaciónes</th>
                                    <th class="thp" scope="col" role="columnheader">Activo</th>
                                    <th class="thp" scope="col" role="columnheader">Opciones</th>
                                </tr>
                            </thead>


                            <tbody role="rowgroup" class="tbodyp">

                                <tr class="text-center trp" role="row" *ngFor="let item of (usuariosFacade.responseUsuarios$ | async) | search: this.filtro.value.filtrar: parametrosBusqueda | slice: desde :hasta">
                                    <td role="cell" data-title="Nombre" class="tdp"> {{item.Nombre}}</td>
                                    <td role="cell" data-title="Usuario" class="tdp"> {{item.Usuario}}</td>
                                    <td role="cell" data-title="Observaciónes" class="tdp">{{item.Observaciones}}</td>
                                    <td role="cell" data-title="Activo" class="tdp">
                                        <span *ngIf="item.Activo === true">
                                            <mat-icon class="success">check_box</mat-icon>
                                        </span>
                                        <span *ngIf="item.Activo === false">
                                            <mat-icon class="error">indeterminate_check_box</mat-icon>
                                        </span>
                                    </td>

                                    <td role="cell" data-title="Opciones" class="tdp">
                                        <div class="text-center">

                                            <button mat-mini-fab class="buttonSecundary" #tooltip="matTooltip" matTooltip="Ver perfiles del usuario" matTooltipPosition="left" (click)="perfilesUsuarios(item)">
                                                <mat-icon>visibility</mat-icon>
                                            </button>
                                            <button mat-mini-fab class="buttonSecundary" #tooltip="matTooltip" matTooltip="Ver menus del usuario" matTooltipPosition="above" (click)="menusUsuario(item)">
                                                <mat-icon>list</mat-icon>
                                            </button>
                                            <!-- <button mat-mini-fab class="btn-delete" #tooltip="matTooltip" matTooltip="Eliminar Usuario" matTooltipPosition="above"
                                                *ngIf="permisos?.eliminar" (click)="eliminarUsuario(item)"> -->

                                            <button mat-mini-fab class="btnDelete" #tooltip="matTooltip" matTooltip="Eliminar Usuario" matTooltipPosition="above"  (click)="eliminarUsuario(item)">
                                                <mat-icon>delete_forever</mat-icon>
                                            </button>
                                            <!-- <button mat-mini-fab class="bntGray" #tooltip="matTooltip" matTooltip="Editar Usuario" matTooltipPosition="above" (click)="openDialog(item)" *ngIf="permisos?.actualizar"> -->

                                            <button mat-mini-fab class="buttonPrincipal" #tooltip="matTooltip" matTooltip="Editar Usuario" matTooltipPosition="above" (click)="openDialog(item)">
                                                <mat-icon>create</mat-icon>
                                            </button>
                                        </div>
                                    </td>

                                </tr>

                            </tbody>
                        </table>
                        <mat-paginator [length]="(usuariosFacade.responseUsuarios$ | async).length " [pageSize]="pageSize " (page)="next($event) ">
                        </mat-paginator>
                    </div>
                </div>
            </section>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="verPerfiles && !verMenus">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/adminMenu']">Inicio</a></li>
            <li class="breadcrumb-item"><a (click)="verPerfiles = false" class="cursorActivo">Usuarios</a></li>
            <li class="breadcrumb-item active" aria-current="page">PERFILES {{this.usuariosFacade.consumirUsuario().Nombre}}</li>
        </ol>
    </nav>

    <mat-card>
        <mat-card-header>
            <mat-card-title><b>Perfiles</b> {{this.usuariosFacade.consumirUsuario().Nombre}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="text-right" *ngIf="(perfilesFacade.responseCargando$ | async) === false">
                <button mat-raised-button class="buttonPrincipal" (click)="openDialogPerfil()">
                    Asignar Perfil
                </button>
            </div>
            <app-table-perfiles dataFilter="usuarios" *ngIf="!menusFacade.itemMenus?.IdPerfil"></app-table-perfiles>
            <app-table-menus [dataProfile]="1" *ngIf="menusFacade.itemMenus?.IdPerfil"></app-table-menus>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="verMenus">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/adminMenu']">Inicio</a></li>
            <li class="breadcrumb-item"><a (click)="verMenus = false; verPerfiles = false" class="cursorActivo">Usuarios</a></li>
            <li class="breadcrumb-item active" aria-current="page">MENUS {{this.menusFacade.itemMenus?.Nombre}}</li>
        </ol>
    </nav>

    <mat-card>
        <mat-card-header>
            <mat-card-title><b>Menus</b> {{this.menusFacade.itemMenus?.Nombre}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="text-right" *ngIf="(menusFacade.responseCargando$ | async) === false">
                <button mat-raised-button class="buttonPrincipal" (click)="dialogMenus()">
                    Asignar Menus
                </button>
            </div>
            <app-table-menus [dataProfile]="2"></app-table-menus>
            
            <ng-template #bottomSheet>
                <mat-nav-list>
                    <a mat-list-item (click)="openLink($event)" *ngFor="let bottom of dataBottomSheet" (click)="navegar(bottom)">
                        <mat-icon>{{bottom.icono}}</mat-icon>
                        <span mat-line><b>{{bottom.title}}</b></span>
                        <span mat-line>{{bottom.subTitle}}</span>
                        <mat-divider></mat-divider>
                    </a>
            
                </mat-nav-list>
            </ng-template>












        </mat-card-content>
    </mat-card>
</div>
