import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

export interface Tile {
  color: string;
  cols?: number;
  rows: number;
  text: string;
  image?: string;
}
@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.scss']
})


export class MenuAdminComponent implements OnInit {
  cols: Subject<any> = new Subject();
  
  fotoEmpresa= 'https://cdn.pixabay.com/photo/2019/04/26/07/14/store-4156934_1280.png';
  fotoUsers= 'https://cdn.pixabay.com/photo/2015/11/06/13/12/matrix-1027571_1280.jpg';
  fotoMenu= 'https://cdn.pixabay.com/photo/2018/08/18/13/26/interface-3614766_1280.png';

  constructor(public authService:AuthService,public router:Router) { }
menusAdmin=[]
  ngOnInit(): void {
//     let menus: any[] = [];
//     // menus = JSON.parse(localStorage.getItem('menus'));
//     menus=JSON.parse(localStorage.getItem("menusTotales"));
//     console.log(menus,'MENUS')
//     for (let i = 0; i < menus.length; i++) {
//       const element = menus[i];
// if (menus[i].idMenu=2012) {
//   this.menusAdmin=menus[i].hijos;
//   console.log(this.menusAdmin,'los meros')
// }
      
//     }

    // this.Menus$.next(menus);
  }
  salir() {
    this.authService.CerrarSesion();
  }

  goMosaic(){
    this.router.navigate(['/mosaicMenu']);
  }
  goEmpresas(){
    this.router.navigate(['/business']);
  }

  goUsers(){
    this.router.navigate(['/users']);
  }
  goMenus(){
    this.router.navigate(['/menus']);
  }

}
