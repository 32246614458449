<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <div class="login100-pic js-tilt" data-tilt>
                <img src="../../../assets/images/undraw_approve_qwp7.svg" alt="IMG">
            </div>

            <form [formGroup]="formregistro" class="login100-form validate-form">
                <span class="login100-form-title">
                    Registrate
                </span>
                <div class="wrap-input100 validate-input" data-validate = "Nombre">
                    <input class="input100" type="text" name="nombre" placeholder="Nombre" formControlName="nombre">
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i class="fa fa-user" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="wrap-input100 validate-input" data-validate = "Correo requerido: Jua@dominio.com">
                    <input class="input100" type="text" name="email" placeholder="Correo electronico" formControlName="correo">
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                    </span>
                </div>

                <div class="wrap-input100 validate-input" data-validate = "Contraseña requerida: ********">
                    <input class="input100" type="password" name="pass" placeholder="Contraseña" formControlName="clave">
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i class="fa fa-lock" aria-hidden="true"></i>
                    </span>
                </div>
                
                <div class="wrap-input100 validate-input" data-validate = "Contraseña requerida: ********">
                    <input class="input100" type="password" name="pass" placeholder="Confirmar Contraseña" formControlName="repiteclave">
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i class="fa fa-lock" aria-hidden="true"></i>
                    </span>
                </div>

                <div class="container-login100-form-btn">
                    <button class="login100-form-btn" (click)="registrar()">
                        Registrar
                    </button>
                </div>
                <div class="text-center p-t-136">
                    ya tienes cuenta?
                    <a class="txt2" routerLink="/login">
                        Inicia sesion
                        <i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                    </a>
                </div>
            </form>
        </div>
    </div>
</div>