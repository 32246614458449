import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MenusFacadeService } from './menus-facade.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermisosValidarService } from '../../../services/seguridad/permisos-validar.service';
import { permisosInterface } from '../../..//Model/Seguridad/permisos.Interface';
@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})
export class MenusComponent implements OnInit, OnDestroy {
  public permisos: permisosInterface;
  private subscripciones: Subscription = new Subscription();


  constructor(public menusFacade: MenusFacadeService,
    private cdRef: ChangeDetectorRef,
    private validarService: PermisosValidarService,
    public router: Router
    ) {
    this.permisos = this.validarService.validate('Menu');
    this.menusFacade.currentUrl = this.router.url;
    console.log(menusFacade.currentUrl);
    this.subscripciones.add(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.menusFacade.previousUrl = this.menusFacade.currentUrl;
        this.menusFacade.currentUrl = event.url;
      };
    }));
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  
  consumirMenus(){
    this.menusFacade.MostrarMenu('');
  }

  ngOnDestroy(): void {
    this.subscripciones.unsubscribe();
  }

  ngOnInit(): void {
    this.consumirMenus();
  }


}
