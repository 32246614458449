import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenusFacadeService } from '../menus-facade.service';
import { menuInterface } from '../../../../Model/Seguridad/menu.Interface';
import { ToastrServiceLocal } from '../../../../services/toast/toastr.service';
import { Subscription } from 'rxjs';
import { ModulosFacadeService } from '../../modulos/modulos-facade.service';

@Component({
  selector: 'app-nuevo-menu',
  templateUrl: './nuevo-menu.component.html',
  styleUrls: ['./nuevo-menu.component.css']
})
export class NuevoMenuComponent implements OnInit, OnDestroy {

  public suscription: Subscription = new Subscription();

  public arrayMenus!: menuInterface;
  public menus!: FormGroup;
  public idMenuPadre: number;
  public idMenu: number;

  constructor(public activatedRoute: ActivatedRoute,
    public menusFacade: MenusFacadeService,
    private router: Router, public modulosMenuFacade:ModulosFacadeService) {
    this.idMenuPadre = Number(this.activatedRoute.snapshot.paramMap.get('idPadre'));
    this.idMenu = Number(this.activatedRoute.snapshot.paramMap.get('idMenu'));


    if (this.idMenu != 0) {
      this.menusFacade.MostrarMenu('');
      this.suscription.add(this.menusFacade.responseMenus$.subscribe((result) => {
        if (result.length != 0) {
          // console.log(result);
          this.obtenerMenu(result);
        }
      }));
    }

    this.formInicializate();
    this.modulosMenuFacade.mostrarModulo('');

  }

  ngOnDestroy(): void {
    this.suscription.unsubscribe();
  }

  obtenerMenu(menus:any) {

    if (this.idMenuPadre == 0) {
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].idMenu === this.idMenu) {
          this.arrayMenus = menus[i];
        }
      }
    } else {
      //Se recorre la respuesta de los menus buscando el idPadre
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].idMenu === this.idMenuPadre) {
          //Se recorre el array de hijos para buscar el idMenu deseado
          for (const j in menus[i].hijos) {
            if (menus[i].hijos[j].idMenu === this.idMenu) {
              this.arrayMenus = menus[i].hijos[j];
            }
          }
        }
      }
    }
    
    var idModulo = 0;
    if (this.idMenuPadre == 0) {
      idModulo = this.arrayMenus.idModulo
    } 

    this.menus.patchValue({
      idMenu: this.arrayMenus.idMenu,
      texto: this.arrayMenus.texto,
      idPadre: this.validarPadre(),
      descripcion: this.arrayMenus.descripcion,
      icono: this.arrayMenus.icono,
      orden: this.arrayMenus.orden,
      visible: this.validarBoolean(this.arrayMenus.visible),
      activo: this.validarBoolean(this.arrayMenus.activo),
      url: this.arrayMenus.url,
      idModulo: idModulo
    });

  }

  ngOnInit(): void {
  }

  formInicializate() {
    this.menus = new FormGroup({
      idMenu: new FormControl(this.idMenu),
      texto: new FormControl('', [Validators.required]),
      icono: new FormControl('', [Validators.required]),
      idPadre: new FormControl(this.validarPadre()),
      orden: new FormControl(''),
      visible: new FormControl('1'),
      activo: new FormControl('1'),
      url: new FormControl(''),
      descripcion: new FormControl(''),
      idModulo: new FormControl('')

    });

  }

  validarBoolean(valor:any) {
    if (valor == true) {
      return '1'
    } else {
      return '0'
    }
  }

  validarPadre() {
    if (this.idMenuPadre == 0) {
      return 0;
    } else {
      return this.idMenuPadre;
    }
  }

  nuevoMenu() {
    console.log(this.idMenu);
    if (this.idMenu == 0) {
      this.menusFacade.NuevoMenu(this.menus.value);
    } else {
      this.menusFacade.EditarMenu(this.menus.value);
    }
    // this.suscription.add(this.menusFacade.responseAction$.subscribe((result) => {
    //   console.log(result,'despues de guardar');
    //   if (result.hasError === false) {
    //   }
    // }));
    
    this.router.navigateByUrl('/menus');

  }

}
