import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmpresaServicesService } from 'src/app/services/empresas/empresa-services.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// pdfMake.vfs=pdfFonts.pdfMake.vfs
// import htmlToPdfmake from 'html-to-pdfmake';

// import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.css']
})
export class DocumentoComponent implements OnInit {

  constructor(private srvCliente: EmpresaServicesService, public activatedRoute: ActivatedRoute) { }
  id: any
  html: any
  detalles: any
  img = '../../../assets/images/daviviendaBack.png';
  img1 = '../../../assets/images/PrimerAviso.jpg';
  img2 = '../../../assets/images/SegundoAviso.jpg';
  img3 = '../../../assets/images/PeriodoGracia.jpg';
  img4 = '../../../assets/images/CartaEspecial.jpg';
  src = 'http://localhost:4200/document/02A3D407-1870-4DB7-B11E-0B9B6BE2C08D'
  // ENCABEZADOS
  // agente:String
  // Contratante:String
  // Direccion:string
  // Fecha:any
  // Ramo:any
  // Poliza:any
  // Factura:any
  // PrimaPendiente:any
  // VencimientoPago:any
  // Expediente:any
  // CodAgente:any
  // NombreAgente:any
  TipoDeDocumento: any
  html1: any
  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDocumento(this.id)
  }
  getDocumento(id) {
    this.srvCliente.getDocumento(id, data => {

      if (data.data.table0[0]) {

        this.detalles = JSON.parse(data.data.table0[0].detalle)
        this.html1 = data.data.table0[0].html;
        this.html = data.data.table0[0].html;

        if (this.detalles.TipodeDocumento != null || this.detalles.TipodeDocumento != undefined) {
          this.TipoDeDocumento = this.detalles.TipodeDocumento;
        }

        this.html1 = this.html.replace(/margin: 0/g, "margin: 0 !important;");

        this.html1 = document.getElementById("html").innerHTML = this.html1.replace("url('http://s3.amazonaws.com/notificaciones.go504hn.com/cartas/b138669d-2238-40d3-bf7b-46a6a92927bf.jpg')", `url('https://notificaciones.go504hn.com/cartas/b138669d-2238-40d3-bf7b-46a6a92927bf.jpg')`);
        this.html1 = document.getElementById("html").innerHTML = this.html1.replace("url('http://s3.amazonaws.com/notificaciones.go504hn.com/cartas/b55fda64-b22f-4813-bc83-adde99774d63.jpg')", `url('https://notificaciones.go504hn.com/cartas/b55fda64-b22f-4813-bc83-adde99774d63.jpg')`);
        this.html1 = document.getElementById("html").innerHTML = this.html1.replace("url('http://s3.amazonaws.com/notificaciones.go504hn.com/cartas/8064be41-fb1c-413f-a466-f3032d0867a8.jpg')", `url('https://notificaciones.go504hn.com/cartas/8064be41-fb1c-413f-a466-f3032d0867a8.jpg')`);
        this.html1 = document.getElementById("html").innerHTML = this.html1.replace("url('http://s3.amazonaws.com/notificaciones.go504hn.com/cartas/0f00eebf-d8a9-408a-97b6-ee2fbc149a55.jpg')", `url('https://notificaciones.go504hn.com/cartas/0f00eebf-d8a9-408a-97b6-ee2fbc149a55.jpg')`);
        
        if (data.data.table0[0].descargarArchivo) {
          setTimeout(() => {
            if (confirm("¿Desea decargar el archivo? ")) {
              this.downloadPDF();
            }
            //this.des()
          }, 2000);
        }

        /*      setTimeout(() => {
               this.downloadPDF();
              //this.des()
             }, 3000); */

      }
      // var testImage:any
      // this.getBase64ImageFromUrl('https://heraldo.yetis.io/img/background.a03c8517.png')
      // .then(result =>{
      //   this.html1=document.getElementById("html").innerHTML= this.html1.replace("url('https://heraldo.yetis.io/img/background.a03c8517.png')", `src="${result}"`);
      //  setTimeout(() => {
      //   // this.downloadPDF();
      //   this.des()
      //  }, 3000);
      // })
      // .catch(err => console.error(err));
      // this.html1= document.getElementById("html").innerHTML = this.html.replace(/margin: 0/g, "margin: 0 !important;");



    })
  }

  getBase64ImageFromUrl = async (imageUrl) => {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
  ver() {
    var testImage: any
    this.getBase64ImageFromUrl('https://heraldo.yetis.io/img/background.a03c8517.png')
      .then(result => console.log(result))
      .catch(err => console.error(err));
  }
  // @ViewChild('pdfTable') pdfTable: ElementRef;
  public downloadAsPDF() {
    const doc = new jsPDF();

    // const pdfTable = this.pdfTable.nativeElement;
    const pdfTable = document.getElementById("html")

    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();

  }

  downloadPDF() {
    let DATA = document.getElementById("reporte");
    const doc = new jsPDF('p', 'pt', 'a1');
    const options = {
      background: 'white',
      scale: 3
    };

    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('img/JPEG');

      // Add image Canvas to PDF
      const bufferX = 10;
      const bufferY = 20;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'JPEG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      // doc.output('datauri');
      return doc;
    }).then((docResult) => {
      //   this.cargando = false;
      //  this.estadoCuentaFacade.cerrarCargando();
      //   this.dialog.closeAll();

      docResult.save(`AvisoDeCobro.pdf`);


    });

  }

}
