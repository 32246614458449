import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UsuariosFacadeService } from './usuarios-facade.service';
import { NuevoUsuarioModalComponent } from './nuevo-usuario-modal/nuevo-usuario-modal.component';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MenusFacadeService } from '../menus/menus-facade.service';
import { PerfilesFacadeService } from '../perfiles/perfiles-facade.service';
import { ModalPerfilesComponent } from './modal-perfiles/modal-perfiles.component';
import { permisosInterface } from '../../../Model/Seguridad/permisos.Interface';
import { SweetAlertService } from '../../../utils/sweetAlert/sweet-alert.service';
import { AsinarMenusModalComponent } from '../asinar-menus-modal/asinar-menus-modal.component';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { PermisosValidarService } from 'src/app/services/seguridad/permisos-validar.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit, OnDestroy {

  private subscripciones: Subscription = new Subscription();
  public data: any;

  public verMenus: boolean = false;
  public verPerfiles: boolean = false;
  public parametrosBusqueda = ['nombre', 'usuario'];

  //Variable del tipo FormGroup, se inicializa en el constructor
  public filtro: FormGroup;

  //Paginacion
  public pageSize = 10;
  public page = 0;
  public pageEvent!: PageEvent;
  public pageIndex: number = 0;
  public desde = 0;
  public hasta = 10;

  public permisos: permisosInterface;

  constructor(public usuariosFacade: UsuariosFacadeService, public dialog: MatDialog,
    public menusFacade: MenusFacadeService,
    private validarService: PermisosValidarService,
    private cdRef: ChangeDetectorRef,
    public perfilesFacade:PerfilesFacadeService,
    public sweetAlert: SweetAlertService) {
    this.permisos = this.validarService.validate('Usuarios');
    //Inicializar el formulario de busqueda
    this.filtro = new FormGroup({
      filtrar: new FormControl({ value: '', disabled: false }),
      filter: new FormControl({ value: '', disabled: false })

    });

    this.usuariosFacade.MostrarUsuarios('');

    this.verMenus = false;
   }

  ngOnInit(): void {
  }
  ngOnDestroy():void{
    this.subscripciones.unsubscribe();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  perfilesUsuarios(item:any) {
    this.usuariosFacade.SetearUsuario(item);
    this.perfilesFacade.UsuariosPerfilesMostrar(`${item.IdUsuario}/activo/true`);
    this.verPerfiles = true;
    // item.filtro = 1;
    // this.data = item;
  }

  menusUsuario(item:any){
    item.filtro = 1;
    item.tituloModal = 'Usuario';
    this.verMenus = true;
    this.menusFacade.menuItem(item);
    console.log(item);
    // this.menusFacade.MostrarMenuUsuario(item.IdUsuario)
  }

  openDialogPerfil(){
    const dialogRef = this.dialog.open(ModalPerfilesComponent, {
      width: '60vw',
      maxWidth: '80vw',
      panelClass: "app-full-bleed-dialog",

    });
  }

  openDialog(data?:any) {
    const dialogRef = this.dialog.open(NuevoUsuarioModalComponent, {
      width: '80vw',
      maxWidth: '100vw',
      data: data,
      panelClass: 'app-full-bleed-dialog'
    });
  }

  //Paginación de la tabla
  next(event: PageEvent) {

    if (event.pageIndex === this.pageIndex + 1) {
      this.desde = this.desde + this.pageSize;
      this.hasta = this.hasta + this.pageSize;
    }
    else if (event.pageIndex === this.pageIndex - 1) {
      this.desde = this.desde - this.pageSize;
      this.hasta = this.hasta - this.pageSize;
    }
    this.pageIndex = event.pageIndex;
  }

  eliminarUsuario(item:any){
    let result = this.sweetAlert.mensajeConConfirmacion(`¿Está seguro de que desea eliminar el usuario ${item.Usuario}?`, '', 'warning');
    result.then((result)=>{
      if(result == true){
        this.usuariosFacade.UsuarioEliminar({ idUsuario: item.IdUsuario });
        this.subscripciones.add(this.usuariosFacade.responseAction$.subscribe((result)=>{
            if(result.hasError == false){
              this.usuariosFacade.MostrarUsuarios('');
            }
        }));
      }
    });
  }

  dialogMenus() {
    const dialogRef = this.dialog.open(AsinarMenusModalComponent, {
      width: '80vw',
      maxWidth: '100vw',
      disableClose: true,
      panelClass: "app-full-bleed-dialog",

    });
  }
  
}
