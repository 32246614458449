import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardPackageService } from 'src/app/modules/dashboard/dashboard-package.service';
import { EmpresaServicesService } from 'src/app/services/empresas/empresa-services.service';
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import { meses } from 'src/app/utils/api';
import { UsuariosFacadeService } from 'src/app/modules/seguridad/usuarios/usuarios-facade.service';
import { PageEvent } from '@angular/material/paginator';
import { SweetAlertService } from 'src/app/utils/sweetAlert/sweet-alert.service';
import { Subscription } from 'rxjs';
import { ModalPerfilesComponent } from 'src/app/modules/seguridad/usuarios/modal-perfiles/modal-perfiles.component';
import { MatDialog } from '@angular/material/dialog';
import { NuevoUsuarioModalComponent } from 'src/app/modules/seguridad/usuarios/nuevo-usuario-modal/nuevo-usuario-modal.component';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-cliente-detalle',
  templateUrl: './cliente-detalle.component.html',
  styleUrls: ['./cliente-detalle.component.scss']
})
export class ClienteDetalleComponent implements OnInit {
  public idCliente:number;

  constructor(private dialog:MatDialog, public sweetAlert: SweetAlertService, public usuariosFacade:UsuariosFacadeService, private toast:ToastrService, private srvCliente:EmpresaServicesService, private ActivatedRoute:ActivatedRoute, 
    public dashboardService: DashboardPackageService,  public router: Router,) { 
      this.idCliente = Number(this.ActivatedRoute.snapshot.paramMap.get("idCliente"));
  }
  
  public cliente :any;
  public clienteDetalles:any=[];
  public flagAccion:number=1;
  public animacionChart = "animate__animated animate__fadeInLeft";
  filtro:FormGroup
  private subscripciones: Subscription = new Subscription();

  ngOnDestroy():void{
    this.subscripciones.unsubscribe();
  }
  
  //grafica por meses
  graficas: any[];
  single: meses[] = [];
  total: string;
  modulo: string;
  sttatus: any[] = [];
  multi: any[];
  mensajemes: string;
  mensajea: string;
  mensajeu: string;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Mes';
  xAxisLabell = 'Dias';
  showYAxisLabel = true;
  yAxisLabel = 'Enviados';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#9D43F7', '#FF622F', '#37F4F8', '#FAED36', '#E3171F', '#17E334']
  };

  //grafica por dia
  dias: any[] = [];

  public clase = "col-md-12";

  public chartStyleColor: [
    {
      backgroundColor: "rgba(80, 227, 194, 0.5)",
      borderColor: "rgba(80, 227, 194, 1)",
      pointBackgroundColor: "rgb(255, 255, 255)",
      pointBorderColor: "rgb(255, 255, 255)",
      pointHoverBackgroundColor: "rgb(255, 255, 255)",
      pointHoverBorderColor: "rgb(255, 255, 255)",
    },
    {
      backgroundColor: "rgba(33, 166, 238, 0.6)",
      borderColor: "rgba(33, 166, 238, 1)",
      pointBackgroundColor: "rgb(255, 255, 255)",
      pointBorderColor: "rgb(255, 255, 255)",
      pointHoverBackgroundColor: "rgb(255, 255, 255)",
      pointHoverBorderColor: "rgb(255, 255, 255)",
    },
    {
      backgroundColor: "rgb(128, 124, 204, 0.5)",
      borderColor: "rgb(128, 124, 204)",
      pointBackgroundColor: "rgb(255, 255, 255)",
      pointBorderColor: "rgb(255, 255, 255)",
      pointHoverBackgroundColor: "rgb(255, 255, 255)",
      pointHoverBorderColor: "rgb(255, 255, 255)",
    },
  ];

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
        maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "white",
        anchor: "end",
        align: "center",
      },
    },
    legend: {
      display: true,
      labels: {
        fontColor: "white",
        fontSize: 10
      },
      position: "bottom"
    },
    scales: {
      xAxes: [
        {
          id: "x-axis-0",
          position: "end",
          ticks: {
            fontColor: "rgb(255, 255, 255)",
            fontSize: 12

          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left",

          ticks: {
            fontColor: "rgb(255, 255, 255)",
            fontSize: 12
          },
        },
        {
          id: "y-axis-1",
          position: "right",
          // gridLines: {
          //   color: 'white',
          // },
          ticks: {
            fontColor: "rgb(255, 255, 255)",
            fontSize: 12
          },
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "white",
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: "white",
            content: "LineAnno",
            fontSize: 12
          },
        },
      ],
    },
  };

  public barChartPlugins = [pluginDataLabels];

  public tipoDos:boolean=false;
  public tipo:string;
  
  ngOnInit(): void {
    
    this.cliente = this.ActivatedRoute.snapshot.paramMap.get("idCliente");

   this.tipo= localStorage.getItem('typeTwo');
if ( this.tipo=='F676E28C-067F-4085-8204-9463062D8581') {
  this.tipoDos=true
} else {
  this.tipoDos=false
  var desencryto =CryptoJS.AES.decrypt(localStorage.getItem('idUnico'),'c3o3YrDuPO').toString(CryptoJS.enc.Utf8);
if (this.cliente!= desencryto ) {

localStorage.clear();
  this.router.navigateByUrl('inicio');
  return

}
}


    this.filtro= new FormGroup({
      filtrar: new FormControl('')
    })
    if (this.cliente=='1UBHCKP') {
      this.flagAccion=1
    } else {
      this.getClienteDetalles();
      this.flagAccion=2
    }
    this.clienteForm= new FormGroup({
      id: new FormControl(''),
      profile: new FormControl(''),
      nombre: new FormControl('',Validators.required),
      telefono: new FormControl('',Validators.required),
      descripcion: new FormControl(''),
      comentario: new FormControl(''),
      rtn: new FormControl('',Validators.required),
      correo: new FormControl('',Validators.email),
      nombreContacto: new FormControl('',Validators.required),
      telefonoContacto: new FormControl('',Validators.required),
      celularContacto: new FormControl(''),
      correoContacto: new FormControl('',Validators.email),
      key: new FormControl(''),
      bucket: new FormControl(''),
    })
    this.cliente!='1UBHCKP'?this.getClienteDetalles():'';
    this.recuperarModulo();

    this.dashboardService.responseDashboard$.subscribe((result)=>{
    })
     this.usuariosFacade.MostrarUsuariosByCliente(this.cliente);


  }

  getClienteDetalles(){
    this.srvCliente.getClienteDetalles(this.cliente,data=>{
      this.clienteDetalles=data.data.table0[0];
      this.clienteForm.patchValue({
        id:data.data.table0[0].id,
        nombre:data.data.table0[0].nombre,
        telefono:data.data.table0[0].telefono,
        descripcion:data.data.table0[0].descripcion,
        comentario:data.data.table0[0].comentario,
        rtn:data.data.table0[0].rtn,
        correo:data.data.table0[0].correo,
        nombreContacto:data.data.table0[0].nombreContacto,
        telefonoContacto:data.data.table0[0].telefonoContacto,
        celularContacto:data.data.table0[0].celularContacto,
       correoContacto:data.data.table0[0].correoContacto,
       key:data.data.table0[0].key_,
       bucket:data.data.table0[0].bucket,
      })
      var itemLogo={
        key:data.data.table0[0].key_,
        bucket:data.data.table0[0].bucket,
      }
      if (itemLogo.key!== null &&itemLogo.key !== "") {
        this.srvCliente.getLogo(itemLogo,data=>{
          this.isImageSaved=true;
          this.cardImageBase64=data.data[0].table0[0].url
         })
        
      }
       this.flagAccion=2
    })
  }

  recuperarModulo() {
    let idModulo = 9//JSON.parse(localStorage.getItem("dasboard"));
  
    this.dashboardService.mostrarDashboard({
        "module": idModulo,
        "parametro":[{idCliente: this.idCliente}]
    });
  }



  saveClient(){
    if (this.clienteForm.valid) {
      
      if (this.clienteForm.value.id==0||this.clienteForm.value.id == null) {
        this.srvCliente.InsertClientes(this.clienteForm.value,data=>{
          this.toast.info(data.data.table0[0].descripcion)
          this.cliente=data.data.table1[0].id 
          if (this.clienteForm.get('profile').value) {
            this.saveLogo();
          }
          this.getClienteDetalles()
        })
        
      } else {
        this.srvCliente.UpateClientes(this.clienteForm.value,data=>{
          this.toast.info(data.data.table0[0].descripcion)
          this.getClienteDetalles()
        })
        
      }

    } else {
      this.toast.warning('Llene los campos vacios');
    }

  }

  // foto
  public isImageSaved: boolean=false;
  public cardImageBase64: string;
  public imageError:any
  public nombreArchivo:any
  public foto:any
  public selectFile: File = null;
  clienteForm:FormGroup
  onFileSelect(event) {
    if (this.flagAccion==2) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.clienteForm.get("profile").setValue(file);
    }

    this.imageError = null;
    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes

      this.nombreArchivo = event.target.files[0].name;
      const max_size = 20971520;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = "Maximum size allowed is " + max_size / 1000 + "Mb";

        return false;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        // var enviar = {
        //   file: image.src,
        //   condicion: 6,
        //   id: this.idPersona,
        //   nombreArchivo: "logo" + this.idPersona,
        //   ruta: "img/fotoEmpleado.jpg",
        // };
        this.foto = image.src;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              "Maximum dimentions allowed " +
              max_height +
              "*" +
              max_width +
              "px";
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.selectFile = imgBase64Path;
          }
          return this.selectFile;
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
    this.saveLogo();
    return true;
  }
  else{
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.clienteForm.get("profile").setValue(file);
    }

    this.imageError = null;
    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes

      this.nombreArchivo = event.target.files[0].name;
      const max_size = 20971520;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = "Maximum size allowed is " + max_size / 1000 + "Mb";

        return false;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        // var enviar = {
        //   file: image.src,
        //   condicion: 6,
        //   id: this.idPersona,
        //   nombreArchivo: "logo" + this.idPersona,
        //   ruta: "img/fotoEmpleado.jpg",
        // };
        this.foto = image.src;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              "Maximum dimentions allowed " +
              max_height +
              "*" +
              max_width +
              "px";
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.selectFile = imgBase64Path;
          }
          return this.selectFile;
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
    return true
  }
  }
formData
  saveLogo(){
    this.formData = new FormData();
    this.formData.append('image', this.clienteForm.get('profile').value);
    this.formData.append('bucket', 'go504hnrepo')
    this.srvCliente.saveLogo(this.formData,data=>{
      var keyImagen=data.data[0].table0[0].Key;
       var bucketR=data.data[0].table0[0].Bucket;
      this.clienteForm.value.profile=null;
      this.clienteForm.value.key=keyImagen;
      this.clienteForm.value.bucket=bucketR;
      this.srvCliente.UpateClientes(this.clienteForm.value,data=>{
        this.toast.info(data.data.table0[0].descripcion)
        this.getClienteDetalles()
      })
      
    })

  }

  eliminarUsuario(item:any){
    let result = this.sweetAlert.mensajeConConfirmacion(`¿Está seguro de que desea eliminar el usuario ${item.Usuario}?`, '', 'warning');
    result.then((result)=>{
      if(result == true){
        this.usuariosFacade.UsuarioEliminar({ idUsuario: item.IdUsuario });
        this.subscripciones.add(this.usuariosFacade.responseAction$.subscribe((result)=>{
            if(result.hasError == false){
              this.usuariosFacade.MostrarUsuariosByCliente(this.cliente);
            }
        }));
      }
    });
  }

  
  openDialog(data?:any) {
    data.idTipo=2;
    data.idCliente=this.cliente;
    const dialogRef = this.dialog.open(NuevoUsuarioModalComponent, {
      width: '80vw',
      maxWidth: '100vw',
      data: data,
      panelClass: 'app-full-bleed-dialog'
    });
  }

   //Paginacion
   public pageSize = 10;
   public page = 0;
   public pageEvent!: PageEvent;
   public pageIndex: number = 0;
   public desde = 0;
   public hasta = 10;

    //Paginación de la tabla
    next(event: PageEvent) {

      if (event.pageIndex === this.pageIndex + 1) {
        this.desde = this.desde + this.pageSize;
        this.hasta = this.hasta + this.pageSize;
      }
      else if (event.pageIndex === this.pageIndex - 1) {
        this.desde = this.desde - this.pageSize;
        this.hasta = this.hasta - this.pageSize;
      }
      this.pageIndex = event.pageIndex;
    }


  validarNumeros(event) {
    if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
        return false;
    } else {
        return true;
    }
  }

  columsDisplay(item: []) {
    return item.slice();
  }
}
